import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Closephoto } from 'assets/images/svg/closephoto.svg';
import { ReactComponent as Add } from 'assets/images/svg/add.svg';
import { validator } from 'Helpers/validator';
import Image from 'Components/Common/Image';
import DropDown from 'Components/Common/DropDown';
import { broadCastActions } from 'Actions';
import DatePickerComponent from 'Components/Common/DateTimePicker';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DragUpload from 'Components/Common/Dragupload';

class AddManageBroadcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      publishmessages: '',
      schedulemessages: '',
      publishmessagesType: '',
      schedulemessagesType: '',
      publishteamName: '',
      scheduleteamName: '',
      createdBy: {
        name: '',
        image: ''
      },
      createdDateTime: 0,
      broadcastDateTime: '',
      deliveryStatus: '',
      status: 1,
      fileName: '',
      publishimage: '',
      scheduleimage: '',
      publishisActive:1,
      scheduleisActive:1,
      image:''
    };
    this.validator = validator.getInstance();
  }

  validation = e => {
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    const elementObject = {
      formId: 'addbroadcast',
      elementName: e.target.name,
      elementValue: e.target.value,
      message: e.target.dataset.error
    };
    this.validator.validate(elementObject, inputRules);
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  };

  handleSelectTeam = e => {
    const value = e.target.options[ e.target.selectedIndex ].dataset.rolename;
    const { name } = e.target;
    this.setState({ [ name ]: value });
  };

  handlescheduleCheckBoxChange = () => {
    this.setState({ scheduleisActive: !this.state.scheduleisActive & 1 });
  };
  handlePublishCheckBoxChange = () => {
    this.setState({ publishisActive: !this.state.publishisActive & 1 });
  };

  renderTeamName = () => {
    const teamNameArray = [
      'teamName1',
      'teamName2',
      'teamName3',
      'teamName4',
      'teamName5',
      'teamName6',
      'teamName7',
      'teamName8'
    ];
    return teamNameArray.map((broadcast, index) => {
      return (
        <option key={ index } value={ index }>
          {broadcast}
        </option>
      );
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const {
      id,
      messages,
      messagesType,
      teamName,
      createdBy,
      createdDateTime,
      broadcastDateTime,
      deliveryStatus,
      status,
      fileName
    } = this.state;

    if (event.target.id && !this.validator.checkBeforeSubmit(event.target.id)) {
      this.props.updateBroadCast({
        id,
        messages,
        messagesType,
        teamName,
        createdBy,
        createdDateTime,
        broadcastDateTime,
        deliveryStatus,
        status,
        fileName
      });
    }
  };

  updateImage = (fileToken, fileName) => {
    this.setState(
      {
          image: fileToken,
          fileName
      })
   };

   removeProfileimage  = () => {
      this.setState({
          image: '',
          fileName: ''
      });
  };

  closeBroadCastMenu = () => {
    this.props.closeBroadCastMenu();
  };

  render() {
    const { fileName, publishmessagesType,schedulemessagesType, publishmessages, schedulemessages, publishteamName,scheduleteamName, publishisActive,scheduleisActive,image } = this.state;
    return (
      <React.Fragment>
        <div className='addbroadcast' id='addbroadcast'>
          <div className='overlay' onClick={ this.closeBroadCastMenu }></div>
          <div className='addmgnt'>
            <Tabs>
              <TabList>
                <Tab>Publish Now</Tab>
                <Tab>Schedule Later</Tab>
              </TabList>

              <TabPanel>
                <div className='head'>
                  <h2>New Broadcast</h2>
                  <div className='tooglebbt'>
                    <div className='toogle'>
                      <span>(Inactive)</span>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          checked={ !!+publishisActive }
                          onChange={ this.handlePublishCheckBoxChange }
                        />
                        <span className='slider round'></span>
                      </label>
                      <span>(active)</span>
                    </div>
                  </div>
                </div>
                <form id='addbroadcast-form' onSubmit={ this.handleSubmit }>
                  <div className='formgroup'>
                    <label>
                      Message Type<sup>*</sup>
                    </label>
                    <input
                      type='text'
                      name='publishmessagesType'
                      value={ publishmessagesType }
                      data-validation={ [ 'user' ] }
                      data-error='Enter message type'
                      placeholder='Enter message type here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>Message</label>
                    <input
                      type='text'
                      name='publishmessages'
                      value={ publishmessages }
                      data-validation={ [ 'user' ] }
                      data-error='Enter message'
                      placeholder='Enter message here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                 
                  <div className='formgroup new'>
                    <label>
                      Select Team<sup>*</sup>
                      <Link className='button' to='/broadcast/team'>
                        <i>
                          <Add />
                        </i>
                        New Team
                      </Link>
                    </label>
                    <DropDown
                      name='publishteamName'
                      DropdownText='Select'
                      selectedOption={ publishteamName }
                      requiredfield='true'
                      dispatchFunction={ this.handleSelectTeam }
                      optionRender={ this.renderTeamName }
                    />
                  </div>
                  <div className='formgroup'>
                  <label>
                    Profile image<sup>*</sup>
                  </label>
                  <div className='loading-parent'>
                    <div className='profileimg'>
                      <Image imageToken={ image } />
                      <span className='profilelabel'>
                        {fileName && (
                          <i>
                            <Closephoto onClick={ this.removeProfileimage } />
                          </i>
                        )}
                      </span>
                    </div>
                    <DragUpload 
                      id='addadminupload'
                      removeImage = { this.removeProfileimage }
                      updateImage = { this.updateImage }
                      requiredfield = 'requiredfield'
                    />
                  </div>
                </div>
                  <div className='buttons'>
                    <div>
                      <button
                        type='reset'
                        onClick={ this.closeBroadCastMenu }
                        className='plain-bbt'
                      >
                        Cancel
                      </button>
                      <button className='blue-bbt'>Save</button>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel>
                <div className='head'>
                  <h2>New Broadcast</h2>
                  <div className='tooglebbt'>
                    <div className='toogle'>
                      <span>(Inactive)</span>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          checked={ !!+scheduleisActive }
                          onChange={ this.handlescheduleCheckBoxChange }
                        />
                        <span className='slider round'></span>
                      </label>
                      <span>(active)</span>
                    </div>
                  </div>
                </div>
                <form id='addbroadcast-form' onSubmit={ this.handleSubmit }>
                  <div className='formgroup'>
                    <label>
                      Message Type<sup>*</sup>
                    </label>
                    <input
                      type='text'
                      name='schedulemessagesType'
                      value={ schedulemessagesType }
                      data-validation={ [ 'user' ] }
                      data-error='Please enter message type'
                      placeholder='Enter message type here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>Message</label>
                    <input
                      type='text'
                      name='schedulemessages'
                      value={ schedulemessages }
                      data-validation={ [ 'user' ] }
                      data-error='Please enter message'
                      placeholder='Enter message here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup date'>
                    <label>
                      Message Date<sup>*</sup>
                    </label>
                    <div className='datetime'>
                      <DatePickerComponent
                        name='broadcastDateTime'
                        minDate={ new Date() }
                      />
                    </div>
                    <span>
                      (Message will be sent at 09.30 AM on the selected date)
                    </span>
                  </div>
                  <div className='formgroup new'>
                    <label>
                      Select Team<sup>*</sup>
                      <Link className='button' to='/UserManagement'>
                        <i>
                          <Add />
                        </i>
                        New Team
                      </Link>
                    </label>
                    <DropDown
                      name='scheduleteamName'
                      selectedOption={ scheduleteamName }
                      requiredfield='true'
                      dispatchFunction={ this.handleSelectTeam }
                      optionRender={ this.renderTeamName }
                    />
                  </div>
                  <div className='formgroup'>
                  <label>
                    Profile image<sup>*</sup>
                  </label>
                  <div className='loading-parent'>
                    <div className='profileimg'>
                      <Image imageToken={ image } />
                      <span className='profilelabel'>
                        {fileName && (
                          <i>
                            <Closephoto onClick={ this.removeProfileimage } />
                          </i>
                        )}
                      </span>
                    </div>
                    <DragUpload 
                      id='addadminupload'
                      removeImage = { this.removeProfileimage }
                      updateImage = { this.updateImage }
                      requiredfield = 'requiredfield'
                    />
                  </div>
                </div>
                  <div className='buttons'>
                    <div>
                      <button
                        type='reset'
                        onClick={ this.closeBroadCastMenu }
                        className='plain-bbt'
                      >
                        Cancel
                      </button>
                      <button className='blue-bbt'>Save</button>
                    </div>
                  </div>
                </form>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchtoProps = {
  updateBroadCast: broadCastActions.updateBroadCast
};

function mapStateToProps(state) {
  return { ...state.broadCast };
}
export default connect(mapStateToProps, mapDispatchtoProps)(AddManageBroadcast);
