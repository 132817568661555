import React,{ Fragment } from 'react';
import DataTable from 'Components/Table/dataTable';
import Pagination from 'Components/Pagination/index';
import formUrlParams from 'Helpers/urlform'
import { Link } from 'react-router-dom';
import Image from 'Components/Common/Image';
//import { CSVLink } from 'react-csv';
import dateFormat from 'dateformat';
import {  convertUTCTOLocalTimeStamp } from 'Components/Common/TimeStamp';

export default class Groups extends React.Component {
  constructor(props) {
    super(props);
    const model = {
      headers : [
        {
          title: 'Group Name',
          accessor: 'groupName' /* API response */,
          chooseOption: {
            name: 'groupNameSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          combineapidata: [ 'groupName', 'groupImage' ],
          cell: (rowDetails)=>{
            const { index,content, ...rest } = rowDetails;
            return(
            <td key={ index }>
                <div className='user'>
                  <Image imageToken={ rest.groupImage } />
                  <Link to={ `/groupinfo/${ rest.groupId }` } > { content } </Link>
                </div>
            </td>
            )            
          },
          sortOption: false,
        },
        {
          title: 'Created By',
          chooseOption: {
            name: 'createdBySearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'createdBy' /* API response */,
          sortOption: false,
        },
        {
          title: 'Created Date',
          chooseOption: {
            name: 'createdDateSearch', /* API param name */
            Component: 'DateTimeRangeComponent',
            dispatchFunction: this.handleDateSearch
          },
          cell: function (rowDetails) {
            const { index, content } = rowDetails;
            const val = content && content.replace('T',' ')
            const time = content && convertUTCTOLocalTimeStamp(val)
            return (
              <td key={ index }>
                {content && dateFormat(time, 'mmm d, yyyy / hh:MM:ss TT ')}
              </td>
            );
          },
          accessor: 'createdAt',
          sortOption: false,
        },
        {
          title: 'Admin Users',
          accessor:'adminUSers'
        },
        {
          title: 'Total Members',
          accessor: 'totalMembers'
        },
        /* {
          title: 'Status',
          chooseOption: {
            name: '',
            Component: 'DropDown',
            dispatchFunction: this.handleSearch,
            optionRender: this.createStatus
          }, 
          accessor: 'status',
          cell: function (rowDetail){
          const { content,index } = rowDetail;
          const status = (content === 1) ?
          <Fragment><span className='active'></span>Active</Fragment> :
          <Fragment><span className='inactive'></span>Inactive</Fragment>;
          return(
               <td key={ index }>{ status }</td>
          )}
        } */
      ],
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      initialPage: 1,
      activeSort: {},
    }
    this.state = model;
  }

  componentDidMount(){
    this.props.fetchGroups()
    this.props.exportGroup();
  }

  formUrlParams = (name, value) => {
    const { filtered, activeSort } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse }, () => {
      let updatedFilter = this.state.filtered;
      if(activeSort && activeSort.id){
        updatedFilter = [
          ...this.state.filtered,
          activeSort
        ]
      }
      this.props.fetchGroups(updatedFilter);
      this.props.exportGroup(updatedFilter);
    });
  }

  /**
   * @method sortTable
   * @summary sort for view data
   * @param {name} sortAttribite - Query string name
   * @return { function } formUrlSort
   */
  sortTable = (sortAttribite) => {
    const { activeSort } = this.state
    const sortType = (activeSort.id === sortAttribite) ? 
    ((activeSort.value && activeSort.value == 'desc') ? 'asc' : 'desc') : 'asc';
    this.setState({
      activeSort: { id: sortAttribite, value: sortType },
    }, () => {
      this.formUrlSort();
    })
  }

  /**
   * @method formUrlSort
   * @summary Construct the query string based on user selection
   * @return {function} call fetch admin user api after change local state
   */
  formUrlSort = () => {
    const updatedFilter = [
      ...this.state.filtered,
      this.state.activeSort
    ]
    this.props.fetchGroups(updatedFilter);
    this.props.exportGroup(updatedFilter);
  }
  
  handleDateSearch = (data) => {
    for (const [ key, value ] of Object.entries(data)) {
      this.formUrlParams(key, value);
    }
  };

  createStatus = () => {
    const statusArray = [ 'Active', 'Inactive' ]
    return statusArray.map((type, index) => {
      return (
          <option key={ index } value={ type.toLowerCase() }>{type}</option>
      )
    })
  }

  handleSearch = (event) => {
    const { name, value } = event.target;
    this.formUrlParams(name, value)
  }

  setPageLimit = ( name, value ) => {
      this.formUrlParams(name, value,{ pageLimit: value })
  }

  render() {
    const { Groups, totalRecords, currentPage, loading, exportGroupData } = this.props;
    const { pageLimit } = this.state;
    return (
      <React.Fragment>
          <div className='grid'>
            <h2>Groups</h2>
            {/* { exportGroupData && exportGroupData.length !== 0 && <CSVLink
                data = { exportGroupData }
                filename = { 'Group Details.csv' }
                className = 'btn green-bbt btn-export'
                onClick={ () => {
                  console.log('Export Data'); // Your click handling logic
                } }
              >
                Export To CSV
              </CSVLink>
            } */}
            <div className='responsive-table'>
              <DataTable className="data-table"
                keyField="id"
                width="100%"
                pagination={ {
                    enabled: true,
                    pageLength: 5,
                    type: 'long'
                } }
                headers={ this.state.headers }
                data={ Groups }
                noData="No records!" 
                loading={ loading }
                sortTable={ this.sortTable }
              />
              <Pagination
                handleClick={ this.formUrlParams }
                totalRecords={ totalRecords }
                initialPage={ currentPage }
                setPageLimit={ this.setPageLimit }
                pageLimit={ pageLimit }
                updateActiveQuery={ this.updateActiveQuery }
              />
            </div>
            
          </div>
      </React.Fragment>
    );
  }
}
