import React from 'react';
import { ReactComponent as PrevNext } from 'assets/images/svg/prevnext.svg';
import { paginationLimitArray } from 'Helpers';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {
    const { initialPage, pageLimit, totalRecords } = this.props;
    if (totalRecords > pageLimit) this.pageSetup(initialPage);
  }

  componentDidUpdate(prevProps) {
    const { pageLimit, totalRecords, initialPage } = this.props;
    if (
      totalRecords !== prevProps.totalRecords ||
      pageLimit !== prevProps.pageLimit ||
      initialPage !== prevProps.initialPage
    ) {
      this.pageSetup(initialPage);
    }
  }

  pageSetup(page) {
    const { totalRecords, pageLimit } = this.props;
    let pager = this.state.pager;
    if (page < 1 || page > pager.totalPages) {
      return;
    }
    pager = this.getPager(totalRecords, page, pageLimit);
    this.setState({ pager: pager });
  }

  setPage(page) {
    const { pager } = this.state;
    this.setState(
      {
        pager: {
          ...pager,
          currentPage: page
        }
      },
      () => {
        this.props.handleClick('page', this.state.pager.currentPage);
      }
    );
  }

  getPager(totalItems = 1, currentPage = 1, pageLimit = 10) {
    const totalPages = Math.ceil(totalItems / pageLimit);
    const startIndex = (currentPage - 1) * pageLimit;
    const endIndex = Math.min(startIndex + pageLimit - 1, totalItems - 1);
    const pages = this.pagination(currentPage, totalPages);
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageLimit: pageLimit,
      totalPages: totalPages,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPageLimit = event => {
    const { value } = event.target;
    const { pager } = this.state;
    this.setState(
      {
        pager: {
          ...pager,
          pageLimit: value
        }
      },
      () => {
        this.props.setPageLimit('size', value);
      }
    );
  };
  pagination = (currentPage, totalPages) => {
    var current = currentPage,
      last = totalPages,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };
  render() {
    const pager = this.state.pager;
    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }
    return (
      <React.Fragment>
        <div className='paginationnumbers'>
          <div className='numbers'>
            Show
            <select value={ this.props.pageLimit } onChange={ this.setPageLimit }>
              {paginationLimitArray.map((pagination, index) => {
                return (
                  <option key={ index } value={ pagination }>
                    {pagination}
                  </option>
                );
              })}
            </select>
          </div>
          <div className='pagination'>
            <ul>
              <li
                className={ pager.currentPage === 1 ? 'disabled' : '' }
                onClick={ () => this.setPage(pager.currentPage - 1) }
              >
                <i className='prev'>
                  <PrevNext />
                </i>
                Previous
              </li>
              {pager.pages.map((page, index) => (
                <li
                  key={ index }
                  onClick={ () => this.setPage(page) }
                  className={ pager.currentPage === page ? 'active' : '' }
                >
                  {page}
                </li>
              ))}
              <li
                className={
                  pager.currentPage === pager.totalPages ? 'disabled' : ''
                }
                onClick={ () => this.setPage(pager.currentPage + 1) }
              >
                Next
                <i className='next'>
                  <PrevNext />
                </i>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Pagination;
