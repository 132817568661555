import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'Actions';
import ForgotPassword from 'Components/ForgotPassword';

function mapStateToProps(state) {
    const { loading,errorElement,popup } = state.forgotpassword;
    return { loading,errorElement,popup };
}

const mapDispatchToProps = {
    forgotPassword: userActions.forgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
