import React, { Component, Fragment } from 'react';
import EditButton from 'Components/Table/EditButton';
import DeleteButton from 'Components/Table/DeleteButton';
import DataTable from 'Container/DataTable/DataTableContainer';
import formUrlParams from 'Helpers/urlform';
import AddManageAdmin from 'Container/ManageAdmin/AddManageAdminContainer';
import { ReactComponent as Add } from 'assets/images/svg/add.svg';
import Pagination from 'Components/Pagination';
import EditManageAdmin from 'Container/ManageAdmin/EditManageAdminContainer';
import Popup from 'Components/Common/PopUp';
import { debounce } from 'lodash';

/**
 * @file ManageAdmin Component
 * Admin can view, update and delete the details
 * @module 6.ManageAdmin
 * @extends React.Component
 */

export default class ManageAdmin extends Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Name',
          chooseOption: {
            name: 'nameSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.debounceEvent(this.handleSearch,500)
          },
          combineapidata: [ 'name', 'image' ],
          accessor: 'name' /* API response */,
          cell: {
            type: 'image-with-name'
          }
        },
        {
          title: 'Email',
          chooseOption: {
            name: 'emailIdSearch',
            Component: 'Search',
            dispatchFunction: this.debounceEvent(this.handleSearch,500)
          },
          accessor: 'emailId'
        },
        {
          title: 'Access Level',
          chooseOption: {
            name: 'roleIdSearch',
            Component: 'DropDown',
            DropdownText:'All',
            dispatchFunction: this.debounceEvent(this.handleSearch,500),
            optionRender: this.createAdminRoles
          },
          accessor: 'roleName'
        },
        {
          title: 'Status',
          chooseOption: {
            name: 'status',
            Component: 'DropDown',
            DropdownText:'All',
            dispatchFunction: this.debounceEvent(this.handleSearch,500),
            optionRender: this.createStatus
          },
          accessor: 'isActive',
          cell: function(rowDetail) {
            const { content, index } = rowDetail;
            const status =
              content === 1 ? (
                <Fragment>
                  <span className='active'></span>Enable
                </Fragment>
              ) : (
                <Fragment>
                  <span className='inactive'></span>Disable
                </Fragment>
              );
            return <td key={ index }>{status}</td>;
          }
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: rowDetail => {
            const { index, id, rowIdx, profile, ...rest } = rowDetail;
            const permission = profile && profile[ 'Manage admin' ];
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <EditButton
                  id={ id }
                  { ...rest }
                  getActiveEditData={ this.getActiveEditData }
                  editClass='editadmin'
                  checkPermission={ permission && permission.edit }
                />
                <DeleteButton
                  remainningRecords={ this.props.responseArray.length }
                  activeQuery={ this.state.filtered }
                  deleteActionListener={ this.props.deleteAdminUser }
                  id={ id }
                  checkPermission={ permission && permission.delete }
                  currentPage = { this.props.currentPage }
                />
              </td>
            );
          }
        }
      ],
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      activeEditData: '',
      addAdminFormStatus: false,
      editAdminFormStatus: false,
      deletepopup: false
    };
    this.state = model;
  }

  /**
   * @event componentDidMount
   * @summary API call to fetch admin  details
   * @return {Object} return manage admin object
   */
  componentDidMount() {
    this.props.fetchAdminUser();
  }

  getActiveEditData = data => {
    this.setState({ activeEditData: data, editAdminFormStatus: true }, () => {
      document.getElementById(data.editClass).classList.add('open');
    });
  };
  closePopUp = event => {
    event.preventDefault();
    this.setState({
      deletepopup: false
    });
  };
  openPopUp = event => {
    event.preventDefault();
    this.setState({
      deletepopup: true
    });
  };
  createStatus = () => {
    const statusArray = [ 'Disable', 'Enable' ];
    return statusArray.map((admin, index) => {
      return (
        <option key={ index } value={ index }>
          {admin}
        </option>
      );
    });
  };
    /**
   * @method debounceEvent 
   * @summary used to delay the event for some limit
   * @param {event} event - React onChange event
   * @return { function } debouncedEvent
   */
  debounceEvent(...args) {
    this.debouncedEvent = debounce(...args);
    return e => {
      e.persist();
      return this.debouncedEvent(e);
    };
  }

  /**
   * @method createAdminRoles
   * @summary Render access level options dynamically based on admin roles
   * @return {none} undefined
   */

  createAdminRoles = () => {
    const { adminRoles } = this.props;
    if (!adminRoles || adminRoles.length <= 0) return null;
    return adminRoles.map((admin, index) => {
      return (
        <option data-rolename={ admin.roleName } key={ index } value={ admin.id }>
          {admin.roleName}
        </option>
      );
    });
  };

  /**
   * @method formUrlParams
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @param {rest} rest - Rest object except name and value
   * @summary Construct the query string based on user selection
   * @return {function} call fetch admin user api after change local state
   */

  formUrlParams = (name, value, rest) => {
    const { filtered } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse, ...rest }, () => {
      this.props.fetchAdminUser(this.state.filtered);
    });
  };

  /**
   * @method handleSearch
   * @summary set the username and password state
   * @param {event} event - React onChange event
   * @return { function } formUrlParams
   */
  handleSearch = event => {
    const { name, value } = event.target;
    this.formUrlParams(name, value);
  };

  /**
   * @method hanldeAddAdmin
   * @summary open admin popup based click event
   * @return {none} undefined
   */

  hanldeAddAdmin = () => {
    this.setState({ addAdminFormStatus: true }, () => {
      document.getElementById('addadmin').classList.add('open');
    });
  };

  /**
   * @method setPageLimit
   * @summary set page limit for view data per page
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @return { function } formUrlParams
   */

  setPageLimit = (name, value) => {
    this.formUrlParams(name, value, { pageLimit: value });
  };

  /**
   * @method closeAddAdmin
   * @summary close add admin popup
   * @return { object } updated manage admin state
   */

  closeAddAdmin = () => {
    this.setState({ addAdminFormStatus: false, filtered: [] });
  };

  /**
   * @method closeEditAdmin
   * @summary close edit admin popup
   * @return { object } updated manage admin state
   */

  closeEditAdmin = () => {
    this.setState({ editAdminFormStatus: false });
  };

  render() {
    const {
      responseArray,
      totalRecords,
      currentPage,
      loading,
      profile
    } = this.props;
    const { addAdminFormStatus, editAdminFormStatus, deletepopup } = this.state;
    const Permission = profile && profile[ 'Manage admin' ];
    return (
      <Fragment>
        <div className='grid admin'>
          <div className='head'>
            <h2>Manage Admin</h2>
            {/* <DeletePopup /> */}
            {addAdminFormStatus && (
              <AddManageAdmin
                optionRender={ this.createAdminRoles }
                closeAddAdmin={ this.closeAddAdmin }
                filterVal = { this.state.filtered }
              />
            )}
            {editAdminFormStatus && (
              <EditManageAdmin
                { ...this.state.activeEditData }
                closeEditAdmin={ this.closeEditAdmin }
              />
            )}
            {Permission && Permission.create && (
              <button className='blue-bbt' onClick={ this.hanldeAddAdmin }>
                <Add />
                Add Admin
              </button>
            )}
          </div>
          <div className='responsive-table'>
            <DataTable
              className='data-table'
              headers={ this.state.headers }
              data={ responseArray }
              noData='No records!'
              loading={ loading }
            />
             <Pagination
            handleClick={ this.formUrlParams }
            totalRecords={ totalRecords }
            initialPage={ currentPage }
            setPageLimit={ this.setPageLimit }
            pageLimit={ this.state.pageLimit }
          />
          </div>
        </div>
        {deletepopup && (
          <Popup
            popupText='Are you sure want to delete this?'
            handleYes={ this.props.deleteAdminUser }
            handleCancel={ this.closePopUp }
          />
        )}
      </Fragment>
    );
  }
}
