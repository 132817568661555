import React from 'react';
import comingsoon from '../../assets/images/comingsoon.svg';

export default function ComingSoon() {
  return (
    <div className='coming-soon'>
      <div className='content-wrapper'>
        <div className='image'>
          <img src={ comingsoon } />
          <h4>Coming soon</h4>
        </div>
      </div>
    </div>
  );
}
