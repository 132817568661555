import React, { Component, Fragment } from 'react';
import { ReactComponent as Audio } from 'assets/images/svg/incomingcalls.svg';
import { ReactComponent as Videocamera } from 'assets/images/svg/videocamera.svg';
import { ReactComponent as Chatbubbles } from 'assets/images/svg/chatbubbles.svg';
import { ReactComponent as Groups } from 'assets/images/svg/groups.svg';
import { ReactComponent as Broadcasts } from 'assets/images/svg/broadcasts.svg';
import { ReactComponent as Calendar } from 'assets/images/svg/calendar.svg';
import Vectorgraph from 'assets/images/vector.png';
import Graph from 'assets/images/graph.PNG';

export default class Dashboard extends Component {
  render() {
    return (
      <Fragment>
        <div className='dashboard'>
          <div className='head'>
            <h2>Analytics Overview</h2>
            <div className='status'>
              <i>
                <Calendar />
              </i>
              <select>
                <option></option>
              </select>
            </div>
          </div>

          <div className='dashboard-sections'>
            <section className='all_logs'>
              <div className='calles list'>
                <div className='audio-video'>
                  <i className='callsic'>
                    <Audio />
                  </i>
                  <div className='list-logs'>
                    <label>
                      1.7K<sup>mins</sup>
                    </label>
                    <label>
                      186<sup>Calls</sup>
                    </label>
                  </div>
                  <span>Audio call</span>
                </div>
                <div className='audio-video'>
                  <i className='videoic'>
                    <Videocamera />
                  </i>
                  <div className='list-logs'>
                    <label>
                      1.7K<sup>mins</sup>
                    </label>
                    <label>
                      186<sup>Calls</sup>
                    </label>
                  </div>
                  <span>Audio call</span>
                </div>
              </div>
              <div className='messages list'>
                <i className='chatic'>
                  <Chatbubbles />
                </i>
                <div className='list-logs'>
                  <label>8.2K</label>
                </div>
                <span>Messages</span>
              </div>
              <div className='groups list'>
                <i className='groupic'>
                  <Groups />
                </i>
                <div className='list-logs'>
                  <label>8.2K</label>
                </div>
                <span>Messages</span>
              </div>
              <div className='broadcasts list'>
                <i className='broadcastsic'>
                  <Broadcasts />
                </i>
                <div className='list-logs'>
                  <label>1,019</label>
                </div>
                <span>Broadcasts</span>
              </div>
            </section>
            <section className='users'>
              <div className='block'>
                <div className='head'>
                  <h2>Total Users</h2>
                  <div className='status'>
                    <i className='calendar'>
                      <Calendar />
                    </i>
                  </div>
                </div>
                <div className='content'>
                  <div className='graph'>
                    <img src={ Vectorgraph } />
                  </div>
                  <div className='labels'>
                    <label>
                      <i className='green'></i>New Users
                    </label>
                    <label>
                      <i className='violet'></i>Active Users
                    </label>
                  </div>
                </div>
              </div>
              <div className='block'>
                <div className='head'>
                  <h2>Android and iOS Users</h2>
                  <div className='status'>
                    <i className='calendar'>
                      <Calendar />
                    </i>
                    <select>
                      <option></option>
                    </select>
                  </div>
                </div>
                <div className='content'>
                  <div className='graph'>
                    <img src={ Vectorgraph } />
                  </div>
                  <div className='labels'>
                    <label>
                      <i className='aash'></i>New Users
                    </label>
                  </div>
                </div>
              </div>
            </section>
            <section className='users'>
              <div className='block'>
                <div className='head'>
                  <h2>Total Server Space</h2>
                  <div className='status'>
                    <i className='calendar'>
                      <Calendar />
                    </i>
                    <select>
                    </select>
                  </div>
                </div>
                <div className='content'>
                  <div className='graph'>
                    <img src={ Vectorgraph } />
                  </div>
                  <div className='labels'>
                    <label>
                      <i className='green'></i>New Users
                    </label>
                  </div>
                </div>
              </div>
              <div className='block'>
                <div className='head'>
                  <h2>Country Wise Users</h2>
                  <div className='status'>
                    <i>
                      <Calendar />
                    </i>
                    <select>
                      <option></option>
                    </select>
                  </div>
                </div>
                <div className='content'>
                  <div className='graph'>
                    <img src={ Vectorgraph } />
                  </div>
                </div>
              </div>
            </section>
            <section className='users '>
              <div className='full-block'>
                <div className='head'>
                  <h2>Total Server bandwidth Usage</h2>
                  <div className='status'>
                    <i className='calendar'>
                      <Calendar />
                    </i>
                    <select>
                      <option></option>
                    </select>
                  </div>
                </div>
                <div className='content'>
                  <div className='list-logs'>
                    <div className='logs'>
                      <i></i>
                      <label>
                        221<sup>GB</sup>
                        <span>Total Server bandwidth Usage</span>
                      </label>
                    </div>
                    <div className='logs'>
                      <i className='yellow'></i>
                      <label>
                        186<sup>GB</sup>
                        <span>Total Server bandwidth Usage</span>
                      </label>
                    </div>
                    <div className='logs'>
                      <i className='aash'></i>
                      <label>
                        86<sup>GB</sup>
                        <span>Total Server bandwidth Usage</span>
                      </label>
                    </div>
                  </div>
                  <div className='graph'>
                    <img src={ Graph } />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}
