import React, { Component, Fragment } from 'react';
import DataTable from 'Components/Table/dataTable';
import { ReactComponent as Android } from 'assets/images/svg/android.svg';
import { ReactComponent as Phonebook } from 'assets/images/svg/phonebook.svg';
import { ReactComponent as Group } from 'assets/images/svg/group.svg';
import { ReactComponent as Ios } from 'assets/images/svg/apple.svg';
import Image from 'Components/Common/Image';
import dateFormat from 'dateformat';
import {  convertUTCTOLocalTimeStamp } from 'Components/Common/TimeStamp';

export default class Usermgnt extends Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Name',
          chooseOption: {
            name: 'nameSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          combineapidata: [ 'name', 'image' ],
          accessor: 'name' /* API response */,
          cell: rowDetails => {
            const { index, content, username, ...rest } = rowDetails;
            return (
              <td key={ index }>
                <div
                  className='user'
                  onClick={ () => this.getActiveUser(username) }
                >
                  <Image imageToken={ rest.image } />
                  <label>{content}</label>{' '}
                </div>
              </td>
            );
          },
          sortOption: false,
        },
        {
          title: 'Email',
          chooseOption: {
            name: 'emailIdSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'emailId',
          sortOption: false,
        },
        {
          title: 'Mobile',
          chooseOption: {
            name: 'mobileSearch',
            type: 'number',
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'mobileNumber',
          sortOption: false,
        },
        {
          title: 'Status',
          accessor: 'status',
          sortOption: false,
        },
        {
          title: 'Device Patform',
          chooseOption: {
            name: 'deviceSearch',
            Component: 'DropDown',
            dispatchFunction: this.handleSearch,
            optionRender: this.props.status,
            DropdownText:'All'
          },
          accessor: 'deviceType',
          cell: function(rowDetails) {
            const { content, index } = rowDetails;
            const deviceType =
              content !== '' ? (
                content === 'android' ? (
                  <Fragment>
                    <i className='android'>
                      <Android />
                    </i>
                    {content}
                  </Fragment>
                ) : content === 'ios' ? (
                  <Fragment>
                    <i className='android'>
                      <Ios />
                    </i>
                    {content}
                  </Fragment>
                ) : (
                  ''
                )
              ) : (
                ''
              );
            return <td key={ index }>{deviceType}</td>;
          },
          sortOption: false,
        },
        {
          title: 'Contacts',
          accessor: 'contacts',
          cell: function(rowDetails) {
            const { index, content } = rowDetails;
            const phonebook = (
              <Fragment>
                <i className='phonebook'>
                  <Phonebook />
                </i>
                {content} Members
              </Fragment>
            );
            return <td key={ index }>{phonebook}</td>;
          },
          sortOption: false,
        },
        {
          title: 'Groups',
          accessor: 'groups',
          cell: function(rowDetails) {
            const { index, content } = rowDetails;
            return (
              <td key={ index }>
                <Fragment>
                  <i className='group'>
                    <Group />
                  </i>
                  { content ? content : '0' } Groups
                </Fragment>
              </td>
            );
          },
          sortOption: false,
        }
      ]
    };
    this.state = model;
  }

  handleSearch = event => {
    const { name, value } = event.target;
    this.props.formUrl(name, value);
  };
  getActiveUser = (username) => {
    this.props.getActiveUserData(username);
  };

  render() {
    const { usersList, loading } = this.props;
    return (
      <Fragment>
        <div className='grid'>
          <div className='responsive-table'>
            <DataTable
              className='data-table'
              headers={ this.state.headers }
              data={ usersList }
              noData='No records!'
              loading={ loading }
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
