import React from 'react';
import GroupAvatar from 'assets/images/groupavatar.svg';
import DataTable from 'Components/Table/dataTable';
import Pagination from 'Components/Pagination/index';
import formUrlParams from 'Helpers/urlform'
//import { CSVLink } from 'react-csv';
import {  formatDateTime } from 'Components/Common/TimeStamp';

export default class GroupInfo extends React.Component {
  constructor(props) {
    super(props);
    const model = {
      headers : [
        {
          title: 'Name',
          chooseOption: {
            name: 'nameSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'name' /* API response */,
        },
        {
          title: 'Email',
          chooseOption: {
            name: 'emailSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
        },
          accessor: 'email' /* API response */,
        },
        {
          title: 'Mobile',
          chooseOption: {
            name: 'mobileSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
        },
          accessor: 'mobile'
        },
        {
          title: 'Status',
          accessor: 'status',
        }
      ],
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      initialPage: 1,
      activeSort: {},
      groupId: '',
      groupImage: GroupAvatar,
      groupName: '',
      createdDate: '',
      totalMembers: '',
      status: '',
    }
    this.state = model;
  }

  componentDidMount(){
    const { match: { params: { id: groupId } } } = this.props;
    this.setState({ groupId: groupId })
    this.props.fetchGroupDetails(groupId)
    this.props.exportGroupInfo(groupId)
  }

  componentDidUpdate(prevProps) {
    const { id, GroupsInfoList  = { } } = this.props; 
    const { id: prevId } = prevProps;
    if(prevId !== id) {
      const groupInfo = GroupsInfoList && GroupsInfoList.length > 0 ? GroupsInfoList[ 0 ] : '';
      groupInfo && this.setState({
        groupImage: groupInfo.groupImage ? groupInfo.groupImage : GroupAvatar,
        groupName: groupInfo.groupName,
        totalMembers: groupInfo.totalMembers,
        createdDate: formatDateTime(groupInfo.createdAt),
      })
    }
  }

  formUrlParams = (name, value) => {
    const { filtered, activeSort, groupId } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse }, () => {
      let updatedFilter = this.state.filtered;
      if(activeSort && activeSort.id){
          updatedFilter = [
              ...this.state.filtered,
              activeSort
          ]
      }
      this.props.fetchGroupDetails(groupId, updatedFilter);
      this.props.exportGroupInfo(groupId, updatedFilter)
    });
  }
  
  /**
   * @method sortTable
   * @summary sort for view data
   * @param {name} sortAttribite - Query string name
   * @return { function } formUrlSort
   */
  sortTable = (sortAttribite) => {
    const { activeSort } = this.state
    const sortType = (activeSort.id === sortAttribite) ? 
    ((activeSort.value && activeSort.value == 'desc') ? 'asc' : 'desc') : 'asc';
    this.setState({
      activeSort: { id: sortAttribite, value: sortType },
    }, () => {
      this.formUrlSort();
    })
  }

  /**
   * @method formUrlSort
   * @summary Construct the query string based on user selection
   * @return {function} call fetch admin user api after change local state
   */
  formUrlSort = () => {
    const updatedFilter = [
      ...this.state.filtered,
      this.state.activeSort
    ]
    this.props.fetchGroupDetails(this.state.groupId, updatedFilter);
    this.props.exportGroupInfo(this.state.groupId, updatedFilter)
  }

  handleSearch = (event) => {
    const { name, value } = event.target;
    this.formUrlParams(name, value)
  } 
  setPageLimit = ( name, value ) => {
    this.formUrlParams(name, value,{ pageLimit: value })
  }
  render() {
    const { GroupsInfoList, exportGroupInfoData, totalRecords, currentPage, loading } = this.props
    const { groupImage, groupName, totalMembers, createdDate, status, pageLimit } = this.state;
    return (
      <React.Fragment>
          <div className='grid'>
            <h2>Group Info & Members of Walkie Talkies</h2>
            {/* { exportGroupInfoData && exportGroupInfoData.length !== 0 && <CSVLink
                  data = { exportGroupInfoData }
                  filename = { 'Group Information.csv' }
                  className = 'btn green-bbt btn-export'
                  onClick={ () => {
                    console.log('Export Data'); // Your click handling logic
                  } }
                >
                  Export To CSV
                </CSVLink>
            } */}
            <div className='groupinfo'>
              <div className='info'>
                <div className='left'>
                  <div className='groupimg' name="groupImage">
                    <img src={ groupImage } />
                  </div>
                  <div className='group'>
                    <div className='formgroup'>
                      <label>Group Name:</label>
                      <span>{ groupName }</span>
                    </div>
                    <div className='formgroup'>
                      <label>Total Members:</label>
                      <span>{ totalMembers }</span>
                    </div>
                  </div>
                </div>
                <div className='right'>
                  <div className='group'>
                    <div className='formgroup'>
                      <label>Created Date & Time:</label>
                      <span>{ createdDate && formatDateTime(createdDate) }</span>
                    </div>
                    <div className='formgroup'>
                      <label>Status:</label>
                      <span>
                        <i className='active'></i>{ status }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='responsive-table'>
              <DataTable className="data-table"
                keyField="id"
                width="100%"
                pagination={ {
                    enabled: true,
                    pageLength: 5,
                    type: 'long'
                } }
                headers={ this.state.headers }
                data={ GroupsInfoList }
                noData="No records!" 
                sortTable={ this.sortTable }
                loading={ loading }
              />
              <Pagination
                handleClick={ this.formUrlParams }
                totalRecords={ totalRecords }
                initialPage={ currentPage }
                setPageLimit={ this.setPageLimit }
                pageLimit={ pageLimit }
                updateActiveQuery={ this.updateActiveQuery }
              />
            </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}
