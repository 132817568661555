import React, { Component, Fragment } from 'react';
import { ReactComponent as Delete } from 'assets/images/svg/delete.svg';
import Popup from 'Components/Common/PopUp';

export default class DeleteButton extends Component {
  constructor(props){
    super(props)
    this.state={
      deletepopup:false
    }
  }
  handlePopup = () =>{
    this.setState({ deletepopup:true })
  }
  closePopUp = () => {
    this.setState({ deletepopup:false })
  }
  handleDelete = () => {
    this.setState({ deletepopup:false })
    const { id, activeQuery, remainningRecords,currentPage } = this.props;
    if (!id) return;
    this.props.deleteActionListener(id, activeQuery, remainningRecords,currentPage);
  };
  render() {
    const { deletepopup } = this.state
    return (
      <Fragment>
      <button disabled={ this.props.checkPermission ? null : 'disabled' } onClick={ this.handlePopup }>
        <Delete />
      </button>
      { deletepopup &&
      <Popup popupText='Are you sure want to delete this?' handleYes={ this.handleDelete } handleCancel={ this.closePopUp } />
      }
      </Fragment>

    );
  }
}
