import React, { Component, Fragment } from 'react';
import fileTokenToBase64 from 'Helpers'
import User from 'assets/images/user.svg';
import Loader  from 'assets/images/svg/loader.svg';

export default class Image extends Component {

    checkNotFound = (event) => {
        const { placeholder, checkNotFound } = this.props
        if( placeholder ) checkNotFound() 
        event.target.src = placeholder ? placeholder : User;
    }

    afterImageLoad = ( event ) => event.target.style.backgroundImage = '';

    render() {
        const { imageToken, style, ...rest } = this.props;
        const imageUrl = fileTokenToBase64(imageToken);
        
        return (
            <Fragment>
                <img { ...rest } onLoad = { this.afterImageLoad } style={ style ,{ backgroundImage: `url(${ Loader })` } }   onError={ this.checkNotFound }  src={ imageUrl } />
            </Fragment>
        )
    }
}
