export const adminRolesConstants = {
    ADMIN_ROLES_REQUEST:'ADMIN_ROLES_REQUEST',
    ADMIN_ROLES_GET_SUCCESS:'ADMIN_ROLES_GET_SUCCESS',
    ADMIN_ROLES_UPDATE_SUCCESS:'ADMIN_ROLES_UPDATE_SUCCESS',
    ALL_ADMIN_ROLES_GET_SUCCESS:'ALL_ADMIN_ROLES_GET_SUCCESS',
    ADMIN_ROLES_ADD_SUCCESS:'ADMIN_ROLES_ADD_SUCCESS',
    ADMIN_ROLES_DELETE_SCCESS:'ADMIN_ROLES_DELETE_SCCESS',
    ADMIN_ROLES_FAILURE:'ADMIN_ROLES_FAILURE',
    ALLOWED_MODULES_GET_SUCCESS:'ALLOWED_MODULES_GET_SUCCESS',
    ALLOWED_MODULES_FAILURE:'ALLOWED_MODULES_FAILURE',
    ADMIN_ROLES_FORM_FAILURE:'ADMIN_ROLES_FORM_FAILURE',
    ADMIN_ROLES_FORM_REQUEST:'ADMIN_ROLES_FORM_REQUEST',
    ADMIN_ROLES_EDIT_FORM_FAILURE:'ADMIN_ROLES_EDIT_FORM_FAILURE',
    ADMIN_DELETE_ROLES_FAILURE:'ADMIN_DELETE_ROLES_FAILURE'
}
