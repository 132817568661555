import React from 'react';
import { connect } from 'react-redux';
import { manageAdminActions } from 'Actions';
import ManageAdmin from 'Components/Admin/ManageAdmin/index';

function mapStatetoProps(state){
    const { allRoles } = state.adminRoles;
    const { profile } = state
    return { 
        ...state.manageAdmin,
        ...state.totalRecords,  
        profile:profile.permission && JSON.parse(profile.permission),
        adminRoles:allRoles
    }
}

const mapDispatchtoProps = {
    fetchAdminUser:manageAdminActions.fetchAdminUser,
    fetchAdminRoles:manageAdminActions.fetchAdminRoles,
    deleteAdminUser:manageAdminActions.deleteAdminUser
}

export default connect(mapStatetoProps,mapDispatchtoProps)(ManageAdmin);