import React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currentYear = new Date().getFullYear();
    return (
        <React.Fragment>
            <footer>
                <h4>Copyrights &copy; { currentYear } Mirror Fly. All Rights Reserved.</h4>
            </footer>
        </React.Fragment>
    );
  }
}
export default Footer;
