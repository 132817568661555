import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboard from 'Components/Dashboard';

const PrivateRoute = ({ component: Component, profile, name , ...rest }) => {
  const allowedModule = profile && profile[ name ]
  return(
    <Route
    { ...rest }
    render={ (props) => 
      (localStorage.getItem('user')  
      ? ((allowedModule && allowedModule.view ) || (name === '') ? 
      <Component { ...props } /> : <Dashboard { ...props }/> ) 
      : <Redirect to={ { pathname: '/login', state: { from: props.location } } } /> )
  }
  />
  ) };

function mapStatetoProps(state){
  const { profile } = state
  return {
      profile:profile.permission && JSON.parse(profile.permission),
  }
}

export default connect(mapStatetoProps)(PrivateRoute);