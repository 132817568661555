const profileImage = localStorage.getItem('profileimage')
const broadCastSample = {
    status:200,
    count:8,
    message:'test sample BroadCast',
    data:[
        {
            messages:'broadcastmessages1',
            id:1,
            messagesType:'messagesType1',
            teamName:'teamName1',
            createdBy:{
            name:'name1',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:1,
            status:1
        },
        {
            messages:'broadcastmessages2',
            id:2,
            messagesType:'messagesType2',
            teamName:'teamName2',
            createdBy:{
            name:'name2',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:0,
            status:0
        },
        {
            messages:'broadcastmessages3',
            id:3,
            messagesType:'messagesType3',
            teamName:'teamName3',
            createdBy:{
            name:'name3',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:1,
            status:1
        },
        {
            messages:'broadcastmessages4',
            id:4,
            messagesType:'messagesType4',
            teamName:'teamName4',
            createdBy:{
            name:'name',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:1,
            status:0
        },
        {
            messages:'broadcastmessages5',
            id:5,
            messagesType:'messagesType5',
            teamName:'teamName5',
            createdBy:{
            name:'name',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:0,
            status:0
        },
        {
            messages:'broadcastmessages6',
            id:6,
            messagesType:'messagesType6',
            teamName:'teamName6',
            createdBy:{
            name:'name',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:1,
            status:1
        },
        {
            messages:'broadcastmessages7',
            id:7,
            messagesType:'messagesType7',
            teamName:'teamName7',
            createdBy:{
            name:'name',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:1,
            status:1
        },
        {
            messages:'broadcastmessages8',
            id:8,
            messagesType:'messagesType8',
            teamName:'teamName8',
            createdBy:{
            name:'name',
            image:`${ profileImage }`
            },
            createdDateTime:'Jul 2 2019 6:22',
            broadcastDateTime:'Jul 2 2019 6:22',
            deliveryStatus:1,
            status:1
        },
        
    ]
}

export default broadCastSample;