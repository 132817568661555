import React, { Fragment } from 'react';
import EditButton from 'Components/Table/EditButton';
import DeleteButton from 'Components/Table/DeleteButton';
import { ReactComponent as Add } from 'assets/images/svg/add.svg';
import EditTeamBroadcast from 'Container/BroadCast/Team/EditTeamContainer';
import AddTeam from 'Container/BroadCast/Team/AddTeamContainer';
import Pagination from 'Components/Pagination/index';
import DataTable from 'Container/DataTable/DataTableContainer';
import formUrlParams from 'Helpers/urlform';
import { debounce } from 'lodash';
import dateFormat from 'dateformat';
import {  convertUTCTOLocalTimeStamp } from 'Components/Common/TimeStamp';

/**
 * @file Team Component
 * Admin can view, update and delete the team details
 * @module 8.Team
 * @extends React.Component
 */

class TeamBroadcast extends React.Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Team Name',
          chooseOption: {
            name: 'nameSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.debounceEvent(this.handleSearch, 200),
          },
          combineapidata: [ 'teamName', 'image' ],
          accessor: 'teamName' /* API response */,
          cell: {
            type: 'image-with-name',
          },
        },
        {
          title: 'About Team',
          accessor: 'aboutTeam',
          cell: function (rowDetails) {
            const { index, content } = rowDetails;
            return (
              <td className='textlimit' key={ index }>
                {content}
              </td>
            );
          },
        },
        {
          title: 'Created Date',
          chooseOption: {
            name: 'createdDateSearch',
            Component: 'DateTimeRangeComponent',
            dispatchFunction: this.handleDateSearch,
          },
          accessor: 'createdAt',
          cell: function (rowDetails) {
            const { index, content } = rowDetails;
            const val = content && content.replace('T',' ')
            const time = content && convertUTCTOLocalTimeStamp(val)
            return (
              <td key={ index }>
                {content && dateFormat(time, 'mmm d, yyyy / hh:MM:ss TT ')}
              </td>
            );
          },
        },
        {
          title: 'Users Count',
          accessor: 'teamMembersCount',
        },
        {
          title: 'Status',
          chooseOption: {
            name: 'statuSearch',
            Component: 'DropDown',
            DropdownText: 'All',
            dispatchFunction: this.debounceEvent(this.handleSearch, 500),
            optionRender: this.createStatus,
          },
          accessor: 'isActive',
          cell: function (rowDetail) {
            const { content, index } = rowDetail;
            const status =
              content === 1 ? (
                <Fragment>
                  <span className='active'></span>Enable
                </Fragment>
              ) : (
                <Fragment>
                  <span className='inactive'></span>Disable
                </Fragment>
              );
            return <td key={ index }>{status}</td>;
          },
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: (rowDetail) => {
            const { index, id, rowIdx, profile, ...rest } = rowDetail;
            const permission = profile && profile.Team;
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <EditButton
                  id={ id }
                  { ...rest }
                  getActiveEditData={ this.getActiveEditData }
                  editClass='editteam'
                  checkPermission={ permission && permission.edit }
                />
                <DeleteButton
                  remainningRecords={ this.props.team.length }
                  deleteActionListener={ this.props.deleteTeam }
                  activeQuery={ this.state.filtered }
                  id={ id }
                  currentPage={ this.props.currentPage }
                  checkPermission={ permission && permission.delete }
                />
              </td>
            );
          },
        },
      ],
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      permission: true,
      fromDate: '',
      toDate: '',
    };
    this.state = model;
  }

  /**
   * @event componentDidMount
   * @summary API call to fetch team  details
   * @return {Object} return team object
   */

  componentDidMount() {
    this.props.fetchTeam();
  }

  getActiveEditData = (data) => {
    this.props.fetchEditObj(data);
    this.props.history.push(`/broadcast/team/editteam`);
  };

  /**
   * @method formUrlParams
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @param {rest} rest - Rest object except name and value
   * @summary Construct the query string based on user selection
   * @return {function} call fetch admin user api after change local state
   */

  formUrlParams = (name, value, rest) => {
    const { filtered } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse, ...rest }, () => {
      this.props.fetchTeam(this.state.filtered);
    });
  };

  /**
   * @method handleSearch
   * @summary set the  state
   * @param {event} event - React onChange event
   * @return { function } formUrlParams
   */

  handleSearch = (event) => {
    const { name, value } = event.target;
    this.formUrlParams(name, value);
  };

  handleDateSearch = (data) => {
    for (const [ key, value ] of Object.entries(data)) {
      this.formUrlParams(key, value);
    }
  };

  /**
   * @method hanldeAddAdmin
   * @summary open admin popup based click event
   * @return {none} undefined
   */

  hanldeAddTeam = () => {
    this.props.history.push(`/broadcast/team/addteam`);
  };

  /**
   * @method setPageLimit
   * @summary set page limit for view data per page
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @return { function } formUrlParams
   */

  setPageLimit = (name, value) => {
    this.formUrlParams(name, value, { pageLimit: value });
  };

  createStatus = () => {
    const statusArray = [ 'Disable', 'Enable' ];
    return statusArray.map((admin, index) => {
      return (
        <option key={ index } value={ index }>
          {admin}
        </option>
      );
    });
  };
  /**
   * @method debounceEvent
   * @summary used to delay the event for some limit
   * @param {event} event - React onChange event
   * @return { function } debouncedEvent
   */
  debounceEvent(...args) {
    this.debouncedEvent = debounce(...args);
    return (e) => {
      e.persist();
      return this.debouncedEvent(e);
    };
  }

  updateActiveQuery = (updatedpagination) => {
    this.setState({
      filtered: [ ...this.state.filtered, ...updatedpagination ],
    });
  };

  render() {
    const { currentPage, totalRecords, team, profile, loading } = this.props;
    const Permission = profile && profile.Team;

    return (
      <Fragment>
        <div className='grid team'>
          <div className='head'>
            <h2> Team Broadcast</h2>
            {Permission && Permission.create && (
              <button className='blue-bbt' onClick={ this.hanldeAddTeam }>
                <Add />
                New Team
              </button>
            )}
          </div>
          <div className='responsive-table'>
            <DataTable
              headers={ this.state.headers }
              data={ team }
              noData='No records!'
              loading={ loading }
            />
            <Pagination
              handleClick={ this.formUrlParams }
              totalRecords={ totalRecords }
              initialPage={ currentPage }
              setPageLimit={ this.setPageLimit }
              pageLimit={ this.state.pageLimit }
              updateActiveQuery={ this.updateActiveQuery }
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default TeamBroadcast;
