import { teamConstants } from 'Constants/team.constants';

const initialState = {
    responseArray: [],
    usersList:[],
    loading: false,
    totalRecords: 0,
    error:false,
    currentPage:1,
    usersTotalRecords:'',
    usersCurrentPage:'',
    formLoading:false,
    errorElement:'',
    editErrorElement:''
  }
  
  export function team(state = initialState, action={}) {
    const { team, usersListObject, editObj } = action;
   
    switch (action.type) {
      case teamConstants.TEAM_REQUEST:
        return { ...state, loading: true, error:null };
      case teamConstants.TEAM_GET_SUCCESS:
        return {
          ...state,
          responseArray: (team ) ? [ ...team ] : [],
          totalRecords: action.totalRecords,
          currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
          loading: false,
          formLoading:false
        };
      case teamConstants.ADD_TEAM_SUCCESS:
        return {
          ...state,                                                       
          responseArray: [ ...state.responseArray, team ],
          formLoading: false,
          error:false,
          errorElement:''
        };
      case teamConstants.UPDATE_TEAM_SUCCESS:
        return {
            ...state,
            responseArray: state.responseArray.map(( res ) => {
              if(res.id == team.id) return team;
              return res;
            }),
            formLoading: false,
            error:false,
            editErrorElement:''
        }
      case teamConstants.DELETE_TEAM_SUCCESS:
        return {
            ...state,
            responseArray: state.responseArray.filter(( teamObj ) => {
              return action.updateObject.ids.indexOf(teamObj.id)
            }),
            totalRecords: state.totalRecords - action.updateObject.updateRecords,
            loading: false,
            error:false
        }
      case teamConstants.TEAM_FAILURE:
        return {
            ...state,
            loading: false,   
            error:true
        } 
      case teamConstants.USERS_REQUEST:
          return { 
            ...state,
            formLoading:true,
            errorElement:'',
            editErrorElement:''
           }
      case teamConstants.USERS_SUCCESS:
          return {
              ...state,
              usersList: (usersListObject) ? [ ...usersListObject ] : [],
              usersTotalRecords:action.totalRecords,
              usersCurrentPage: (action.currentPage) ? action.currentPage : state.currentPage,
              formLoading:false
            };
      case teamConstants.USERS_FAILURE:
          return { 
            ...state,
            formLoading:false
           }
      case teamConstants.TEAM_ADD_FORM_FAILURE:
       return { 
         ...state,
         formLoading:false,
         errorElement:action.error
        }
      case teamConstants.TEAM_EDIT_FORM_FAILURE:
       return { 
           ...state,
           formLoading:false,
           editErrorElement:action.error
         }
      case teamConstants.EDIT_FORM_REQUEST:
        return { 
            ...state,
            editFormObj: editObj,
            formLoading: false,
          }
  
      default:
        return state;
    }
  }
