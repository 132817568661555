import React, { Fragment } from 'react';
import routes from 'Routes/routes';
import PrivateRoute from 'Routes/PrivateRoute';
import { Route } from 'react-router-dom';
import Footer from 'Components/Common/Footer';
import Header from 'Components/Common/Header';
import Sidemenu from 'Components/Common/SideMenu';

function SwitchRoutes() {
  return (
    <Fragment>
      <Sidemenu /> <Header />
      <div className='main-container' id='maincontainer'>
        {routes.map((prop, key) =>
          prop.route === 'private' ? (
            <PrivateRoute
              path={ prop.path }
              component={ prop.component }
              key={ key }
              exact={ prop.exact }
              name= { prop.name }
            />
          ) : (
            <Route
              path={ prop.path }
              component={ prop.component }
              key={ key }
              exact={ prop.exact }
            />
          )
        )}
      </div>
      <Footer />
    </Fragment>
  );
}

export default SwitchRoutes;
