import { settingsConstants } from 'Constants/settings.constants';

const initialState = {
  id:null,
  mailDomain: '',
  adminUser: '',
  audioLimit: '',
  videoLimit: '',
  mailLogin: 'no',
  loading: false
}

export function settings(state = initialState, action={}) {
  const { settings } = action;
  switch (action.type) {
    case settingsConstants.SETTINGS_REQUEST:
      return { ...state, loading: true };
    case settingsConstants.SETTINGS_GET_SUCCESS:
      return {
        ...state,
        ...settings,
        loading: false,
      };
    case settingsConstants.SETTINGS_UPDATE_SUCCESS: 
      return {
        ...state,
        ...settings,
        loading: false
      };   
    case settingsConstants.SETTINGS_FAILURE:
      return { ...state, loading: false  };
    default:
      return state;
  }
}
