import React, { Component } from 'react';
import Camera, {  IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
 
class TakePhoto extends Component{
  handleTakePhoto =  (dataurl,filename) => {
      var arr = dataurl.split(','),
      mime = arr[ 0 ].match(/:(.*?);/)[ 1 ];
      const u8arr = Uint8Array.from(atob(arr[ 1 ]), c => c.charCodeAt(0));
      const file =  new File([ u8arr ], filename, { type: mime });
      this.props.dispatchFunction(mime,file)
  }
  handleCameraError = (error) => {
    this.props.cameraError(error)
  }
  render(){
    return (
      <Camera
        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri,'image.jpg'); } }
        idealResolution = { { width: 200, height: 200 } }
        isMaxResolution = { true }
        isImageMirror = { true }
        isSilentMode = { false }
        isDisplayStartCameraError = { true }
        isFullscreen = { false }
        sizeFactor = { 1 }
        imageType = { IMAGE_TYPES.JPG }
        onCameraError = { (error) => { this.handleCameraError(error); } }

      />
    )}
}
export default TakePhoto;