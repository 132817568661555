import React from 'react'
import { connect } from 'react-redux';
import { userConatctsActions } from 'Actions';
import { withRouter } from 'react-router-dom';
import Contacts from 'Components/Users/Contacts';

function mapStatetoProps(state){
    return{ ...state.userContactsList  } 
}

const mapDispatchtoProps = {
    fetchUserContacts:userConatctsActions.fetchUserContacts
}

export default connect(mapStatetoProps,mapDispatchtoProps)(withRouter(Contacts))