import { userConstants } from 'Constants/user.constants';

const initialState = { loading: false };

export function authentication(state = initialState, action={}) {
   const { user, error } = action
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return { loading: true, errorElement:null, message: null };
    case userConstants.LOGIN_SUCCESS:
      return { loading: false, ...user };
    case userConstants.LOGIN_FAILURE:
      return { loading: false, ...error.validation };
    default:
      return state;
  }
}
