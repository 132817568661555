import React, { Component, Fragment } from 'react';
import Search from 'Components/Common/SearchBar';
import DropDown from 'Components/Common/DropDown';
import DateTimeRangeComponent from 'Components/Common/DateTimeRangePicker';
import Image from 'Components/Common/Image';
import LoadingOverlay from 'react-loading-overlay';
import { ReactComponent as Sort } from '../../assets/images/svg/sortarrows.svg';

const customComponents = {
  Search,
  DropDown,
  DateTimeRangeComponent
};

function dynamicClass(className, options) {
  const Component = customComponents[ className ];
  if (!Component) return null;
  return <Component { ...options } />;
}

export default class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortby: null,
      descending: null,
      search: false
    };
    this.noData = props.noData || 'No Records found.';
    this.pagination = props.pagination;
  }

  renderTableHeader = () => {
    const { headers } = this.props;
    const headerView = headers.map((header, index) => {
      const title = header.title;
      const cleanTitle = header.accessor;
      const width = header.width;
      const { sortOption } = header;
      if (!sortOption) {
        return (
          <th
            key={ cleanTitle }
            ref={ th => {
              this[ cleanTitle ] = th;
            } }
            style={ { width: width } }
            data-col={ cleanTitle }
          >
            <span name={ title } data-col={ cleanTitle }>
              {title}
            </span>
          </th>
        );
      }
      const { Component, ...rest } = sortOption;
      const column = sortOption.column || cleanTitle
      return (
        <th
          key={ column }
          ref={ th => {
            this[ column ] = th;
          } }
          style={ { width: width } }
          data-col={ column }
          data-sort={ `${ column }Sort` }
        >
          <span name={ title } data-col={ column }>
            {title}
            <i className="sort"><Sort onClick={ this.onSort } /></i>
            {dynamicClass(Component, rest)}
          </span>
        </th>
      );
    });
    return headerView;
  };

  renderTableoptions = () => {
    const { headers } = this.props;
    return headers.map((key, index) => {
      const { chooseOption } = key;
      if (!chooseOption) return <td key={ index }></td>;
      const { Component, ...rest } = chooseOption;
      return <td key={ index }>{dynamicClass(Component, rest)}</td>;
    });
  };

  renderContent = () => {
    const { headers, data,profile } = this.props;
    return data.map((row, rowIdx) => {
      const id = row[ 'id' ];
      const tds = headers.map((header, index) => {
        const accessors = header.accessor;
        let content = row[ accessors ];
        let customcontent = '';
        const cell = header.cell;
        if (cell) {
          if (typeof cell === 'object') {
            if (cell.type === 'image' && content) {
              content = <Image style={ cell.style } imageToken={ content } />;
            }
            if (cell.type === 'image-with-name') {
              const splitData = header.combineapidata;
              content = (
                <div className='user'>
                  <Image imageToken={ row[ splitData[ 1 ] ] } />{' '}
                   {row[ splitData[ 0 ] ]} 
                </div>
              );
            }
          } else if (typeof cell === 'function') {
            customcontent = cell({ index, id, rowIdx, content, ...row, profile });
          }
        }
        return typeof cell === 'function' ? (
          customcontent
        ) : (
          <td key={ index } data-row={ rowIdx }>
            {content}
          </td>
        );
      });
      return <tr key={ rowIdx }>{tds}</tr>;
    });
  };

  /**
   * @method
   * @summary Callback method of onclick on the all sorting icon from the Datatable.
   * @return {Array}
   */
  onSort = (e) => {
    const { sort } = e.target.closest('th').dataset;
    if(!sort) return;
    this.props.sortTable(sort);
  }

  renderNoData = () => {
    return <div className='nodate'>No Data!</div>;
  };

  renderTable = () => {
    const headerView = this.renderTableHeader();
    const headerOptions = this.renderTableoptions();
    const contentView =
      this.props.data && this.props.data.length > 0
        ? this.renderContent()
        : null;

    return (
      <form id='searchForm' onSubmit={ event => event.preventDefault() }>
        <table>
          <thead>
            <tr>{headerView}</tr>
            <tr className='serach'>{headerOptions}</tr>
          </thead>
          <tbody>{contentView}</tbody>
        </table>
        {this.props.data.length === 0 && this.renderNoData()}
      </form>
    );
  };
  render() {
    const { loading } = this.props;
    return (
      <Fragment>
        <div className='loading-parent'>
          <LoadingOverlay
            active={ loading }
            spinner
            styles={ {
              wrapper: {
                width: '100%',
                textAlign: '-webkit-center'
              }
            } }
            text='Please wait...'
          ></LoadingOverlay>

          {this.renderTable()}
        </div>
      </Fragment>
    );
  }
}
