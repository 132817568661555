import { feedbackConstants } from 'Constants/feedback.constants';

const initialState = {
    responseArray: [],
    usersList:[],
    loading: false,
    totalRecords: 0,
    error:false,
    currentPage:1,
    usersTotalRecords:'',
    usersCurrentPage:'',
    formLoading:false,
    errorElement:'',
    editErrorElement:'',
    exportFeedbackData: [],
    exportTotalRecords: 0,
    exportLoding: false,
}

export function feedback(state = initialState, action = {}) {
    const { feedback } = action;

    switch (action.type) {
        case feedbackConstants.FEEDBACK_REQUEST:
            return { ...state, loading: true, error: null };
        case feedbackConstants.FEEDBACK_GET_SUCCESS:
            return {
                ...state,
                responseArray: ( feedback ) ? [ ...feedback ] : [],
                totalRecords: action.totalRecords,
                currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
                loading: false,
                formLoading:false
            }
        case feedbackConstants.DELETE_TEAM_SUCCESS:
            return {
                ...state,
                responseArray: state.responseArray.filter(( teamObj ) => {
                    return action.updateObject.ids.indexOf(teamObj.id)
                }),
                totalRecords: state.totalRecords - action.updateObject.updateRecords,
                loading: false,
                error:false
            }
        case feedbackConstants.TEAM_FAILURE:
            return {
                ...state,
                loading: false,   
                error:true
            }
        default:
            return state;
    }
}

export function exportFeedbackData(state = initialState, action = {}) {
    const { exportData, totalRecords, error } = action;
    switch (action.type) {
        case feedbackConstants.EXPORT_FEEDBACK_REQUEST:
            return { ...state, exportLoding: true, error: null }
        case feedbackConstants.EXPORT_FEEDBACK_SUCCESS:
            return { ...state, exportLoding: false, exportFeedbackData: exportData, exportTotalRecords: totalRecords }
        case feedbackConstants.EXPORT_FEEDBACK_FAILURE:
            return { ...state, exportLoding: false, error: error }
        default:
            return state;
    }
}
