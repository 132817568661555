let pagemoved = false
let movePage = false

export default function formUrlParams(filteredstate, accessor, value) {
    const filtered = filteredstate;
    let insertNewFilter = 1;

    if (filtered.length) {
        filtered.forEach((filter, i) => {
            if (filter[ 'id' ] === accessor ) {
                if (value === '') filtered.splice(i, 1);
                
                else filter[ 'value' ] = value;
                insertNewFilter = 0;
            } 
            else if(filter[ 'id' ] === 'page' && accessor !== 'page') {
                const index = filtered.findIndex(page=>page[ 'id' ] === 'page');
                if (index > -1) filtered.splice(index, 1);
            }
        });
    }

    if (insertNewFilter) {
        filtered.push({ id: accessor, value: value });
    }
    return filtered;
}

export function alterQueryRequest(SearchArray, remainningRecords,currentPage) {
    const current = SearchArray && SearchArray.find(param => param.id=== 'page')
    if(current){
    if(current.value == 1){
     pagemoved = false
     return SearchArray;
    }
    if(SearchArray && remainningRecords > 1 && current.value == currentPage){
        pagemoved = false
        movePage = false
        return SearchArray;
    }
    }
    if(!SearchArray || remainningRecords > 1) {
        if(pagemoved === true && movePage === false){
            if(remainningRecords == 2){
                movePage = true
        return SearchArray.map(param=>{
             if(param.id === 'page') {
                 pagemoved = true
                return {
                  ...param,
                  value:currentPage
                }       
             }
             return param;
         })
        }else{
            return SearchArray.map(param=>{
                if(param.id === 'page') {
                    pagemoved = true
                    movePage = false
                   return {
                     ...param,
                     value:currentPage
                   }       
                }
                return param;
            }) 
        }
        }else{
            pagemoved = false
            movePage = false
            return SearchArray;
    }}
    if(movePage === true && remainningRecords == 1){
        movePage = false
        pagemoved = true
        return SearchArray.map(param=>{
            if(param.id === 'page') {
                pagemoved = true
                return {
                ...param,
                value:currentPage-1
                }       
            }
            return param;
        })
        }
   return SearchArray.map(param=>{
        if(param.id=== 'page') {
            pagemoved = true
            movePage = false
           return {
             ...param,
             value:param.value-1
           }       
        }
        return param;
    })
 }

export function queryParser(url,SearchArray) {
    const queryString = SearchArray.map((options) => options[ 'id' ] + '=' + escape(options[ 'value' ])).join('&');
     return  (queryString) ? url.concat('?',queryString) : url;
}

export function findCurrentPageRequest(SearchArray) {
   if(!SearchArray || SearchArray.length === 0 ) {
       return 1;
   }
   const currentPage = SearchArray.find( ({ id }) => id === 'page' );
   return (currentPage) ? currentPage.value : 1;
 }
