import React, { Component, Fragment } from 'react';
import { validator } from 'Helpers/validator';

export default class DropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.selectedOption
        }
        this.validator = validator.getInstance();
    }

    optionRender = () => {
        if (!this.props.optionRender) return null;
        return this.props.optionRender();
    }

    handleInputChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        })
        this.props.dispatchFunction(event);
    }

    render() {
        const { requiredfield } = this.props;
        const { selectedOption } = this.state;
        return (
            <Fragment>
                <select
                    value={ selectedOption || '' }
                    onChange={ this.handleInputChange }
                    data-validation={ (requiredfield) ? [ 'requiredfield' ] : undefined }
                    name={ this.props.name }
                >
                    <option value="">{ this.props.DropdownText }</option>
                    {this.optionRender()}
                </select>
            </Fragment>
        )
    }
}