import React, { Component } from 'react';
import { validator } from 'Helpers/validator';
import Loginbg from './images/login-wallpaper.svg';
import { Link } from 'react-router-dom';
import loginlogo from 'assets/images/logo.png';
import labeltop from 'Helpers/labelTop';
import LoadingOverlay from 'react-loading-overlay';
import { ReactComponent as Triangle } from 'assets/images/svg/triangle.svg';
import { ReactComponent as Square } from 'assets/images/svg/square.svg';
import { ReactComponent as Bubble } from 'assets/images/svg/bubble.svg';

/**
 * @file
 * User can retreive their password through email
 * @module 3.ForgotPassword
 * @extends React.Component
 */

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailId: '',
      errorElement: '',
      popup:false
    };
    this.validator = validator.getInstance();
  }
  componentDidUpdate(prevProps) {
    if (this.props.errorElement !== prevProps.errorElement) {
      this.setState({ errorElement: this.props.errorElement });
    }
    if (this.props.popup !== prevProps.popup) {
      this.setState({ popup: this.props.popup });
    }
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */

  validation = e => {
    const superparent = e.target.parentElement;
    !e.target.value && superparent.classList.remove('top');
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    const elementObject = {
      formId: 'forgotpassword',
      elementName: e.target.name,
      elementValue: e.target.value,
      message: e.target.dataset.error
    };
    this.validator.validate(elementObject, inputRules);
  };

  /**
   * @method handleEmailIdChange
   * @summary set the emailId state
   * @param {event} event - React onChange event
   * @return {none} undefined
   */

  handleEmailIdChange = event => {
    this.setState({ emailId: event.target.value });
  };

  /**
   * @method handleValidSubmit
   * @summary validate the emailId based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */

  handleValidSubmit = event => {
    event.preventDefault();
    const formId = event.target.id;
    this.setState({ errorElement: null }, () => {
      if (formId && !this.validator.checkBeforeSubmit(formId)) {
        this.props.forgotPassword(this.state.emailId);
      }
    });
  };

  render() {
    const { errorElement,popup } = this.state;
    const { loading } = this.props;
    return (
      <div className='main-logindiv'>
        
        <LoadingOverlay
          active={ loading }
          spinner
          styles={ {
            wrapper: {
              width: '100%',
              textAlign: '-webkit-center'
            }
          } }
          text='Please wait...'
        >
          <div className='icons'>
            <div className='right-side'>
              <Triangle className='triangle' />
              <Square className='square' />
            </div>
            <div className='left-side'>
              <Triangle className='triangle' />
              <Square className='square' />
            </div>
          </div>
          <div className='login'>
            <div className='left-section'>
              <img src={ Loginbg } name='bg-image' />
            </div>
            <div className='right-section'>
              <Bubble className='bubble' />

              <section>
                <div className='login-logo'>
                  <img src={ loginlogo } name='logo' />
                </div>
                <div className='main-login forgotpassword'>
                  <form
                    id='forgotpassword'
                    noValidate={ true }
                    onSubmit={ this.handleValidSubmit }
                  >
                    <h2>Forgot password</h2>
                    <div className='form-group'>
                      <label>Email</label>
                      <input
                        id='userEmailId'
                        name='emailId'
                        onBlur={ this.validation }
                        data-validation={ [ 'email' ] }
                        onChange={ this.handleEmailIdChange }
                        onFocus={ labeltop }
                        type='email'
                        value={ this.state.emailId }
                      />
                       {errorElement !== '' && (
                      <span className='errorElement'>{errorElement}</span>
                    )}
                    </div>
                   
                    <button
                      type='submit'
                      className='default-bbt'
                      disabled={ loading }
                    >
                      <span>Submit</span>
                    </button>
                    <Link to='/login' className='back'>
                      Back to login
                    </Link>
                  </form>
                  { popup &&
                    <div className='seccess-popup'>
                    <div className='content-wraper'>
                      <p>
                        A reset password link has been sent to your email, click the link
                        to reset password
                      </p>
                      <div className='action'>
                        <Link to='/login'>ok</Link>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </section>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
