import React, { Component, Fragment } from 'react';
import DropDown from 'Components/Common/DropDown';
import { validator } from 'Helpers/validator';
import validation from 'Helpers/Validation';
import LoadingOverlay from 'react-loading-overlay';

class AddManageAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      isActive: 1,
      name: '',
      roleName: '',
      userName: '',
      fileName: '',
      errorElement:''
    };
    this.validator = validator.getInstance();
  }

   /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */
  
  validation = e => {
    validation(e, 'add-admin-form');
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [ name ]: value });
  };
  handlenameChange = event => {
    const { name, value } = event.target;
    this.setState({ [ name ]: value.toLowerCase() });
  }

  handleAceesLevel = event => {
    var dataset = event.target.options[ event.target.selectedIndex ].dataset;
    const { name } = event.target;
    this.setState({ [ name ]: dataset[ 'rolename' ] });
  };

  handleCheckBoxChange = () => {
    this.setState({ isActive: !this.state.isActive & 1 });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { id } = event.target;
    const { emailId, isActive, name, roleName, userName } = this.state;

    if (id && !this.validator.checkBeforeSubmit(id)) {
      this.props.addAdminUser({
        emailId,
        isActive,
        name,
        roleName,
        userName
      },this.props.filterVal);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.totalRecords !== this.props.totalRecords) {
          
      if (this.props.error !== true && this.props.errorElement === '') {
        document.getElementById('searchForm') &&
          document.getElementById('searchForm').reset();
        this.closeAddaddroles();
      }
    }
    if (prevProps.errorElement !== this.props.errorElement) {
      this.setState({ errorElement:this.props.errorElement })
    }
  }

  closeAddaddroles = () => {
    this.props.closeAddAdmin();
  };

  render() {
    const { name, userName, emailId, isActive,errorElement } = this.state;
    const { formLoading } = this.props

    return (
      <Fragment>
        <div className='addadmin' id='addadmin'>
          <div className='overlay' onClick={ this.closeAddaddroles }></div>
          <div className='addmgnt'>
          <LoadingOverlay
            active={ formLoading }
            spinner
            styles={ {
              wrapper: {
                width: '100%',
                textAlign: '-webkit-center'
              }
            } }
            text='Please wait...'
          ></LoadingOverlay>
            <div className='head'>
              <h2>Add Admin</h2>
              <div className='tooglebbt'>
                <div className='toogle' name='status'>
                  <span>(Inactive)</span>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={ !!+isActive }
                      onChange={ this.handleCheckBoxChange }
                    />
                    <span className='slider round'></span>
                  </label>
                  <span>(active)</span>
                </div>
              </div>
            </div>
            <form id='add-admin-form' autoComplete='off' onSubmit={ this.handleSubmit }>
              <div className='formgroup'>
                <label>
                  Name<sup>*</sup>
                </label>
                <input
                  type='text'
                  name='name'
                  value={ name }
                  maxLength='25'
                  data-validation={ [ 'user' ] }
                  placeholder='Enter name here!'
                  data-error='Enter your name'
                  onBlur={ this.validation }
                  onChange={ this.handleChange }
                />
              </div>
              <div className='formgroup'>
                <label>Username</label>
                <input
                  type='text'
                  name='userName'
                  onBlur={ this.validation }
                  data-validation={ [ 'username' ] }
                  data-error='Username can contain -,_,\,.'
                  value={ userName }
                  placeholder='Enter user name here!'
                  onChange={ this.handlenameChange }
                  maxLength='16'
                />
              </div>
              <div className='formgroup'>
                <label>
                  Email<sup>*</sup>
                </label>
                <input
                  type='text'
                  name='emailId'
                  value={ emailId }
                  onBlur={ this.validation }
                  data-validation={ [ 'email' ] }
                  onChange={ this.handleChange }
                  placeholder='Enter email address here!'
                />
              </div>
              <div className='formgroup'>
                <label>
                  Access Level<sup>*</sup>
                </label>
                <DropDown
                  name='roleName'
                  requiredfield='true'
                  DropdownText='Select'
                  dispatchFunction={ this.handleAceesLevel }
                  optionRender={ this.props.optionRender }
                />
              </div>
              { errorElement  && 
                    <span className='errorElement'>{ errorElement }</span> }
              <div className='buttons'>
                <div>
                  <button
                    type='reset'
                    onClick={ this.closeAddaddroles }
                    className='plain-bbt'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='blue-bbt'>
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddManageAdmin;
