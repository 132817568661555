import React, { Component } from 'react';

export default class Uploader extends Component {
    constructor(props){
        super(props)
            this.state={
                error:'',
                popup:false
            }
    }

    validateBeforeUpload = ( file ) => {
        const iFileSize = file.size;
        const fileName = file.name;   
      
       if(!( /\.(jpe?g|png)$/i.test(fileName))) {
            return {
                messsage:'Invalid format. Please upload file of any below format JPG/JPEG or PNG.',
                error:true
            }
        } else if(iFileSize >= 1024 * 1024 * 2) { 
            return {
                messsage:'File size is too large, please upload a file less than 2 MB.',
                error:true
            }
        }
        return  {
            error:false
        };
    }

    fileChangedHandler = event => {
        const file=  event.target.files[ 0 ];
        const isValid = this.validateBeforeUpload(file); 
        if(isValid.error){
            this.setState({ error:isValid.error,popup:true })   
            event.preventDefault();
            this.props.imageError(isValid.error,isValid.messsage)
            return;
        } else {        
            const { type } =  this.props
            this.setState({ error:'',popup:false })
            this.props.updateProfileImage(`type=${ type }`,file );  
        } 
    }
    
    handlePopup = event => {
        event.target.value = '';
        this.props.closePopup && this.props.closePopup(event)
    }
    
    render() {
        const { id, dataValidation, dataError } = this.props
        return (
            <input 
                id={ id } 
                type="file" 
                name = { id }
                data-validation={ dataValidation }
                data-error = { dataError }
                onChange={ this.fileChangedHandler } 
                onClick={ this.handlePopup } 
            />
        )
    }
}