import { profileConstants,userConstants } from 'Constants';

const initialState = {
  id: null,
  name: '',
  email: '',
  username: '',
  mobileNumber: '',
  image:'',
  imageUrl:'',
  loading:false
}
export function profile(state = initialState, action={}) {
  const { profile } = action;
  switch (action.type) {
    case profileConstants.PROFILE_REQUEST:
      return { ...state, loading: true };
    case profileConstants.PROFILE_GET_SUCCESS:
    case profileConstants.PROFILE_UPDATE_SUCCESS:
    case profileConstants.PROFILE_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        ...profile,
        loading: false
      };
    case profileConstants.PROFILE_FAILURE:
      return { ...state, loading: false };
      case userConstants.LOGOUT_SUCCESS:
        return  initialState ;
    default:
      return state;
  }
}
