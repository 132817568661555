import React from 'react';
import { connect } from 'react-redux';
import { broadCastActions } from 'Actions';
import ManageBroadcast from 'Components/Broadcast/Manage';

function mapStatetoProps(state){
    const { profile } = state
    return {
        profile:profile.permission && JSON.parse(profile.permission),
        ...state.broadCast
    }
}

const mapDispatchtoProps = {
    fetchBroadCast:broadCastActions.fetchBroadCast,
    deleteBroadCast:broadCastActions.deleteBroadCast
}

export default connect(mapStatetoProps,mapDispatchtoProps)(ManageBroadcast);