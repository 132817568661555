import { userConstants } from 'Constants/user.constants';

const initialState = { loading: false };

export function userlogout(state = initialState, action={}) {
  switch (action.type) {
    case userConstants.LOGOUT_REQUEST:
      return { loading: true,error:null };
    case userConstants.LOGOUT_SUCCESS:
      return { loading: false, error:false };
    case userConstants.LOGOUT_FAILURE:
      return { loading: false, error:true };
    default:
      return state;
  }
}
