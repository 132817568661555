import React, { Component, Fragment } from 'react';
import Contacts from 'Container/Users/contactsContainer';
import Groups from 'Container/Groups/GroupsContainer';
import Image from 'Components/Common/Image';
import {  formatDateTime } from 'Components/Common/TimeStamp';
import toastr from 'toastr';

export default class UserDetails extends Component{
    constructor(props){
      super(props)
      this.state={
          toShow:'Contacts',
          userDetails:[],
          filtered:[]
      }
    }

    componentDidMount(){
      const { usersList, match: { params: { id: userName } } } = this.props;
      this.setState({ userName: userName })
      let isExists = false;
      usersList.some((e)=>{
        if(e.username === userName) {
          isExists = true; 
          this.setState({ userDetails:e })
        }
      })
      if(!isExists) this.props.history.push(`/UserManagement`);
    }   

    componentDidUpdate(prevProps) {
      this.handleUsersList(prevProps);
    } 

    /**
     * handle usersContactlist and finds the current user to show on top.
     */
    handleUsersList = (prevProps) => {
      const { userContactsList, match: { params: { id: userName } } } = this.props;
      if(prevProps.match.params.id !== userName && this.state.userDetails.username !== userName) {
        this.setState({ userName: userName })
        return userContactsList.some((e)=>{
          e.username === userName && this.setState({ userDetails:e })
        })
      }
    }

    handleClick = (event) => {
      this.setState({
        toShow: event.target.name
      })
    }

    handleUserDetails = (data) => {
      this.props.userContactsList.some((e)=>{
        e.username === data && this.setState({ userDetails:e })
      })
    }

    render(){
      const { userDetails } = this.state
        return(
            <Fragment>
            <div className='groupinfo'>
              <div className='info'>
                <div className='left'>
                  <div className='groupimg' name="groupImage">
                    <Image imageToken = { userDetails.image } />
                  </div>
                  <div className='group'>
                    <div className='formgroup'>
                      <label>Name:</label>
                      <span>{ userDetails.name }</span>
                    </div>
                    <div className='formgroup'>
                      <label>Email:</label>
                      <span>{ userDetails.emailId }</span>
                    </div>
                  </div>
                </div>
                <div className='right'>
                  <div className='group'>
                    <div className='formgroup'>
                      <label>Mobile:</label>
                      <span>{ userDetails.mobileNumber }</span>
                    </div>
                    <div className='formgroup'>
                      <label>Platform:</label>
                      <span>{ userDetails.deviceType }</span>
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={ this.handleClick } name='Contacts'>contacts</button>
              <button onClick={ this.handleClick } name='Groups'>group</button>
              { this.state.toShow === 'Contacts' && <Contacts dispatchfunction={ this.handleUserDetails }/> }
              { this.state.toShow === 'Groups' && <Groups/> }
            </div>
            </Fragment>
        )
    }
} 