import { manageAdminConstant } from 'Constants/manageAdmin.constants';
import Request from 'Services/request';
import { manageAdminRolesActions } from './manageAdminRolesactions'
import { alterQueryRequest,  findCurrentPageRequest, queryParser } from 'Helpers/urlform'
import toastr from 'toastr';

const requestAdminUser = manageAdmin =>({ 
    type: manageAdminConstant.ADMIN_USER_REQUEST, 
    manageAdmin 
})
const requestAdminUserForm = manageAdmin =>({ 
  type: manageAdminConstant.ADMIN_USER_FORM_REQUEST, 
  manageAdmin 
})

const successAdminUser = adminDetails =>({
    type: manageAdminConstant.ADMIN_USER_GET_SUCCESS,
    manageAdmin:adminDetails.data,
    totalRecords:adminDetails.count,
    currentPage:adminDetails.currentPage
})

const successUpdateAdminUser = adminDetails =>({
  type: manageAdminConstant.UPDATE_ADMIN_USER_SUCCESS,
  manageAdmin:adminDetails
})

const failureAddminUserForm = error =>({
  type: manageAdminConstant.ADMIN_USER_FORM_FAILURE,
  error
})
const failureEditAddminUserForm = error =>({
  type: manageAdminConstant.ADMIN_USER_EDIT_FORM_FAILURE,
  error
})

const failureAddminUser = error =>({
    type: manageAdminConstant.ADMIN_USER_FAILURE,
    error
})

function fetchAdminUser(SearchArray) {
  let url = '/adminuser';
  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  }  
  const currentPage = findCurrentPageRequest(SearchArray);
  return async (dispatch) => {
    dispatch(requestAdminUser({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data,count } = response;
        dispatch(successAdminUser({ data, count, currentPage }));
        dispatch(manageAdminRolesActions.fetchAlladminRoles());
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureAddminUser(error.toString()));
      });
  };
}

function addAdminUser(addAdminUserObj,SearchArray) {
    return (dispatch) => {
      dispatch(requestAdminUserForm({}));
      Request.create('/adminuser',{ ...addAdminUserObj },
        { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const {  message } = response;   
        dispatch(fetchAdminUser(SearchArray));
        toastr.remove();
        toastr.success(message);
      })
      .catch((error) => {
         dispatch(failureAddminUserForm(error.toString()));
      });
    };
  }

  function updateAdminUser(adminUserObj) {
    const { id, ...rest } = adminUserObj
    return (dispatch) => {
      dispatch(requestAdminUserForm({}));
      Request.update(`/adminuser/${ id }`,
        { ...rest },
        { 'Authorization': `${ localStorage.getItem('user') }` })
        .then((response) => {
          const { message } = response;        
          dispatch(successUpdateAdminUser(adminUserObj));
          toastr.remove();
          toastr.success(message);
        })
        .catch((error) => {
          dispatch(failureEditAddminUserForm(error.toString()));
        });
    };
  }

  function deleteAdminUser(id, SearchArray, remainningRecords,currentPage){
    const updateSearchArray = alterQueryRequest(SearchArray, remainningRecords,currentPage);
      return (dispatch) => {
          dispatch(requestAdminUser({}));
          Request.remove('/adminuser',
          { id: id  },
          { 'Authorization': `${ localStorage.getItem('user') }` })
          .then(() => {
            return dispatch(fetchAdminUser(updateSearchArray));
          }).then(()=>{
            toastr.remove();
             toastr.success('Admin User deleted Successfully')
          })
          .catch((error) => {
            toastr.remove();
            toastr.error(error.toString());
            dispatch(failureAddminUser(error.toString()));
          });
      }
  }
  
  export const manageAdminActions = {
    fetchAdminUser,
    addAdminUser,
    updateAdminUser,
    deleteAdminUser
  };