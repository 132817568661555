import React from 'react'
import { connect } from 'react-redux';
import { usersListActions } from 'Actions';
import Users from 'Components/Users';

function mapStatetoProps(state){
    const { usersList, totalRecords, currentPage, loading } = state.usersList
    return{
        usersList:usersList,
        totalRecords:totalRecords,
        currentPage:currentPage,
        loading
    } 
}
    const mapDispatchtoProps = {
        fetchUsersList:usersListActions.fetchUsersList
    }

export default connect(mapStatetoProps,mapDispatchtoProps)(Users)