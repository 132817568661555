import React from 'react'
import { connect } from 'react-redux';
import { FeedbackAction } from 'Actions/feedbackAction';
import FeedBack from 'Components/Feedback';

function mapStatetoProps(state){
    const { responseArray, totalRecords, currentPage, loading } = state.feedback;
    const { exportFeedbackData } = state.exportFeedbackData;
    //const { profile } = state;
    return{
        //profile: profile.permission && JSON.parse(profile.permission),
        feedback: responseArray,
        totalRecords,
        currentPage: currentPage,
        loading: loading,
        exportFeedbackData,
    }
}

const mapDispatchtoProps = {
    fetchFeedback: FeedbackAction.fetchFeedback,
    deleteFeedback: FeedbackAction.deleteFeedback,
    exportFeedback: FeedbackAction.exportFeedback,
}

export default connect(mapStatetoProps,mapDispatchtoProps)(FeedBack)