import { callManagementConstants } from 'Constants/callManagement.constants';
import Request from 'Services/request';
import { alterQueryRequest, findCurrentPageRequest, queryParser } from 'Helpers/urlform'
import toastr from 'toastr';
import { request } from 'http';
import {  formatDateTime } from 'Components/Common/TimeStamp';

const requestCallManagement = callManagement => ({
    type: callManagementConstants.CALL_MANAGEMENT_REQUEST,
    callManagement
})

const successCallManagement = callManagementObj => ({
    type: callManagementConstants.CALL_MANAGEMENT_GET_SUCCESS,
    callManagement: callManagementObj.data,
    totalRecords: callManagementObj.count,
    currentPage: callManagementObj.currentPage
})

const failureCallManagement = error => ({
    type: callManagementConstants.CALL_MANAGEMENT_FAILURE,
    error
})

function fetchCallManagement(SearchArray) {
    let url = '/calls';
    let isStatusSearch = false;
    if (SearchArray && SearchArray.length > 0) {
        url = queryParser(url, SearchArray);
        SearchArray.forEach(function(res, index){ 
            if(res.id === 'statusSearch') {
                isStatusSearch = true;
            }
        });
    }
    const currentPage = findCurrentPageRequest(SearchArray);
    return async (dispatch) => {
        dispatch(requestCallManagement({}));
        await Request.get(url, { }, { 'Authorization': `${ localStorage.getItem('user') }` })
            .then((response) => {
                const { data, count } = response;
                if(!isStatusSearch) {
                    data && data.forEach(function(res){
                        res.status = res.status === 0 ? 1 : res.status;
                    })
                }
                dispatch(successCallManagement({ data, count, currentPage }));
            })
            .catch((error) => {
                toastr.error(error.toString());
                dispatch(failureCallManagement(error.toString()));
            });
    };
}

const requestCallManagementExportData = exportData => ({
  type: callManagementConstants.EXPORT_CALL_MANAGEMENT_REQUEST, 
  exportData
})

const successCallManagementExportData = exportDataObj => ({
  type: callManagementConstants.EXPORT_CALL_MANAGEMENT_SUCCESS,
  exportData: exportDataObj.data,
  totalRecords: exportDataObj.count
})

const failureCallManagementExportData = error => ({
  type: callManagementConstants.EXPORT_CALL_MANAGEMENT_FAILURE,
  error
})

function exportCallManagement(SearchArray) {
  let url = '/calls';
  let isStatusSearch = false;
  if (SearchArray && SearchArray.length > 0) {
    url = queryParser(url, SearchArray);
    SearchArray.forEach(function(res, index){ 
        if(res.id === 'statusSearch') {
            isStatusSearch = true;
        }
    });
  }
  const exportUrl = SearchArray && SearchArray.length > 0 ? `&exportType=true` : `?exportType=true`;
  url = `${ url }${ exportUrl }`;
  return async (dispatch) => {
    dispatch(requestCallManagementExportData({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
        .then(response => {
            const { data, count } = response;
            data && data.forEach(function(res){ 
                res.status = (!isStatusSearch && res.status === 0) ? 1 : res.status;
                res[ 'Call From' ] = res.callFrom; 
                res[ 'Call To' ] = res.callTo; 
                res[ 'Date & Time' ] = res.callDateAndTime && formatDateTime(res.callDateAndTime);
                res[ 'Call Type' ] = res.callType; 
                res[ 'Duration' ] = res.duration; 
                res[ 'Status' ] = res.status === 0 ? 'Incoming' : res.status === 1 ? 'Outgoing' : res.status === 2 ? 'Missed' : ''; 
                delete res.callFrom; delete res.callTo; delete res.callDateAndTime;
                delete res.callType; delete res.duration; delete res.status;
                delete res.fromUserImage; delete res.toUserImage;
            });
            dispatch(successCallManagementExportData({ data, count }));
        })
        .catch((error) => {
            toastr.remove();
            toastr.error(error.toString());
            dispatch(failureCallManagementExportData(error.toString()));
        });
  };
}

export const CallManagementAction = {
    fetchCallManagement,
    exportCallManagement,
}
