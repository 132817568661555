import React, { Component } from 'react';
import CheckboxGroup from '../CheckboxGroup'
import { validator } from 'Helpers/validator';
import validation from 'Helpers/Validation';
import LoadingOverlay from 'react-loading-overlay';

class AddManageAdminRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module:'',
      roleName: '',
      errorElement:''
    }
    this.validator = validator.getInstance();
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */
  componentDidMount(){
    this.setState({ module:this.props.allowedModules })
  }
  validation = e => {
    validation(e, 'addroleform');
  };

  closeaddroles = () => {
      this.props.closeAddRoles()
  };

  onCheckboxGroupChange = (checkboxGroupkey, checkboxes) => {
    this.setState(prevState => ({
      ...prevState,
      module: {
        ...prevState.module,
        [ checkboxGroupkey ]: checkboxes
      }
    }))
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  }
  
  createcheckBoxGroup = () => {
    var checkboxGroupArray = [];
    for (const property in this.state.module) {
          checkboxGroupArray.push(<CheckboxGroup
                checkboxes={ this.state.module[ property ] }
                checkboxGroupkey={ property } 
                parentClass = 'addroles'
                key={ property }
                onCheckboxGroupChange={ this.onCheckboxGroupChange }
          />)
    }
    return checkboxGroupArray
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if( event.target.id && !this.validator.checkBeforeSubmit(event.target.id) ) {
      const data = { ...this.state, isDeletable:1 }
      this.props.addAdminRoles(data,this.props.filterVal);   
     
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
      if(prevProps.totalRecords !== this.props.totalRecords) {
           if( this.props.error !== true && this.props.errorElement === '' ) {        
             document.getElementById('searchForm') && 
             document.getElementById('searchForm').reset();
             this.closeaddroles();
          } 
      }  
      if (prevProps.errorElement !== this.props.errorElement) {
        this.setState({ errorElement:this.props.errorElement })
      }   
    }

  render() {
    const { roleName, errorElement } = this.state;
    const { formLoading } = this.props
    return (
      <React.Fragment>
        <div className='addadmin' id='addroles'>
          <div className='overlay' onClick={ this.props.closeaddroles }></div>
          <div className='addadminroles'>
          <LoadingOverlay
            active={ formLoading }
            spinner
            styles={ {
              wrapper: {
                width: '100%',
                textAlign: '-webkit-center'
              }
            } }
            text='Please wait...'
          ></LoadingOverlay>
            <div className='head'>
              <h2>Add Roles</h2>
            </div>
            <form id='addroleform' autoComplete='off' onSubmit={ this.handleSubmit }>
              <div className='formgroup'>
                <label>
                  Role<sup>*</sup>
                </label>
                <input id="roleName"
                  onChange={ this.handleChange }
                  value={ roleName }
                  type="text"
                  name="roleName"
                  onBlur={ this.validation }
                  data-validation={ [ 'requiredfield' ] }
                  data-error= 'Enter valid role name'
                  placeholder="Enter role here!" />
              </div>
              <div className='allowedmodules'>
                <h3>Allowed Modules</h3>
                <div className='allroles'>
                     {this.createcheckBoxGroup()}
                </div>
              </div>
              { errorElement !== '' && 
                    <span className='errorElement'>{ errorElement }</span> }
              <div className='buttons'>
                <div>
                  <button type='reset' onClick={ this.closeaddroles } className='plain-bbt'>Cancel</button>
                  <button type='submit' className='blue-bbt'>Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddManageAdminRoles;
