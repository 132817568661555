export const settingsConstants = {
 
  SETTINGS_REQUEST: 'SETTINGS_REQUEST',
  SETTINGS_GET_SUCCESS: 'SETTINGS_GET_SUCCESS',
  SETTINGS_UPDATE_SUCCESS: 'SETTINGS_UPDATE_SUCCESS',
  SETTINGS_FAILURE: 'SETTINGS_FAILURE',
  DEFAULT_AUDIO_LIMIT:2,
  DEFAULT_VIDIO_LIMIT:5,
  DEFAULT_AUDIO_TYPE:'min',
  DEFAULT_VIDIO_TYPE:'min',
};
