import React from 'react';
import Upload from 'Components/Common/Uploader';
import { Link } from 'react-router-dom';
import { ReactComponent as Cam } from 'assets/images/svg/camera.svg';
import { ReactComponent as View } from 'assets/images/svg/view.svg';
import { ReactComponent as Cameraicon } from 'assets/images/svg/cameraicon.svg';
import { ReactComponent as Photo } from 'assets/images/svg/photo.svg';
import { ReactComponent as Uploadimg } from 'assets/images/svg/upload.svg';
import { ReactComponent as Trash } from 'assets/images/svg/trash.svg';
import { ReactComponent as Loader } from 'assets/images/svg/loader.svg';
import { validator } from 'Helpers/validator';
import uploadImage from 'Helpers/uploadImage';
import toastr from 'toastr';
import Image from 'Components/Common/Image';
import LoadingOverlay from 'react-loading-overlay';
import Cropme from 'croppie';
import 'croppie/croppie.css';
import validation from 'Helpers/Validation';
import TakePhoto from 'Components/Common/Takephoto';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as Reload } from 'assets/images/svg/reload.svg';
import { ReactComponent as Done } from 'assets/images/svg/done.svg';
import { ReactComponent as Close } from 'assets/images/svg/Popclose.svg';
import Popup from 'Components/Common/PopUp'

/**
 * @file Profile Component
 * User can view and update their profile
 * @module 4.Profile
 * @extends React.Component
 */
var cropme;

const popUpOptions = {
  showZoomer: true,
  enableOrientation: true,
  viewport: { width: 150, height: 150, type: 'square' },
  boundary: { width: 300, height: 300 }
};

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    const { id, name, email, username, mobileNumber, image } = this.props;
    this.state = {
      id,
      name,
      email,
      username,
      image,
      filename: '',
      popupState: false,
      mobileNumber,
      loaderStatus: false,
      webCamStatus: false,
      photoTaken: false,
      cameraerror: false,
      deletepopup: false,
      CampopupState: false,
      inValidFormatMeaasge:'',
      inValidFormatpopup:''
    };
    this.validator = validator.getInstance();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      const { id, name, email, username, mobileNumber, image } = this.props;
      this.setState({
        id,
        name,
        email,
        username,
        image,
        mobileNumber,
        viewPhoto: false
      });
    }
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {e} event - React onBlur event
   * @return {none} undefined
   */

  validation = e => {
    validation(e, 'profileform');
  };

  cropImage = () => {
    this.setState({ loaderStatus: true });
    cropme
      .result({
        type: 'base64',
        size: 'original',
        quality: 0.9,
        format: 'jpeg'
      })
      .then(base64 => {
        const fileObject = this.dataURLtoFile(base64, this.state.filename);
        this.aftercropedImage(fileObject);
      });
  };

  closePopUp = event => {
    event.preventDefault();
    this.setState({
      popupState: false,
      webCamStatus: false,
      cameraerror: false,
      deletepopup: false,
      CampopupState: false
    });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[ 0 ].match(/:(.*?);/)[ 1 ];
    const u8arr = Uint8Array.from(atob(arr[ 1 ]), c => c.charCodeAt(0));
    return new File([ u8arr ], filename, { type: mime });
  };

  aftercropedImage = file => {
    uploadImage('type=profile', file)
      .then(({ data }) => {
        const { fileToken } = data;
        this.setState(
          {
            image: fileToken,
            popupState: false,
            CampopupState: false,
            loaderStatus: false
          },
          () => {
            this.props.updateProfileImage(this.state.image);
          }
        );
      })
      .catch(error => {
        toastr.error(error.toString());
      });
  };
  handleRefresh = () => {
    this.setState({ CampopupState:false,webCamStatus:true })
  }
  handleimageError = ( error,message ) => {
    this.setState({ inValidFormatpopup:error,inValidFormatMeaasge:message })
  }
  handleInValidPopup = () => {
    this.setState({ inValidFormatpopup:false })
  }
  updateProfileImage = (fileType, file) => {
    if (file) {
      this.setState(
        {
          filename: file.name,
          popupState: true,
          photoTaken: false,
          webCamStatus: false
        },
        () => {
          const element = document.getElementById('container');
          cropme = new Cropme(element, popUpOptions);
          var reader = new FileReader();
          reader.onload = function(e) {
            cropme
              .bind({
                url: e.target.result
              })
              .then(() => cropme.setZoom(0));
          };
          reader.readAsDataURL(file);
        }
      );
    }
  };
  updateCamProfileImage = (fileType, file) => {
    if (file) {
      this.setState(
        {
          filename: file.name,
          CampopupState: true,
          photoTaken: false,
          webCamStatus: false
        },
        () => {
          const element = document.getElementById('container');
          cropme = new Cropme(element, popUpOptions);
          var reader = new FileReader();
          reader.onload = function(e) {
            cropme
              .bind({
                url: e.target.result
              })
              .then(() => cropme.setZoom(0));
          };
          reader.readAsDataURL(file);
        }
      );
    }
  };

  handleOnchange = event => {
    event.persist();
    this.setState(
      {
        [ event.target.name ]: event.target.value
      },
      () => (!event.target.value ? this.labeltop(event) : null)
    );
  };

  handleValidSubmit = event => {
    event.preventDefault();
    if (event.target.id && !this.validator.checkBeforeSubmit(event.target.id)) {
      const { id, name, mobileNumber, image } = this.state;
      this.props.updateProfile({ id, name, mobileNumber, image });
    }
  };

  labeltop(e) {
    const superparent = e.target.parentElement;
    superparent.classList.add('top');
  }
  droplist = () => {
    document.getElementById('droplist').classList.toggle('open');
  };

  camStatus = (fileType, file) => {
    if (file.size > 0) {
      this.setState({ photoTaken: true, webCamStatus: false });
      this.updateCamProfileImage(fileType, file);
    }
  };
  removeProfilePicture = event => {
    event.preventDefault();
    this.setState({ image: '', deletepopup: false }, () => {
      this.props.updateProfileImage(this.state.image);
    });
  };
  openDeletepopup = event => {
    event.preventDefault();
    this.setState({ deletepopup: true });
  };
  closeDeletePopUp = () => {
    this.setState({ deletepopup: false });
  };

  handleWebcam = event => {
    this.setState({ webCamStatus: true });
  };
  handleDropDown = event => {
    document.getElementById('droplist').classList.toggle('open');
  };
  handleViewPhoto = event => {
    this.setState({ viewPhoto: !this.state.viewPhoto });
  };
  handleOutsideClick = event => {
    document.getElementById('droplist').classList.remove('open');
  };
  CameraCheck = () => {
    this.setState({ cameraerror: true, webCamStatus: false });
  };
  render() {
    const {
      name,
      email,
      username,
      mobileNumber,
      image,
      popupState,
      loaderStatus,
      webCamStatus,
      viewPhoto,
      cameraerror,
      deletepopup,
      CampopupState,
      inValidFormatMeaasge,
      inValidFormatpopup
    } = this.state;
    const { loading } = this.props;
    const { password } = JSON.parse(localStorage.getItem('userdetail')) || {};
    return (
      <React.Fragment>
        <div className='myprofile'>
          <h2>My Profile</h2>
          <div className='main-block loading-parent'>
            <LoadingOverlay
              active={ loading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center'
                }
              } }
              text='Please wait...'
            ></LoadingOverlay>
            <form id='profileform' onSubmit={ this.handleValidSubmit }>
              <div className='profileimg'>
                <div className='profile-wrapper'>
                  <div className='image'>
                    <Image
                      name='profileImage'
                      alt='Profile Image'
                      imageToken={ image }
                    />
                  </div>
                  <OutsideClickHandler onOutsideClick={ this.handleOutsideClick }>
                    <div className='label' onClick={ this.droplist }>
                      <i>
                        <Cam />

                        <div className='droplist' id='droplist'>
                          <ul>
                            {image && (
                              <li onClick={ this.handleViewPhoto }>
                                <i>
                                  <View />
                                </i>
                                View Photo
                              </li>
                            )}
                            <li onClick={ this.handleWebcam }>
                              <i>
                                <Photo />
                              </i>
                              Take Photo
                            </li>
                            <li className='upload-li'>
                              <label className='upload-label'>
                                <Upload
                                  { ...this.state }
                                  type='profile'
                                  updateProfileImage={ this.updateProfileImage }
                                  closePopup={ this.handleDropDown }
                                  imageError= { this.handleimageError }
                                />
                                <i>
                                  <Uploadimg />
                                </i>
                                Upload Photo
                              </label>
                            </li>
                            {image && (
                              <li onClick={ this.openDeletepopup }>
                                <i>
                                  <Trash />
                                </i>
                                Remove Photo
                              </li>
                            )}
                          </ul>
                        </div>
                      </i>
                    </div>
                  </OutsideClickHandler>
                </div>
              </div>
              <div className='group'>
                <div className={ name !== '' ? 'form-group top' : 'form-group' }>
                  <label>
                    Name<sup>*</sup>
                  </label>
                  <input
                    name='name'
                    onBlur={ this.validation }
                    onChange={ this.handleOnchange }
                    onFocus={ this.labeltop }
                    maxLength={ 30 }
                    data-validation={ [ 'user' ] }
                    data-error='Enter a name here'
                    type='text'
                    value={ name }
                  />
                </div>
                <div
                  className={
                    mobileNumber !== '' ? 'form-group top' : 'form-group'
                  }
                >
                  <label>Mobile number</label>
                  <input
                    name='mobileNumber'
                    onBlur={ this.validation }
                    data-validation={ [ 'mobilenumber' ] }
                    data-error='Enter mobile number here'
                    onFocus={ this.labeltop }
                    onChange={ this.handleOnchange }
                    type='text'
                    value={ mobileNumber }
                  />
                </div>
                <div
                  className={ username !== '' ? 'form-group top' : 'form-group' }
                >
                  <label>Username</label>
                  <input
                    name='username'
                    disabled={ true }
                    onBlur={ this.validation }
                    onChange={ this.handleOnchange }
                    type='text'
                    onFocus={ this.labeltop }
                    value={ username }
                  />
                </div>
                <div className={ email !== '' ? 'form-group top' : 'form-group' }>
                  <label>Email</label>
                  <input
                    name='email'
                    disabled={ true }
                    onBlur={ this.validation }
                    data-validation={ [ 'email' ] }
                    onChange={ this.handleOnchange }
                    type='text'
                    onKeyUp={ this.labeltop }
                    value={ email }
                  />
                </div>
                <div className={ email !== '' ? 'form-group top' : 'form-group' }>
                  <label>Password</label>
                  <input
                    name='password'
                    disabled={ true }
                    type='password'
                    onKeyUp={ this.labeltop }
                    value={ password }
                  />
                </div>
                <div className='changepwd form-group'>
                  <Link to='/profile/changepassword'>Change password</Link>
                </div>
                <button type='submit' className='blue-bbt'>
                  <span>Update</span>
                </button>
              </div>
            </form>
            {popupState && (
              <div className='profile-container upload-image'>
                <div className='profile-popup-visible'>
                  <div className='title'>
                    <h4>Drag & Adjust</h4>
                    <button className='close-btn' onClick={ this.closePopUp }>
                      <Close />
                    </button>
                  </div>
                  <div id='container'>
                    <div className='buttons'>
                      <button onClick={ this.cropImage }>
                        <Done />
                      </button>
                    </div>
                  </div>
                  <div className='popup-controls'>
                    {loaderStatus && (
                      <div className='loader' style={ { zIndex: 999 } }>
                        <Loader style={ { width: 50, height: 50 } } />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {CampopupState && (
              <div className='profile-container upload-image'>
                <div className='profile-popup-visible'>
                  <div className='title'>
                    <h4>Drag & Adjust</h4>
                    <button className='close-btn' onClick={ this.closePopUp }>
                      <Close />
                    </button>
                  </div>
                  <div id='container'>
                    <div className='buttons'>
                      <button onClick={ this.handleRefresh }>
                        <Reload />
                      </button>
                      <button onClick={ this.cropImage }>
                        <Done />
                      </button>
                    </div>
                  </div>
                  <div className='popup-controls' id='camera-popup'>
                    {loaderStatus && (
                      <div className='loader' style={ { zIndex: 999 } }>
                        <Loader style={ { width: 50, height: 50 } } />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {webCamStatus && (
              <div className='profile-container upload-image'>
                <div className='profile-popup-visible'>
                <div className='title'>
                    <h4>Take Photo</h4>
                    <button className='close-btn' onClick={ this.closePopUp }>
                      <Close />
                    </button>
                  </div>
                  <div className='popup-controls' id='upload-image-popup'>
                    {/* <Cameraicon /> */}
                    <div className='loader'>
                      <Loader style={ { width: 50, height: 50 } }  />
                    </div>
                    <TakePhoto
                      width='300'
                      dispatchFunction={ this.camStatus }
                      cameraError={ this.CameraCheck }
                    />
                  </div>
                </div>
              </div>
            )}
            {cameraerror && (
              <div className='profile-container  not-found'>
                <div className='profile-popup-visible'>
                  <div className='pophead'>
                    <button className='close-btn' onClick={ this.closePopUp }>
                      <Close />
                    </button>
                  </div>
                  <div className='popup-controls'>
                    <div className='popup-body'>
                      Camera Not Found <br />
                      <Cameraicon />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {viewPhoto && (
              <div className='profile-container viwe-profileImage'>
                <div className='profile-popup-visible'>
                  <div className='popup-controls'>
                    <div>
                      <Image
                        className='viewImage'
                        name='profileImage'
                        alt='Profile Image'
                        imageToken={ image }
                      />
                    </div>
                  </div>
                </div>
                <button className='close-btn' onClick={ this.handleViewPhoto }>
                  <Close />
                </button>
              </div>
            )}
            { inValidFormatpopup &&
                    <div className='seccess-popup'>
                    <div className='content-wraper'>
                      <p>
                       { inValidFormatMeaasge }
                      </p>
                      <div className='action'>
                        <Link onClick={ this.handleInValidPopup }>ok</Link>
                      </div>
                    </div>
                  </div>
            }
            {deletepopup && (
              <Popup popupText='Are you sure want to delete this image?' handleYes={ this.removeProfilePicture } handleCancel={ this.closePopUp } />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default MyProfile;
