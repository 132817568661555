import {  teamConstants } from 'Constants/team.constants';
import Request from 'Services/request';
import { alterQueryRequest,  findCurrentPageRequest, queryParser } from 'Helpers/urlform'
import toastr from 'toastr';

const requestTeam = team =>({ 
    type: teamConstants.TEAM_REQUEST, 
    team 
})

const successTeam = teamObj =>({
    type: teamConstants.TEAM_GET_SUCCESS,
    team:teamObj.data,
    totalRecords:teamObj.count,
    currentPage:teamObj.currentPage
})

const successUpdateTeam = teamObj =>({
  type: teamConstants.UPDATE_TEAM_SUCCESS,
  team:teamObj
})

const failureTeam = error =>({
    type: teamConstants.TEAM_FAILURE,
    error
})

const failureAddTeam = error =>({
  type: teamConstants.TEAM_ADD_FORM_FAILURE,
  error
})

const failureEditTeam = error =>({
  type: teamConstants.TEAM_EDIT_FORM_FAILURE,
  error
})

const requestUsersList = usersList =>({
  type:teamConstants.USERS_REQUEST,
  usersList
})

const sucessUsersList = usersListObject => ({
  type:teamConstants.USERS_SUCCESS,
  usersListObject:usersListObject.data,
  totalRecords:usersListObject.count,
  currentPage:usersListObject.currentPage
})

const failureUsersList = error => ({
  type:teamConstants.USERS_FAILURE,
  error
})

const requestEditFormObject = (editFormObj) => ({
  type: teamConstants.EDIT_FORM_REQUEST,
  editObj: editFormObj,
})

function fetchTeam(SearchArray) {
  let url = '/team';
  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  }  
  const currentPage = findCurrentPageRequest(SearchArray);
  return async (dispatch) => {
    dispatch(requestTeam({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data,count } = response;
        dispatch(successTeam({ data, count, currentPage }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureTeam(error.toString()));
      });
  };
}

function fetchUsers(SearchArray){
  let url = '/users'
  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  }  
  const currentPage = findCurrentPageRequest(SearchArray);
  return async (dispatch) => {
    dispatch(requestUsersList({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data,count } = response;
        dispatch(sucessUsersList({ data, count, currentPage }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureUsersList(error.toString()));
      });
  };
}

  function addTeam(addTeamObj,SearchArray) {
    return (dispatch) => {
      dispatch(requestUsersList({}));
      Request.create(`/team`,
        { ...addTeamObj },
        { 'Authorization': `${ localStorage.getItem('user') }` })
        .then((response) => {
          const { message } = response;        
          dispatch(fetchTeam(SearchArray))
          toastr.remove();
          toastr.success(message);
        })
        .catch((error) => {
          dispatch(failureAddTeam(error.toString()));
       });
    };
  }

  function updateTeam(teamObj,SearchArray) {
    const { id, ...rest } = teamObj
    return (dispatch) => {
      dispatch(requestUsersList({}));
      Request.update(`/team/${ id }`,
        { ...rest },
        { 'Authorization': `${ localStorage.getItem('user') }` })
        .then((response) => {
          const { message } = response;        
          dispatch(successUpdateTeam(teamObj));
          dispatch(fetchTeam(SearchArray))
          toastr.remove();
          toastr.success(message);
        })
        .catch((error) => {
          dispatch(failureEditTeam(error.toString()));
        });
    };
  }
  
  function deleteTeam(id, SearchArray, remainningRecords,currentPage){
    const updateSearchArray = alterQueryRequest(SearchArray, remainningRecords,currentPage);
      return (dispatch) => {
          dispatch(requestTeam({}));
          Request.remove(`/team?id=${ id }`,
          {  },
          { 'Authorization': `${ localStorage.getItem('user') }` })
          .then((response) => {
            const { message } = response;
            dispatch(fetchTeam(updateSearchArray));
            toastr.remove();
            toastr.success(message);
          })
          .catch((error) => {
            toastr.remove();
            toastr.error(error.toString());
            dispatch(failureTeam(error.toString()));
          });
      }
  }

  function fetchEditObj(editObj) {
    return async (dispatch) => {
      await dispatch(requestEditFormObject(editObj));
    };
  }

export const TeamAction ={
    fetchTeam,
    addTeam,
    updateTeam,
    deleteTeam,
    fetchUsers,
    fetchEditObj,
}