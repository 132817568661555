import React from 'react'
import { connect } from 'react-redux';
import { groupsAction } from 'Actions/groupsAction';
import GroupsInfo from 'Components/Groups/GroupInfo/index';

function mapStatetoProps(state){
    const { id, GroupsInfoList, totalRecords, currentpage, loading } = state.groupsList
    const { exportGroupInfoData } = state.exportGroupData;
    return{
        id: id,
        GroupsInfoList: GroupsInfoList,
        totalRecords: totalRecords,
        currentpage: currentpage,
        loading: loading,
        exportGroupInfoData,
    } 
}

const mapDispatchtoProps = {
    fetchGroupDetails:groupsAction.fetchGroupDetails,
    exportGroupInfo: groupsAction.exportGroupInfo,
}

export default connect(mapStatetoProps,mapDispatchtoProps)(GroupsInfo)