import React, { Component } from 'react';
import { validator } from 'Helpers/validator';
import { Link } from 'react-router-dom';
import Loginbg from './images/login-wallpaper.svg';
import loginlogo from 'assets/images/logo.png';
import LoadingOverlay from 'react-loading-overlay';
import labeltop from 'Helpers/labelTop';
import EyeIcon from 'Components/Common/eyeIcon';
import { ReactComponent as Triangle } from 'assets/images/svg/triangle.svg';
import { ReactComponent as Square } from 'assets/images/svg/square.svg';
import { ReactComponent as Bubble } from 'assets/images/svg/bubble.svg';
import validation from 'Helpers/Validation';

/**
 * @file LoginPage Component
 * User authentication
 * @module 1.LoginPage
 * @extends React.Component
 */
export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false
    };
    this.validator = validator.getInstance();
  }

  /**
   * @method handleChange
   * @summary set the username and password state
   * @param {event} event - React onChange event
   * @return {none} undefined
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  };

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */
  validation = e => {
    validation(e, 'login-form');
  };

  togglPasswordIcon = event => {
    event.preventDefault();
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  };

  componentDidUpdate(prevProps) {
    if (this.props.errorElement !== prevProps.errorElement) {
      this.setState({ errorElement: this.props.errorElement });
    }
  }

  /**
   * @method handleSubmit
   * @summary submit the login form after the validation
   * @param {event} event - React onSubmit event
   * @return {none} undefined
   */
  handleSubmit = event => {
    event.preventDefault();
    const formId = event.target.id;
    this.setState({ errorElement: null }, () => {
      const { username, password } = this.state;
      if (formId && !this.validator.checkBeforeSubmit(formId)) {
        this.props.login(username, password);
      }
    });
  };

  render() {
    const { username, password, showPassword, errorElement } = this.state;
    const { loading, message } = this.props;
    return (
      <div className='main-logindiv' id='login-page'>
        <LoadingOverlay
          spinner
          active={ loading }
          text='Please wait...'
          styles={ {
            wrapper: {
              width: '100%',
              textAlign: '-webkit-center'
            }
          } }
        >
          <div className='icons'>
            <div className='left-side'>
              <Triangle className='triangle' />
              <Square className='square' />
            </div>
            <div className='right-side'>
              <Triangle className='triangle' />
              <Square className='square' />
            </div>
          </div>
          <div className='login'>
            <div className='left-section'>
              <img name='bg-image' src={ Loginbg } />
            </div>
            <div className='right-section'>
              <Bubble className='bubble' />

              <section>
                <div className='login-logo'>
                  <img src={ loginlogo } name='logo' />
                </div>
                <div className='main-login'>
                  <form
                    id='login-form'
                    className='login-form'
                    autoComplete='off'
                    onSubmit={ this.handleSubmit }
                  >
                    <div className='form-group'>
                      <label>Email / username</label>
                      <input
                        id='test'
                        className='login-forminput'
                        onBlur={ this.validation }
                        readOnly
                        data-validation={ [ 'usernameoremail' ] }
                        onChange={ this.handleChange }
                        onFocus={ labeltop }
                        value={ username }
                        type='text'
                        name='username'
                      />
                    </div>
                    <div className='form-group'>
                      <label>Password</label>
                      <input
                        className='login-forminput'
                        onBlur={ this.validation }
                        readOnly
                        data-validation={ [ 'passwordPattern' ] }
                        onChange={ this.handleChange }
                        onFocus={ labeltop }
                        value={ password }
                        type={ showPassword ? 'text' : 'password' }
                        name='password'
                      />
                      {errorElement && (
                        <span className='error'> {message} </span>
                      )}
                      <EyeIcon
                        dispatchFunction={ this.togglPasswordIcon }
                        showPassword={ showPassword }
                      />
                    </div>
                    <Link to='/forgotPassword' className='forget-password'>
                      Forgot password?
                    </Link>
                    <button className='default-bbt' type='submit'>
                      <span>Log In</span>
                    </button>
                  </form>
                </div>
              </section>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
