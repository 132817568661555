export const manageAdminConstant = {
    ADMIN_USER_REQUEST: 'ADMIN_USER_REQUEST',
    ADMIN_USER_GET_SUCCESS: 'ADMIN_USER_GET_SUCCESS',
    ADMIN_USER_FAILURE: 'ADMIN_USER_FAILURE',  
    ADD_ADMIN_USER_REQUEST: 'ADD_ADMIN_USER_REQUEST',
    ADD_ADMIN_USER_SUCCESS: 'ADD_ADMIN_USER_SUCCESS',
    UPDATE_ADMIN_USER_SUCCESS : 'UPDATE_ADMIN_USER_SUCCESS',
    DELETE_ADMIN_USER_SUCCESS : 'DELETE_ADMIN_USER_SUCCESS',
    ADMIN_USER_FORM_REQUEST: 'ADMIN_USER_FORM_REQUEST',
    ADMIN_USER_FORM_FAILURE: 'ADMIN_USER_FORM_FAILURE',
    ADMIN_USER_EDIT_FORM_FAILURE:'ADMIN_USER_EDIT_FORM_FAILURE'
}