import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'Actions';
import Activateaccount from 'Components/Activateaccount';

function mapStateToProps(state) {
    const { loading,errorElement } = state.resetPassword;
    return { loading,errorElement };
}

const mapDispatchToProps = {
    activateaccount: userActions.activateaccount
}; 

export default connect(mapStateToProps, mapDispatchToProps)(Activateaccount);
