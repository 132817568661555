import React, { Component, Fragment } from 'react';
import { ReactComponent as Closephoto } from 'assets/images/svg/closephoto.svg';
import { ReactComponent as Search } from 'assets/images/svg/search-icon.svg';
import Image from 'Components/Common/Image';
import validation from 'Helpers/Validation';
import { validator } from 'Helpers/validator';
import DragUpload from 'Components/Common/Dragupload';
import SearchBar from 'Components/Common/SearchBar';
import formUrlParams from 'Helpers/urlform';
import DataTable from 'Components/Table/dataTable';
import PrevNextButton from '../PrevNext';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

export default class AddTeam extends Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Name',
          combineapidata: [ 'name', 'image' ],
          accessor: 'name' /* API response */,
          cell: {
            type: 'image-with-name',
          },
        },
        {
          title: 'Mobile',
          accessor: 'mobileNumber',
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: (rowDetails) => {
            const { index, rowIdx, ...rest } = rowDetails;
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <button
                  id='addTeamMembers'
                  onClick={ this.handleAddandRemoveTeamMembers.bind(this, rest) }
                >
                  {this.modifiedAddOrRemoveButton(rest.username)}
                </button>
              </td>
            );
          },
        },
      ],
      teamName: '',
      aboutTeam: '',
      fileName: '',
      isActive: 1,
      imageUrl: '',
      loading: false,
      image: '',
      filtered: [],
      teamMembers: [],
      searchParam: '',
      currentPage: '',
      showError: false,
      errorElement: '',
      inValidFormatMeaasge: '',
      inValidFormatpopup: '',
    };
    this.state = model;
    this.validator = validator.getInstance();
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */

  validation = (e) => {
    validation(e, 'add-team-form');
  };

  modifiedAddOrRemoveButton = (username) => {
    const { teamMembers } = this.state;
    const isExists = teamMembers
      ? teamMembers.findIndex((member) => member.username === username)
      : -1;
    return isExists === -1 ? 'Add' : 'Delete';
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [ name ]: value });
  };

  handleCheckBoxChange = () => {
    this.setState({ isActive: !this.state.isActive & 1 });
  };

  formUrlParams = (name, value) => {
    const { filtered } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse }, () => {
      this.props.fetchUsers(this.state.filtered);
    });
  };

  handleSearch = (event) => {
    const { name, value } = event.target;
    this.setState({ searchParam: value }, () =>
      this.formUrlParams(name, value)
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.totalRecords !== this.props.totalRecords) {
      if (this.props.error !== true && this.props.errorElement === '') {
        document.getElementById('searchForm') && document.getElementById('searchForm').reset();
        this.redirectToGrid();
      }
    }
    if (prevProps.errorElement !== this.props.errorElement) {
      this.setState({ errorElement: this.props.errorElement });
    }
  }

  redirectToGrid = () => {
    this.props.history.push(`/broadcast/team`);
  };

  handleAddandRemoveTeamMembers = (rest, event) => {
    event.preventDefault();
    let updateMembers = [];
    const { teamMembers } = this.state;
    const newUserObj = {
      username: rest.username,
      name: rest.name,
      image: rest.image,
      mobileNumber: rest.mobileNumber,
    };
    const isExists =
      teamMembers &&
      teamMembers.some((el) => {
        return el.username === rest.username;
      });
    if (!isExists) {
      this.state.teamMembers.push(newUserObj);
      updateMembers = this.state.teamMembers;
    } else {
      var removeIndex = this.state.teamMembers
        .map((item) => {
          return item.username;
        })
        .indexOf(rest.username);
      this.state.teamMembers.splice(removeIndex, 1);
      updateMembers = this.state.teamMembers;
    }
    this.setState({
      teamMembers: updateMembers,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { id } = event.target;
    const { teamName, aboutTeam, isActive, image, teamMembers } = this.state;
    const teamUsers = teamMembers.map((item) => {
      return item[ 'username' ];
    });
    if (teamUsers.length === 0) {
      this.setState({ showError: true }, () => {
        if (
          id &&
          !this.validator.checkBeforeSubmit(id) &&
          this.state.showError !== true
        ) {
          this.props.addTeam(
            {
              teamName,
              aboutTeam,
              isActive,
              image,
              teamMembers: teamUsers,
            },
            this.props.filterVal
          );
        }
      });
    } else {
      this.setState({ showError: false }, () => {
        if (
          id &&
          !this.validator.checkBeforeSubmit(id) &&
          this.state.showError !== true
        ) {
          this.props.addTeam(
            {
              teamName,
              aboutTeam,
              isActive,
              image,
              teamMembers: teamUsers,
            },
            this.props.filterVal
          );
        }
      });
    }
  };

  updateImage = (fileToken, fileName) => {
    this.setState({
      image: fileToken,
      fileName,
    });
  };
  handleimageError = (error, message) => {
    this.setState({ inValidFormatpopup: error, inValidFormatMeaasge: message });
  };
  handleInValidPopup = () => {
    this.setState({ inValidFormatpopup: false });
  };

  removeProfileimage = () => {
    this.setState({
      image: '',
      fileName: '',
    });
  };

  handleUsersList = () => {
    const { teamMembers } = this.state;
    const { usersList } = this.props;
    const updatedList = [
      ...teamMembers,
      ...usersList
    ]
    return updatedList.reduce((acc, current) => {
      const x = acc.find(item => item.username === current.username);
      if (!x) {
        const updatedUsersList = {
          ...current
        }
        return acc.concat([ updatedUsersList ]);
      } else {
        return acc;
      }
    }, []);
  }

  render() {
    const {
      teamName,
      aboutTeam,
      fileName,
      image,
      teamMembers,
      searchParam,
      isActive,
      showError,
      errorElement,
      inValidFormatMeaasge,
      inValidFormatpopup,
    } = this.state;
    const {
      usersList,
      usersTotalRecords,
      usersCurrentPage,
      formLoading,
    } = this.props;
    return (
      <Fragment>
          <div className='addbroadcast addteam broadcast open' id='addteam'>
            <div className='overlay' onClick={ this.redirectToGrid }></div>
            <div className='addmgnt' id='team-form' >
              <LoadingOverlay
                active={ formLoading }
                spinner
                styles={ {
                  wrapper: {
                    width: '100%',
                    textAlign: '-webkit-center',
                  },
                } }
                text='Please wait...'
              ></LoadingOverlay>
              <div className='head'>
                <h2>New Team Broadcast</h2>
                <div className='tooglebbt'>
                  <div className='toogle' name='status'>
                    <span>(Inactive)</span>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        checked={ !!+isActive }
                        onChange={ this.handleCheckBoxChange }
                      />
                      <span className='slider round'></span>
                    </label>
                    <span>(active)</span>
                  </div>
                </div>
              </div>
              <div className='form'>
                <form
                  id='add-team-form'
                  autoComplete='off'
                  onSubmit={ this.handleSubmit }
                >
                  <div className='formgroup new'>
                    <label>
                      Team Name<sup>*</sup>
                    </label>
                    <input
                      type='text'
                      name='teamName'
                      className='team-ipout'
                      value={ teamName }
                      data-validation={ [ 'requiredfield' ] }
                      placeholder='Enter team name here!'
                      data-error='Enter team name'
                      onBlur={ this.validation }
                      maxLength='25'
                      onChange={ this.handleChange }
                    />
                  </div>

                  <div className='formgroup'>
                    <label>About Team</label>
                    <input
                      type='text'
                      name='aboutTeam'
                      className='team-ipout'
                      value={ aboutTeam }
                      placeholder='Enter about team here!'
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>
                      Profile image<sup>*</sup>
                    </label>
                    <div className='loading-parent dragable' id='dragable'>
                      <div className='profileimg'>
                        <Image imageToken={ image } />
                        <span className='profilelabel'>
                          {fileName && (
                            <i>
                              <Closephoto onClick={ this.removeProfileimage } />
                            </i>
                          )}
                        </span>
                      </div>
                      <DragUpload
                        id='addadminupload'
                        removeImage={ this.removeProfileimage }
                        updateImage={ this.updateImage }
                        requiredfield='requiredfield'
                        imageError={ this.handleimageError }
                      />
                    </div>
                  </div>
                  {errorElement && (
                    <span className='errorElement'>{errorElement}</span>
                  )}
                  <div className='buttons'>
                    <div>
                      <button
                        type='reset'
                        onClick={ this.redirectToGrid }
                        className='plain-bbt'
                      >
                        Cancel
                      </button>
                      <button className='blue-bbt'>Save</button>
                    </div>
                  </div>
                  <div className='form-group search'>
                    <label>Add users</label>
                    <div className='responsive-table'>
                      <div className='input'>
                        <SearchBar
                          name='nameSearch'
                          dispatchFunction={ this.handleSearch }
                        />
                        <Search />
                      </div>
                      <DataTable
                        className='data-table'
                        keyField='id'
                        headers={ this.state.headers }
                        data={ searchParam === '' ? teamMembers : this.handleUsersList() }
                        noData='No records!'
                      />
                      {showError && (
                        <span className='error'>
                          Team should contain atelast one member
                        </span>
                      )}

                      {searchParam !== '' && (
                        <PrevNextButton
                          handleClick={ this.formUrlParams }
                          totalRecords={ usersTotalRecords }
                          initialPage={ usersCurrentPage }
                          setPageLimit={ this.setPageLimit }
                          pageLimit={ this.state.pageLimit }
                        />
                      )}
                      {inValidFormatpopup && (
                        <div className='seccess-popup'>
                          <div className='content-wraper'>
                            <p>{inValidFormatMeaasge}</p>
                            <div className='action'>
                              <Link onClick={ this.handleInValidPopup }>ok</Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </Fragment>
    );
  }
}
