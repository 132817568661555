import EditManageAdminRoles from 'Components/Admin/ManageAdminRoles/Edit';
import { connect } from 'react-redux';
import { manageAdminRolesActions } from 'Actions';

function mapStatetoProps(state) {   
  return{ ...state.adminRoles };
}

const mapDispatchtoProps = {
  updateAdminRoles: manageAdminRolesActions.updateAdminRoles,
}

export default connect(mapStatetoProps, mapDispatchtoProps)(EditManageAdminRoles);