import { adminRolesConstants } from 'Constants/adminRole.costants';
import Request from 'Services/request';
import toastr from 'toastr';
import { alterQueryRequest,  findCurrentPageRequest, queryParser } from 'Helpers/urlform'

const requestAdminRoles = adminRoleObject => ({
  type: adminRolesConstants.ADMIN_ROLES_REQUEST,
  adminRoleObject
})
const requestAdminFormRoles = adminRoleObject => ({
  type: adminRolesConstants.ADMIN_ROLES_FORM_REQUEST,
  adminRoleObject
})

const sucecessAdminRoles = adminRoleObject => ({
  type: adminRolesConstants.ADMIN_ROLES_GET_SUCCESS,
  adminRoleObject:adminRoleObject.data,
  totalRecords:adminRoleObject.count,
  currentPage:adminRoleObject.currentPage
})

const sucecessAllAdminRoles = adminRoleObject => ({
  type: adminRolesConstants.ALL_ADMIN_ROLES_GET_SUCCESS,
  adminRoleObject:adminRoleObject.data
})

const sucecessAllowedModules = allowedModulesObject => ({
  type: adminRolesConstants.ALLOWED_MODULES_GET_SUCCESS,
  allowedModulesObject:allowedModulesObject
})

const successUpdateAdminRoles = adminRoleObject =>({
  type: adminRolesConstants.ADMIN_ROLES_UPDATE_SUCCESS,
  adminRoleObject
})

const failureAdminRloes = error => ({
  type: adminRolesConstants.ADMIN_ROLES_FAILURE,
  error
})

const failureDeleteRloes = error => ({
  type: adminRolesConstants.ADMIN_DELETE_ROLES_FAILURE,
  error
})

const failureAdminFormRloes = error => ({
  type: adminRolesConstants.ADMIN_ROLES_FORM_FAILURE,
  error
})
const failureEditAdminFormRloes = error => ({
  type: adminRolesConstants.ADMIN_ROLES_EDIT_FORM_FAILURE,
  error
})

function fetchAlladminRoles() {
  const url = '/roles/names'
  return async (dispatch) => {
    dispatch(requestAdminRoles({}));
    await Request.get(url, {},
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { data } = response;
        dispatch(sucecessAllAdminRoles({ data }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureAdminRloes(error.toString()));
      });
  };
}

function fetchAdminRoles(SearchArray) {
  let url = '/roles'

  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  } 
  const currentPage = findCurrentPageRequest(SearchArray);

  return async (dispatch) => {
    dispatch(requestAdminRoles({}));
    await Request.get(url, {},
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { data,count } = response;
        dispatch(sucecessAdminRoles({ data, count, currentPage }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureAdminRloes(error.toString()));
      });
  };
}

function addAdminRoles(addAdminRolesObj,SearchArray) {
  return (dispatch) => {
    dispatch(requestAdminFormRoles({}));
    Request.create('/roles', { ...addAdminRolesObj },
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { message } = response;
        dispatch(fetchAdminRoles(SearchArray));
        toastr.remove();
        toastr.success(message);
      })
      .catch((error) => {
        dispatch(failureAdminFormRloes(error.toString()));
      })
  }
}

function updateAdminRoles(adminRolesObj) {
  const { id, ...rest } = adminRolesObj
 
  return (dispatch) => {
    dispatch(requestAdminFormRoles({}));
    Request.update(`/roles/${ id }`, { ...rest },
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { message } = response;
        dispatch(successUpdateAdminRoles(adminRolesObj));
        toastr.remove()
        toastr.success(message)
      })
      .catch((error) => {
        dispatch(failureEditAdminFormRloes(error.toString()));
      })
  }
}

function deleteAdminRoles(id, SearchArray, remainningRecords,currentPage) {
  const updateSearchArray = alterQueryRequest(SearchArray, remainningRecords,currentPage);
  return (dispatch) => {
    dispatch(requestAdminRoles({}));
    Request.remove('/roles',{ id: id  },
    { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { message } = response;
        dispatch(fetchAdminRoles(updateSearchArray));
        toastr.remove();
        toastr.success(message)
      })
      .catch((error) => {
        dispatch(failureDeleteRloes(error.toString()));
      })
  }
}

function fetchAllowedModules(){
  const url = '/roles/allowedmodules'
  return async (dispatch) => {
    dispatch(requestAdminRoles({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data } = response;
        dispatch(sucecessAllowedModules( data ));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureAdminRloes(error.toString()));
      });
  };
}
function handleCancel(){
  dispatch(cancelEditForm( data ));
}
export const manageAdminRolesActions = {
  fetchAdminRoles,
  addAdminRoles,
  updateAdminRoles,
  fetchAlladminRoles,
  deleteAdminRoles,
  fetchAllowedModules
}
