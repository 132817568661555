import { history } from 'Helpers/history';
import { apiHost } from 'Helpers/apiurl';
import toastr from 'toastr';

const removeEmpty = obj => {
  Object.keys(obj).forEach(key => obj[ key ] == null && delete obj[ key ]);
};

class CustomError extends Error {
  constructor(statusCode, customError , ...params) {
      super(...params);
      this.CustomError = customError
      this.statusCode = statusCode
  }
}

function request(url, params, header, method = 'GET') {
  const condition = navigator.onLine ? 'online' : 'offline';
  toastr.remove()
  if(condition === 'offline') {
     return Promise.reject('Please check your internet connectivity')
  }
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  setHttpHeader(options, header);

  if (params) {
    (method === 'GET') ? url.concat('?', objectToQueryString(params))
      : options.body = JSON.stringify(params);
  }

  if(params instanceof File) {
      delete options.headers[ 'Content-Type' ]
      const formData = new FormData();
      formData.append('file', params);
      options.body = formData;
  }
  
  var apiUrl = apiHost + url;
  if (url.startsWith('https://') || url.startsWith('http://')) {
    apiUrl = url;
    delete options.headers[ 'Content-Type' ]
  }

  return fetch(apiUrl, options)
    .then(serverErrors)
    .then((response) => response.json())
    .then(handleErrors.bind(this, url));
}

function setHttpHeader(options, header) {
  if (header && header instanceof Object) {
    options.headers = {
      ...options.headers,
      ...header
    }
    return options;
  }
   return options;
}
 
function serverErrors(response) {
  if (response.status == 401) {
    localStorage.removeItem('user');
    history.push('/login');
  }
  if (!response.ok) throw Error(response.statusText);
  return response;
}

function handleErrors(url, response) {
  if(response.status == 204) return response; 
  if (response.status == 400 && url === '/login') {
    throw new CustomError(response.status, { errorElement:'password', message:response.message });  
  }
  if (response.status != 200 ) throw(response.message);
  return response;
}

function objectToQueryString(obj) {
  return Object.keys(obj).map((key) => `${ key }=${ escape(obj[ key ]) }`).join('&');
}

function get(url, params, header = null) {
  return request(url, params, header);
}

function create(url, params, header = null) {
  return request(url, params, header, 'POST');
}

function update(url, params, header = null) {
  return request(url, params, header, 'PUT');
}

function remove(url, params, header = null) {
  return request(url, params, header, 'DELETE');
}

function handleCatchedErrors(error, dispatch, failure ) {
  toastr.remove()
  if( error.statusCode && error.CustomError instanceof Object ) {
      dispatch(failure({
        validation: { ...error.CustomError }
      }));
      return;
  }
  dispatch(failure(error.toString()));
  // toastr.error(error.toString());
}

export default {
  get,
  create,
  update,
  remove,
  objectToQueryString,
  handleCatchedErrors
};
