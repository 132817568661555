import React,{ Fragment } from 'react';
import DataTable from 'Container/DataTable/DataTableContainer';
import Pagination from 'Components/Pagination/index';
import formUrlParams from 'Helpers/urlform';
import { Link } from 'react-router-dom';
import DeleteButton from 'Components/Table/DeleteButton';
import Image from 'Components/Common/Image';
//import { CSVLink } from 'react-csv';
import { ReactComponent as Audio } from 'assets/images/svg/audiocall.svg';
import { ReactComponent as Video } from 'assets/images/svg/videocall.svg';
import { ReactComponent as Incoming } from 'assets/images/svg/incoming.svg';
import { ReactComponent as Outgoing } from 'assets/images/svg/outgoing.svg';
import { ReactComponent as Missed } from 'assets/images/svg/missed.svg';
import { IncomingMessage } from 'http';
import {  formatDateTime, millisToMinutesAndSeconds } from 'Components/Common/TimeStamp';

export default class CallManagement extends React.Component {
  constructor(props) {
    super(props);
    const model = {
      headers : [
        {
          title: 'Call From',
          accessor: 'callFrom' /* API response */,
          chooseOption: {
            name: 'callFromSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          combineapidata: [ 'callFrom', 'fromUserImage' ],
          cell: {
            type: 'image-with-name'
          },
          sortOption: false,
        },
        {
          title: 'Call To',
          accessor: 'callTo' /* API response */,
          chooseOption: {
            name: 'callToSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          combineapidata: [ 'callTo', 'toUserImage' ],
          cell: {
            type: 'image-with-name'
          },
          sortOption: false,
        },
        {
          title: 'Date & Time',
          accessor: 'callDateAndTime',
          chooseOption: {
            name: 'callDateAndTimeSearch',
            Component: 'DateTimeRangeComponent',
            dispatchFunction: this.handleDateSearch
          },
          cell: function(rowDetails) {
            const { index, content } = rowDetails;
            return (
              <td key={ index } id='call-date-time'>
               { content && formatDateTime(content) }
              </td>
            );
          },
          sortOption: false, //{ status: true, column: 'createdAt' },
        },
        {
          title: 'Call Type',
          accessor:'callType',
          combineapidata: [ 'callType', 'Audio' ],
          chooseOption: {
            name: 'callTypeSearch', /* API param name */
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          cell: function(rowDetails) {
            const { content, index } = rowDetails;
            const td = <div className='user'>
              <i className='icon'>
                { content === 'audio' ? <Audio /> : 
                  content === 'video' ? <Video /> : 
                  ''
                }
              </i>
              { content } 
            </div>
            return <td key={ `${ content }${ index }` }>{ td }</td>;
          },
          /* cell: {
            type: 'icon-with-text'
          }, */
          sortOption: false,
        },
        {
          title: 'Duration',
          accessor: 'duration',
          cell: function(rowDetails) {
            const { content, index } = rowDetails;
            const duration = (content && content !== 0) ? millisToMinutesAndSeconds(content) : 0;
            return <td key={ index }>{ duration }</td>;
          },
          /* chooseOption: {
            name: 'durationSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          sortOption: false, */
        },
        {
          title: 'Status',
          accessor: 'status',
          chooseOption: {
            name: 'statusSearch',
            Component: 'DropDown',
            DropdownText: 'All',
            dispatchFunction: this.handleSearch,
            optionRender: this.createStatus
          },
          cell: function(rowDetails) {
            const { content, index } = rowDetails;
            const td = <div className='user'>
              <i className='icon'>
                { content === 0 ? <Incoming /> : 
                  content === 1 ? <Outgoing /> : 
                  content === 2 ? <Missed /> : 
                  ''
                }
              </i>
              { content === 0 ? 'Incoming' : content === 1 ? 'Outgoing' : content === 2 ? 'Missed' : '' } 
            </div>
            return <td key={ index }>{ td }</td>;
          },
          sortOption: false,
        },
      ],
      activeEditData: '',
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      initialPage: 1,
      activeSort: {},
    }
    this.state = model;
  }

  componentDidMount(){
    this.props.fetchCallManagement();
    this.props.exportCallManagement();
  }

  formUrlParams = (name, value, rest) => {
    const { filtered, activeSort } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse, ...rest }, () => {
      let updatedFilter = this.state.filtered;
      if(activeSort && activeSort.id){
        updatedFilter = [
          ...this.state.filtered,
          activeSort
        ]
      }
      this.props.fetchCallManagement(updatedFilter);
      this.props.exportCallManagement(updatedFilter);
    });
  }

  /**
   * @method sortTable
   * @summary sort for view data
   * @param {name} sortAttribite - Query string name
   * @return { function } formUrlSort
   */
  sortTable = (sortAttribite) => {
    const { activeSort } = this.state
    const sortType = (activeSort.id === sortAttribite) ? 
    ((activeSort.value && activeSort.value == 'desc') ? 'asc' : 'desc') : 'asc';
    this.setState({
      activeSort: { id: sortAttribite, value: sortType },
    }, () => {
      this.formUrlSort();
    })
  }

  /**
   * @method formUrlSort
   * @summary Construct the query string based on user selection
   * @return {function} call fetch admin user api after change local state
   */
  formUrlSort = () => {
    const updatedFilter = [
      ...this.state.filtered,
      this.state.activeSort
    ]
    this.props.fetchCallManagement(updatedFilter);
    this.props.exportCallManagement(updatedFilter);
  }

  createStatus = () => {
    const statusArray = [ 'Incoming', 'Outgoing', 'Missed' ]
    return statusArray.map((type, index) => {
      return (
          <option key={ index } value={ index }>{type}</option>
      )
    })
  }

  handleSearch = (event) => {
    const { name, value } = event.target;
    this.formUrlParams(name, value)
  }

  handleDateSearch = (data) => {
    for (const [ key, value ] of Object.entries(data)) {
      this.formUrlParams(key, value);
    }
  };

  setPageLimit = ( name, value ) => {
    this.formUrlParams(name, value,{ pageLimit: value })
  }

  updateActiveQuery = (updatedpagination) => {
    this.setState({
      filtered: [ ...this.state.filtered, ...updatedpagination ],
    });
  };

  render() {
    const { callList, totalRecords, currentPage, loading, exportCallManagementData } = this.props;
    return (
      <React.Fragment>
          <div className='grid'>
            <div className='head'>
              <h2>Call Management</h2>

              {/* { exportCallManagementData && exportCallManagementData.length !== 0 && <CSVLink
                  data = { exportCallManagementData }
                  filename = { 'Call Management Details.csv' }
                  className = 'btn green-bbt btn-export'
                  onClick={ () => {
                    console.log('Export Data'); // Your click handling logic
                  } }
                >
                  Export To CSV
                </CSVLink>
              } */}
            </div>
            <div className='responsive-table'>
              <DataTable 
                className="data-table"
                keyField="id"
                width="100%"
                /* pagination={ {
                    enabled: true,
                    pageLength: 5,
                    type: 'long'
                } } */
                headers={ this.state.headers }
                data={ callList }
                noData="No records!" 
                loading={ loading }
                sortTable={ this.sortTable }
                //profile={ this.props.profile }
              />
              <Pagination
                handleClick={ this.formUrlParams }
                totalRecords={ totalRecords }
                initialPage={ currentPage }
                setPageLimit={ this.setPageLimit }
                pageLimit={ this.state.pageLimit }
                updateActiveQuery={ this.updateActiveQuery }
              />
            </div>            
          </div>
      </React.Fragment>
    );
  }
}
