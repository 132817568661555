import React, { Component } from 'react';

export default class CheckboxItem extends Component {
  
    handleCheckboxChange = (event) => {
        const { checkboxChangeCallback } = this.props;
        checkboxChangeCallback(event.target.checked, event.target.name);
    };

    render() {
        const { checkboxname, parentkey, checkboxValue, checked, parentClass } = this.props;
        return (
            <li>
                <div className='check-box'>
                    <div className='checkbox'>
                        <input type='checkbox'
                            checked={ checked }
                            name={ checkboxname }
                            id={ `${ parentkey }${ checkboxname }${ parentClass }` }
                            onChange={ this.handleCheckboxChange }
                            value={ checkboxValue }
                        />
                        <label htmlFor={ `${ parentkey }${ checkboxname }${ parentClass }` }>
                            <span>{checkboxname}</span>
                        </label>
                    </div>
                </div>
            </li>

        )
    }
}