export const groupsContants={
    GROUPS_REQUEST : 'GROUPS_REQUEST',
    GROUPS_GET_SUCCESS : 'GROUPS_GET_SUCCESS',
    GROUP_INFO_GET_SUCCESS : 'GROUP_INFO_GET_SUCCESS',
    GROUPS_FAILURE : 'GROUPS_FAILURE',
    EXPORT_GROUP_REQUEST: 'EXPORT_GROUP_REQUEST',
    EXPORT_GROUP_SUCCESS: 'EXPORT_GROUP_SUCCESS',
    EXPORT_GROUP_FAILURE: 'EXPORT_GROUP_FAILURE',
    EXPORT_GROUP_INFO_REQUEST: 'EXPORT_GROUP_INFO_REQUEST',
    EXPORT_GROUP_INFO_SUCCESS: 'EXPORT_GROUP_INFO_SUCCESS',
    EXPORT_GROUP_INFO_FAILURE: 'EXPORT_GROUP_INFO_FAILURE',
}
