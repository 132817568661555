import React from 'react';
import { connect } from 'react-redux';
import TeamBroadcast from 'Components/Broadcast/Team/index';
import { TeamAction } from 'Actions'

function mapStatetoProps(state){
    const { responseArray, totalRecords,currentPage, loading } = state.team;
    const { profile } = state;
    return{
        profile: profile.permission && JSON.parse(profile.permission),
        team:responseArray,
        totalRecords,
        currentPage:currentPage,
        loading:loading
    }    
}
const mapDispatchtoProps = {
    fetchTeam:TeamAction.fetchTeam,
    deleteTeam:TeamAction.deleteTeam,
    fetchEditObj: TeamAction.fetchEditObj,
}

export default connect(mapStatetoProps,mapDispatchtoProps)(TeamBroadcast)