import React from 'react';
import Image from 'Components/Common/Image';
import { ReactComponent as Closephoto } from 'assets/images/svg/closephoto.svg';
import { ReactComponent as Search } from 'assets/images/svg/search-icon.svg';
import { validator } from 'Helpers/validator';
import SearchBar from 'Components/Common/SearchBar';
import formUrlParams from 'Helpers/urlform';
import DragUpload from 'Components/Common/Dragupload';
import DataTable from 'Components/Table/dataTable';
import PrevNextButton from '../PrevNext';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

class EditManageBroadcast extends React.Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Name',
          combineapidata: [ 'name', 'image' ],
          accessor: 'name' /* API response */,
          cell: {
            type: 'image-with-name',
          },
        },
        {
          title: 'Mobile',
          accessor: 'mobileNumber',
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: (rowDetails) => {
            const { index, rowIdx, ...rest } = rowDetails;
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <button
                  onClick={ this.handleAddandRemoveTeamMembers.bind(this, rest) }
                >
                  {this.modifiedAddOrRemoveButton(rest.username)}
                </button>
              </td>
            );
          },
        },
      ],
      teamName: '',
      aboutTeam: '',
      fileName: '',
      isActive: 0,
      loading: false,
      image: '',
      filtered: [],
      teamMembers: [],
      currentPage: '',
      searchParam: '',
      showError: false,
      editErrorElement: '',
      inValidFormatMeaasge:'',
      inValidFormatpopup:''
    };
    this.state = model;
    this.validator = validator.getInstance();
  }

  validation = (e) => {
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    const elementObject = {
      formId: 'edit-team-form',
      message: e.target.dataset.error,
      elementName: e.target.name,
      elementValue: e.target.value,
    };
    this.validator.validate(elementObject, inputRules);
  };

  componentDidMount() {
    this.props.fetchUsers();
  }

  handleCheckBoxChange = () => {
    this.setState({ isActive: !this.state.isActive & 1 });
  };

  redirectToGrid = () => {
    this.setState(
      {
        teamMembers: [],
      },
      () => {
        this.props.history.push(`/broadcast/team`);
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [ name ]: value });
  };

  handleAddandRemoveTeamMembers = (rest, event) => {
    event.preventDefault();
    let updateMembers = [];
    const { teamMembers } = this.state;
    const newUserObj = {
      username: rest.username,
      name: rest.name,
      image: rest.image,
      mobileNumber: rest.mobileNumber,
    };
    const isExists =
      teamMembers &&
      teamMembers.some((el) => {
        return el.username === rest.username;
      });
    if (!isExists) {
      updateMembers = this.state.teamMembers.concat(newUserObj);
    } else {
      var removeIndex = this.state.teamMembers
        .map((item) => {
          return item.username;
        })
        .indexOf(rest.username);
      this.state.teamMembers.splice(removeIndex, 1);
      updateMembers = this.state.teamMembers;
    }
    this.setState({
      teamMembers: updateMembers,
    });
  };

  modifiedAddOrRemoveButton = (username) => {
    const { teamMembers } = this.state;
    const isExists = teamMembers
      ? teamMembers.findIndex((member) => member.username === username)
      : -1;
    return isExists === -1 ? 'Add' : 'Delete';
  };

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      const { id, teamName, aboutTeam, isActive, image, teamMembers } = props;
      return {
        id,
        teamName,
        aboutTeam,
        isActive,
        image,
        fileName: image,
        teamMembers,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      if (this.props.error !== true && this.props.editErrorElement === '') {
        this.redirectToGrid();
      }
    }
    if (prevProps.editErrorElement !== this.props.editErrorElement) {
      this.setState({ editErrorElement: this.props.editErrorElement });
    }
  }

  componentWillUnmount() {
    this.setState({
      teamMembers: '',
    });
  }

  formUrlParams = (name, value) => {
    const { filtered } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse }, () => {
      this.props.fetchUsers(this.state.filtered);
    });
  };

  handleSearch = (event) => {
    const { name, value } = event.target;
    this.setState({ searchParam: value }, () =>
      this.formUrlParams(name, value)
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formId = event.target.id;
    const {
      id,
      teamName,
      aboutTeam,
      isActive,
      image,
      createdAt,
      teamMembers,
    } = this.state;
    const teamUsers = teamMembers.map((item) => {
      return item[ 'username' ];
    });
    if (teamUsers.length === 0) {
      this.setState({ showError: true });
    } else {
      this.setState({ showError: false }, () => {
        if (formId && !this.validator.checkBeforeSubmit(formId)) {
          this.props.updateTeam({
            id,
            teamName,
            aboutTeam,
            isActive,
            createdAt,
            image,
            teamMembers: teamUsers,
          }, this.props.filterVal);
        }
      });
    }
  };

  updateImage = (fileToken, fileName) => {
    this.setState({
      image: fileToken,
      fileName,
    });
  };

  removeProfileimage = () => {
    this.setState({
      image: '',
      fileName: '',
    });
  };
  
  handleimageError = ( error,message ) => {
    this.setState({ inValidFormatpopup:error,inValidFormatMeaasge:message })
  }

  handleInValidPopup = () => {
    this.setState({ inValidFormatpopup:false })
  }

  handleUsersList = () => {
    const { teamMembers } = this.state;
    const { usersList } = this.props;
    const updatedList = [
      ...teamMembers,
      ...usersList
    ]
    return updatedList.reduce((acc, current) => {
      const x = acc.find(item => item.username === current.username);
      if (!x) {
        const updatedUsersList = {
          ...current
        }
        return acc.concat([ updatedUsersList ]);
      } else {
        return acc;
      }
    }, []);
  }

  render() {
    const {
      teamName,
      aboutTeam,
      isActive,
      fileName,
      image,
      teamMembers,
      searchParam,
      formLoading,
      showError,
      editErrorElement,
      inValidFormatMeaasge,
      inValidFormatpopup
    } = this.state;
    const { usersList, usersTotalRecords, usersCurrentPage } = this.props;
    return (
      <React.Fragment>
        <div className='addbroadcast editteam broadcast open' id='editteam'>
          <div className='overlay' onClick={ this.redirectToGrid }></div>
          <div className='addmgnt' id='team-form' >
            <LoadingOverlay
              active={ formLoading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center',
                },
              } }
              text='Please wait...'
            ></LoadingOverlay>
            <div className='head'>
              <h2>Edit Team Broadcast</h2>
              <div className='tooglebbt'>
                <div className='toogle' name='status'>
                  <span>(Inactive)</span>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      checked={ !!+isActive }
                      onChange={ this.handleCheckBoxChange }
                    />
                    <span className='slider round'></span>
                  </label>
                  <span>(active)</span>
                </div>
              </div>
            </div>
            <div className='form'>
              <form id='edit-team-form' onSubmit={ this.handleSubmit }>
                <div className='formgroup new'>
                  <label>
                    Team Name<sup>*</sup>
                  </label>
                  <input
                    type='text'
                    name='teamName'
                    value={ teamName }
                    data-validation={ [ 'requiredfield' ] }
                    data-error='Enter team name'
                    onBlur={ this.validation }
                    placeholder='Enter team name here!'
                    maxLength='25'
                    onChange={ this.handleChange }
                  />
                </div>
                <div className='formgroup'>
                  <label>About Team</label>
                  <input
                    type='text'
                    name='aboutTeam'
                    value={ aboutTeam }
                    placeholder='Enter about team here!'
                    onChange={ this.handleChange }
                  />
                </div>
                <div className='formgroup'>
                  <label>
                    Profile image<sup>*</sup>
                  </label>
                  <div className='loading-parent dragable' id='dragable'>
                    <div className='profileimg'>
                      <Image
                        checkNotFound={ this.removeProfileimage }
                        imageToken={ image }
                      />
                      <span className='profilelabel'>
                        {fileName && (
                          <i>
                            <Closephoto onClick={ this.removeProfileimage } />
                          </i>
                        )}
                      </span>
                    </div>
                    {image === null && (
                      <DragUpload
                        id='addadminupload'
                        removeImage={ this.removeProfileimage }
                        updateImage={ this.updateImage }
                        requiredfield='requiredfield'
                        imageError= { this.handleimageError }
                      />
                    )}
                    {image !== null && (
                      <DragUpload
                        id='addadminupload'
                        removeImage={ this.removeProfileimage }
                        updateImage={ this.updateImage }
                        requiredfield=''
                      />
                    )}
                  </div>
                </div>
                {editErrorElement !== '' && (
                  <span className='errorElement'>{editErrorElement}</span>
                )}
                <div className='buttons'>
                  <div>
                    <button
                      type='reset'
                      onClick={ this.redirectToGrid }
                      className='plain-bbt'
                    >
                      Cancel
                    </button>
                    <button className='blue-bbt'>Save</button>
                  </div>
                </div>
                <div className='form-group search'>
                  <label>Add users</label>
                  <div className='responsive-table'>
                    <div className='input'>
                      <SearchBar
                        name='nameSearch'
                        dispatchFunction={ this.handleSearch }
                      />
                      <Search />
                    </div>
                    <DataTable
                      className='data-table'
                      keyField='id'
                      headers={ this.state.headers }
                      data={ searchParam === '' ? teamMembers : this.handleUsersList() }
                      noData='No records!'
                    />
                    {showError && (
                      <span className='error'>
                        Team should contain atelast one member
                      </span>
                    )}
                    {searchParam !== '' && (
                      <PrevNextButton
                        handleClick={ this.formUrlParams }
                        totalRecords={ usersTotalRecords }
                        initialPage={ usersCurrentPage }
                        setPageLimit={ this.setPageLimit }
                        pageLimit={ this.state.pageLimit }
                      />
                    )}
                    { inValidFormatpopup &&
                    <div className='seccess-popup'>
                    <div className='content-wraper'>
                      <p>
                       { inValidFormatMeaasge }
                      </p>
                      <div className='action'>
                        <Link onClick={ this.handleInValidPopup }>ok</Link>
                      </div>
                    </div>
                  </div>
                  }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default EditManageBroadcast;
