import { userConstants } from 'Constants/user.constants';

const initialState = { loading: false,errorElement:'',popup:false };

export function forgotpassword(state = initialState, action={}) {
  switch (action.type) {
    case userConstants.FORGET_PASSWORD_REQUEST:
      return { loading: true,errorElement:'',popup:false };
    case userConstants.FORGET_PASSWORD_SUCCESS:
      return { errorElement:'',popup:true }
    case userConstants.FORGET_PASSWORD_FAILURE:
      return { loading: false,errorElement:action.error };
    default:
      return state;
  }
}
