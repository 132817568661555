import { usersListConstants } from 'Constants/usersList.constants';

const initialState = {
    usersList:[],
    loading:false,
    totalRecords:0,
    errorElement:''
  }

export function usersList(state= initialState,action={}){
    const{ usersListObject,error }=action;
    switch(action.type){
        case usersListConstants.USERS_LIST_REQUEST:
            return { ...state,loading: true,errorElement:'' }
        case usersListConstants.USERS_LIST_SUCCESS:
            return {
                ...state,
                usersList: (usersListObject) ? [ ...usersListObject ] : [],
                totalRecords:action.totalRecords,
                currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
                loading:false,
                errorElement:''
              };
        case usersListConstants.USERS_LIST_FAILURE:

            return { 
                ...state,
                errorElement:error,
                loading:false }
        default:
            return state
    }

}