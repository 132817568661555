import ManageAdminRoles from 'Components/Admin/ManageAdminRoles/index';
import { connect } from 'react-redux';
import { manageAdminRolesActions } from 'Actions';

function mapStatetoProps(state){
    const { profile } = state
    return{
       ...state.adminRoles,
       profile:profile.permission && JSON.parse(profile.permission),
    }
}
const mapDispatchtoProps ={
    fetchAdminRoles:manageAdminRolesActions.fetchAdminRoles,
    addAdminRoles:manageAdminRolesActions.addAdminRoles,
    deleteAdminRoles:manageAdminRolesActions.deleteAdminRoles,
    fetchAllowedModules:manageAdminRolesActions.fetchAllowedModules

}

export default connect(mapStatetoProps,mapDispatchtoProps)(ManageAdminRoles);
