import React, { Component } from 'react';
import Loginbg from './images/reset-wallpaper.png';
import { ReactComponent as Triangle } from 'assets/images/svg/triangle.svg';
import { ReactComponent as Square } from 'assets/images/svg/square.svg';
import { ReactComponent as Lock } from './images/lock.svg';
import { Link } from 'react-router-dom';
import loginlogo from 'assets/images/logo.png';
import { ReactComponent as Bubble } from 'assets/images/svg/bubble.svg';

export default class Successful extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='main-logindiv'>
        <div className='icons'>
          <div className='right-side'>
            <Triangle className='triangle' />
            <Square className='square' />
          </div>
          <div className='left-side'>
            <Triangle className='triangle' />
            <Square className='square' />
          </div>
        </div>
        <div className='reset'>
          <div className='left-section'>
            <img src={ Loginbg } name='bg-image' />
          </div>
          <div className='right-section'>
            <Bubble className='bubble' />
            <section>
              <div className='login-logo'>
                <img src={ loginlogo } name='logo' />
              </div>
              <div className='main-login'>
                <div className='successful'>
                  <Lock />
                  <h4>Password Reset is successful</h4>
                  <Link to='/login'>Back to login</Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
