import { medaiHost } from 'Helpers/apiurl'
const checkUrlHttp = new RegExp('^(http|https)://', 'i');

export default function fileTokenToBase64(image) {
    return checkUrlHttp.test(image) ? image : 
    `${ medaiHost }/${ image }?mf=${ localStorage.getItem('user') }`
}

export const PAGELIMIT = 10;
export const PAGESTOSHOW = 5;
export const INITIALPAGE = 1;

export const paginationLimitArray = [ 5, 10, 20 ];