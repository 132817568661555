import React from 'react';
import { connect } from 'react-redux';
import { manageAdminActions } from 'Actions/manageAdminactions';
import AddManageAdmin from 'Components/Admin/ManageAdmin/Add/index';

const mapDispatchtoProps = {
    addAdminUser: manageAdminActions.addAdminUser
}

function mapStatetoProps(state){
  return { 
      ...state.manageAdmin
  }
}
  
export default connect(mapStatetoProps, mapDispatchtoProps)(AddManageAdmin);