import React, { Component } from 'react';
import CheckboxGroup from '../CheckboxGroup';
import { validator } from 'Helpers/validator';
import validation from 'Helpers/Validation';
import LoadingOverlay from 'react-loading-overlay';

class EditManageAdminRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module: {},
      roleName: '',
      childrender: false,
      editErrorElement: '',
    };
    this.validator = validator.getInstance();
  }

  componentDidMount() {
    const { roleName, adminRoles, id } = this.props;

    const moduleFilter = adminRoles
      .filter((x) => x.id === id)
      .map((val) => {
        return val.allowedModules !== ''
          ? JSON.parse(val.allowedModules)
          : null;
      })
      .filter((el) => el);

    this.setState({
      roleName,
      module: { ...moduleFilter[ 0 ] },
      childrender: true,
    });
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */

  validation = (e) => {
    validation(e, 'editroleform');
  };

  closeEditRoles = () => {
    this.props.closeEditRoles();
  };

  onCheckboxGroupChange = (checkboxGroupkey, checkboxes) => {
    this.setState((prevState) => ({
      ...prevState,
      module: {
        ...prevState.module,
        [ checkboxGroupkey ]: checkboxes,
      },
    }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  };

  createcheckBoxGroup = () => {
    var checkboxGroupArray = [];
    const { editClass } = this.props;

    for (const property in this.state.module) {
      checkboxGroupArray.push(
        <CheckboxGroup
          checkboxes={ this.state.module[ property ] }
          checkboxGroupkey={ property }
          parentClass={ editClass }
          key={ property }
          onCheckboxGroupChange={ this.onCheckboxGroupChange }
        />
      );
    }
    return checkboxGroupArray;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.id && !this.validator.checkBeforeSubmit(event.target.id)) {
      const { id } = this.props;
      const { roleName, module } = this.state;
      const updateData = { roleName, module, id };
      this.props.updateAdminRoles(updateData);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error) {
      if (this.props.error !== true && this.props.errorElement === '') {
        this.closeEditRoles();
      }
    }
    if (prevProps.editErrorElement !== this.props.editErrorElement) {
      this.setState({ editErrorElement: this.props.editErrorElement });
    }
  }

  render() {
    const { roleName, childrender, editErrorElement } = this.state;
    const { title, formLoading } = this.props;
    return (
      <React.Fragment>
        <div className='addadmin' id={ this.props.formId }>
          <div className='overlay' onClick={ this.closeEditRoles }></div>
          <div className='addadminroles'>
            <LoadingOverlay
              active={ formLoading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center',
                },
              } }
              text='Please wait...'
            ></LoadingOverlay>
            <div className='head'>
              <h2> {title}</h2>
            </div>
            <form
              id='editroleform'
              autoComplete='off'
              onSubmit={ this.handleSubmit }
            >
              <div className='formgroup rollname'>
                <label>
                  Role<sup>*</sup>
                </label>
                <input
                  id='roleName'
                  onChange={ this.handleChange }
                  value={ roleName }
                  type='text'
                  name='roleName'
                  onBlur={ this.validation }
                  data-validation={ [ 'requiredfield' ] }
                  data-error='Enter valid role name'
                  placeholder='Enter role here!'
                />
                {editErrorElement !== '' && (
                  <span className='errorElement'>{editErrorElement}</span>
                )}
              </div>
              <div className='allowedmodules'>
                <h3>Allowed Modules</h3>
                <div className='allroles'>
                  {childrender && this.createcheckBoxGroup()}
                </div>
              </div>

              {title === 'Edit Roles' && (
                <div className='buttons'>
                  <div>
                    <button
                      type='reset'
                      onClick={ this.closeEditRoles }
                      className='plain-bbt'
                    >
                      Cancel
                    </button>
                    <button type='submit' className='blue-bbt'>
                      Save
                    </button>
                  </div>
                </div>
              )}
              {title === 'View Roles' && (
                <div className='buttons'>
                  <div>
                    <button
                      type='reset'
                      onClick={ this.closeEditRoles }
                      className='plain-bbt'
                    >
                      Cancel
                    </button>
                    <button
                      onClick={ this.props.changeEditView }
                      className='blue-bbt'
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditManageAdminRoles;
