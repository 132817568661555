import React, { Component, Fragment } from 'react';
import { ReactComponent as Edit } from 'assets/images/svg/edit.svg';
import toastr from 'toastr';

export default class EditButton extends Component {
  handleEdit = () => {
    const { getActiveEditData, ...rest } = this.props;
    if(!this.props.checkPermission) {
      toastr.error('You dont have permission to edit')
      return
    }
    this.props.getActiveEditData(rest);
  };

  render() {
    return (
      <Fragment>
        <button disabled={ this.props.checkPermission ? null : 'disabled' } onClick={ this.handleEdit }>
          <Edit />
        </button>
      </Fragment>
    );
  }
}
