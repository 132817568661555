import { userContacts } from 'Constants'

const initialState = {
    userContactsList:[],
    loading:false,
    totalRecords:0
  }

export function userContactsList(state= initialState,action={}){
    const{ userContactObject }=action;
    switch(action.type){
        case userContacts.USER_CONTACTS_REQUEST:
            return { ...state,loading: true }
        case userContacts.USER_CONTACTS_SUCCESS:
            return {
                ...state,
                userContactsList: (userContactObject) ? [ ...userContactObject ] : [],
                totalRecords:action.totalRecords,
                currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
                loading:false
              };
        case userContacts.USERS_CONTACTS_FAILURE:
            return { 
                ...state,
                loading:false }
        default:
            return state
    }

}