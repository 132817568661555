const validatorsRules = {
  email: {
    test: {
     test: (inputString) => {
        return validateEmail(inputString)
    }
    },
    message: 'Enter an email',
  },
  usernameoremail: {
    test: {
      test: (inputString) => {
        return validateUserNameOrEmail(inputString)
      }
    },
    message: 'Enter an email or username',
  },
  user: {
    test: {
      test: (username) => {
        return validateUserName(username)
      }
    },
    message: 'Enter the valid name',
  },
  requiredfield: {
    test: /\S+/,  
    message: 'Field must not be empty',
  },
  password: {
    test: {
      test: (password, addtionalParam, elementName) => {
        return validatePassword(password, addtionalParam, elementName)
      }
    },
    message: 'Enter a password'
  },
  passwordPattern: {
    test:{
      test: (password, addtionalParam, elementName) => {
        return validatePasswordPattern(password, addtionalParam, elementName)
      }
    },
    message: 'Enter a Password'
  },
  passwordpattern: {
    test: {
      test: (password, addtionalParam, elementName) => {
        return validateConfirmPassword(password, addtionalParam, elementName)
      }
    },
    message: 'Enter a password'
  },
  mobilenumber: {
    test: {
      test: (mobilenumber) => {
        return validateMobileNumber(mobilenumber)
      }
    },
    message: 'Enter the valid mobile number',
  },
  username: {
    test: {
      test: (username) => {
        return validateusername(username)
      }
    },
    message: 'Enter the valid mobile number',
  },
  domain: {
    test: {
      test: (domain) => {
        return validateDomain(domain)
      }
    },
    message: 'Please enter mail domain',
  },
  adminuser: {
    test: {
      test: (adminuser) => {
        return validateAdminUser(adminuser)
      }
    },
    message: 'Please enter admin user',
  },
  number: {
    test: /^-?\d+$/,
    message: 'Enter the valid number',
  },
  emptyornumber: {
    test: /^(\s*|\d+)$/,
    message: 'Enter the valid number',
  },
  minlength: {
    test: (value, currentValue) => value.length > currentValue,
    message: 'must be longer than two characters',
  },
  greaterthanZero: {
    test: {
      test: (limit, addtionalParam, elementName) => {
        return validateVideoAudioLimit(limit, addtionalParam, elementName)
      }
    },
    // test:,
    message: 'Please enter a number',
  },
  requiredvideoformat: {
    test: {
      test: (numType, addtionalParam, elementName) => {
        return validatevideoduration(numType, addtionalParam, elementName)
      }
    },
    message: ''
  },
  requiredformat: {
    test: {
      test: (numType, addtionalParam, elementName) => {
        return validateduration(numType, addtionalParam, elementName)
      }
    },
    message: ''
  },
};

const phones = {
  'am-AM': /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
  'ar-AE': /^((\+?971)|0)?5[024568]\d{7}$/,
  'ar-BH': /^(\+?973)?(3|6)\d{7}$/,
  'ar-DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
  'ar-EG': /^((\+?20)|0)?1[0125]\d{8}$/,
  'ar-IQ': /^(\+?964|0)?7[0-9]\d{8}$/,
  'ar-JO': /^(\+?962|0)?7[789]\d{7}$/,
  'ar-KW': /^(\+?965)[569]\d{7}$/,
  'ar-SA': /^(!?(\+?966)|0)?5\d{8}$/,
  'ar-SY': /^(!?(\+?963)|0)?9\d{8}$/,
  'ar-TN': /^(\+?216)?[2459]\d{7}$/,
  'be-BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  'bg-BG': /^(\+?359|0)?8[789]\d{7}$/,
  'bn-BD': /^(\+?880|0)1[13456789][0-9]{8}$/,
  'cs-CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'da-DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'de-DE': /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
  'de-AT': /^(\+43|0)\d{1,4}\d{3,12}$/,
  'el-GR': /^(\+?30|0)?(69\d{8})$/,
  'en-AU': /^(\+?61|0)4\d{8}$/,
  'en-GB': /^(\+?44|0)7\d{9}$/,
  'en-GG': /^(\+?44|0)1481\d{6}$/,
  'en-GH': /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
  'en-HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
  'en-MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
  'en-IE': /^(\+?353|0)8[356789]\d{7}$/,
  'en-IN': /^(\+?91|0)?[-\s]?[6789]\d{9}$/,
  'en-KE': /^(\+?254|0)(7|1)\d{8}$/,
  'en-MT': /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
  'en-MU': /^(\+?230|0)?\d{8}$/,
  'en-NG': /^(\+?234|0)?[789]\d{9}$/,
  'en-NZ': /^(\+?64|0)[28]\d{7,9}$/,
  'en-PK': /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
  'en-RW': /^(\+?250|0)?[7]\d{8}$/,
  'en-SG': /^(\+65)?[89]\d{7}$/,
  'en-TZ': /^(\+?255|0)?[67]\d{8}$/,
  'en-UG': /^(\+?256|0)?[7]\d{8}$/,
  'en-US': /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  'en-ZA': /^(\+?27|0)\d{9}$/,
  'en-ZM': /^(\+?26)?09[567]\d{7}$/,
  'es-CL': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  'es-EC': /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
  'es-ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  'es-MX': /^(\+?52)?(1|01)?\d{10,11}$/,
  'es-PA': /^(\+?507)\d{7,8}$/,
  'es-PY': /^(\+?595|0)9[9876]\d{7}$/,
  'es-UY': /^(\+598|0)9[1-9][\d]{6}$/,
  'et-EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
  'fa-IR': /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
  'fi-FI': /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
  'fj-FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
  'fo-FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'fr-FR': /^(\+?33|0)[67]\d{8}$/,
  'fr-GF': /^(\+?594|0|00594)[67]\d{8}$/,
  'fr-GP': /^(\+?590|0|00590)[67]\d{8}$/,
  'fr-MQ': /^(\+?596|0|00596)[67]\d{8}$/,
  'fr-RE': /^(\+?262|0|00262)[67]\d{8}$/,
  'he-IL': /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
  'hu-HU': /^(\+?36)(20|30|70)\d{7}$/,
  'id-ID': /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
  'it-IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  'ja-JP': /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
  'kk-KZ': /^(\+?7|8)?7\d{9}$/,
  'kl-GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'ko-KR': /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
  'lt-LT': /^(\+370|8)\d{8}$/,
  'ms-MY': /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
  'nb-NO': /^(\+?47)?[49]\d{7}$/,
  'ne-NP': /^(\+?977)?9[78]\d{8}$/,
  'nl-BE': /^(\+?32|0)4?\d{8}$/,
  'nl-NL': /^(\+?31|0)6?\d{8}$/,
  'nn-NO': /^(\+?47)?[49]\d{7}$/,
  'pl-PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  'pt-BR': /(?=^(\+?5{2}\-?|0)[1-9]{2}\-?\d{4}\-?\d{4}$)(^(\+?5{2}\-?|0)[1-9]{2}\-?[6-9]{1}\d{3}\-?\d{4}$)|(^(\+?5{2}\-?|0)[1-9]{2}\-?9[6-9]{1}\d{3}\-?\d{4}$)/,
  'pt-PT': /^(\+?351)?9[1236]\d{7}$/,
  'ro-RO': /^(\+?4?0)\s?7\d{2}(\/|\s|\.|\-)?\d{3}(\s|\.|\-)?\d{3}$/,
  'ru-RU': /^(\+?7|8)?9\d{9}$/,
  'sl-SI': /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
  'sk-SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'sr-RS': /^(\+3816|06)[- \d]{5,9}$/,
  'sv-SE': /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
  'th-TH': /^(\+66|66|0)\d{9}$/,
  'tr-TR': /^(\+?90|0)?5\d{9}$/,
  'uk-UA': /^(\+?38|8)?0\d{9}$/,
  'vi-VN': /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
  'zh-CN': /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
  'zh-TW': /^(\+?886\-?|0)?9\d{8}$/
};

function mobileNumberValidation(mobilenumber) {
   return Object.values(phones).some(number => number.test(mobilenumber))
}
function usernameValidation(username) {
  return username.match(/^([ a-z0-9_.$!-]{8,16})*$/)
}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  const updateString =  s.charAt(0).toUpperCase() + s.slice(1)
  return updateString.replace(/([A-Z])/g, ' $1').trim()
}

function validateDomain(domain) {
  validatorsRules.domain.update = false;
  if (domain.length < 1) {
    return null;
  } else if (!domain.match(/^@[a-zA-Z0-9][a-zA-Z0-9-]([^\s]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]([^\s]){1,})+/)) {
    validatorsRules.domain.update = true;
    validatorsRules.domain.messageupdate = `Enter the valid domain address`;
    return null;
  } 
  return true;
}

function validateMobileNumber(mobilenumber) {
  validatorsRules.mobilenumber.update = false;
  if (!mobilenumber.match(/(^$)/) && !mobileNumberValidation(mobilenumber)) {
    validatorsRules.mobilenumber.update = true;
    validatorsRules.mobilenumber.messageupdate = `Enter a valid mobile number`;
    return null;
  } 
  return true;
}
function validateusername(username) {
  validatorsRules.username.update = false;
  if (!username.match(/(^$)/) && !usernameValidation(username)) {
    validatorsRules.username.update = true;
    validatorsRules.username.messageupdate = `Username can accepts alphabets,numbers and symbols (_-.$!) with the range of 8 to 16
    `;
    return null;
  } 
  return true;
}

function validateAdminUser(username) {
  validatorsRules.adminuser.update = false;
  if (username.length < 1) {
    return null;
  } else if (!username.match(/^([A-Za-z0-9]{3,})$/)) {
    validatorsRules.adminuser.update = true;
    validatorsRules.adminuser.messageupdate = `Enter a valid admin user`;
    return null;
  } 
  return true;
}

function validateUserName(username) {
  validatorsRules.user.update = false;
  if (username.length < 1) {
    return null;
  } else if (!username.match(/^([a-z0-9]+([-_\s]{1}[a-z0-9]+)*){4,20}$/i)) {
    validatorsRules.user.update = true;
    validatorsRules.user.messageupdate = `Enter a valid name`;
    return null;
  } 
  return true;
}

function validateEmail(email) {
  validatorsRules.email.update = false;
  if (email.length < 1) {
    return null;
  } else if (!email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-\.]+)\.([a-zA-Z]){2,63}$/)) {
    validatorsRules.email.update = true;
    validatorsRules.email.messageupdate = `Enter the valid email address (e.g., yourname@domain.com)`;
    return null;
  } 
  return true;
}

function validateUserNameOrEmail(usernameoremail) {
  validatorsRules.usernameoremail.update = false;
  if (usernameoremail.length < 1) {
    return null;
  } else if (usernameoremail.includes('@') && !usernameoremail.match(/^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i)) {  
    validatorsRules.usernameoremail.update = true;
    validatorsRules.usernameoremail.messageupdate = `Enter valid email address`;
    return null;
  } else if (!usernameoremail.includes('@') && !usernameoremail.match(/^([ a-z0-9_.$!-]{8,16})*$/)) {
    validatorsRules.usernameoremail.update = true;
    validatorsRules.usernameoremail.messageupdate = `Invalid credentials. Enter valid email address or username`;
    return null;
  } 
  return true;
}

function validatePasswordPattern(passwordPattern, addtionalParam, elementName) {
  validatorsRules.passwordPattern.update = false;
  if( passwordPattern.length < 1){
    return null;
  } else if(passwordPattern.search(/^(?=\S*[^\s])\S{8,}$/ )){
    validatorsRules.passwordPattern.update = true;
    validatorsRules.passwordPattern.messageupdate = `Invalid credentials. Enter a valid ${ capitalize(elementName) }`;  
    return null
}
return true;
}
function validateVideoAudioLimit(limit, addtionalParam, elementName) {
  validatorsRules.greaterthanZero.update = false;
  if( limit.length < 1){
    return null;
  } else if(limit.search( /^([1-9]+[0-9]*)$/)){
    validatorsRules.greaterthanZero.update = true;
    validatorsRules.greaterthanZero.messageupdate = `Enter the valid ${ capitalize(elementName) }`;  
    return null
}
return true;
}
function validateduration(numType, addtionalParam, elementName) {
  validatorsRules.requiredformat.update = false;
  if (addtionalParam  && !isNaN(addtionalParam.validateInput[ 0 ])  && addtionalParam.validateInput[ 1 ] !== '') {
    return true;
  }else if (addtionalParam  && isNaN(addtionalParam.validateInput[ 0 ])  && addtionalParam.validateInput[ 1 ] === '' ) {
    return true
   }else if(addtionalParam  && (addtionalParam.validateInput[ 0 ] > 0) && addtionalParam.validateInput[ 1 ] === ''){
    validatorsRules.requiredformat.update = true;
    validatorsRules.requiredformat.messageupdate = `Please select valid ${ capitalize(elementName) }`;
    return null
  }
  return true;
}
function validatevideoduration(numType, addtionalParam, elementName) {
  validatorsRules.requiredvideoformat.update = false;
  if (addtionalParam  && !isNaN(addtionalParam.validateInput[ 0 ])  && addtionalParam.validateInput[ 1 ] !== '') {
    return true;
  }else if (addtionalParam  && isNaN(addtionalParam.validateInput[ 0 ])  && addtionalParam.validateInput[ 1 ] === '' ) {
   return true
  }else if(addtionalParam  && (addtionalParam.validateInput[ 0 ] > 0) && addtionalParam.validateInput[ 1 ] === ''){
    validatorsRules.requiredvideoformat.update = true;
    validatorsRules.requiredvideoformat.messageupdate = `Please select valid ${ capitalize(elementName) }`;
    return null
  }
  return true;
}

function validateConfirmPassword(password, addtionalParam, elementName) {
  validatorsRules.passwordpattern.update = false;
  const validate = validatePassword(password, addtionalParam, elementName);
  if(validate){
    if(password.search(/^(?=\S*[A-Z])(?=\S*[~`\[\]|';+=!\\\-/@#$%^&_*(),.?":{}|<>])\S{8,}$/)){
        validatorsRules.passwordpattern.update = true;
        validatorsRules.passwordpattern.messageupdate = `Password must be atleast 8 characters with the combination of atleast one capital letter and a symbol.`;  
        return null
    }
    return true;
  }else {
    if(validatorsRules.password.update){
       validatorsRules.passwordpattern.update = true;
       validatorsRules.passwordpattern.messageupdate = validatorsRules.password.messageupdate 
    }
    return validate
  }
}

function validatePassword(password, addtionalParam, elementName) {
  validatorsRules.password.update = false;
  if (password.length < 1) {
    return null;
  }else if (addtionalParam  && addtionalParam.validateInput[ 1 ] &&  addtionalParam.validateInput[ 0 ] !== addtionalParam.validateInput[ 1 ]) {
      validatorsRules.password.update = true;
      validatorsRules.password.messageupdate = addtionalParam.updateMessage;
      return null;
  }
  return true;
}

export default validatorsRules;
