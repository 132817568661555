import React from 'react';
import {
  Switch, 
  Route, 
  Router
} from 'react-router-dom';
import SwitchRoutes from 'Routes/switchRoutes';
import { history } from 'Helpers/history';
import Login from 'Container/LoginContainer';
import ForgotPassword from 'Container/ForgotPasswordContainer';
import { connect } from 'react-redux';
import { profileActions } from 'Actions';
import ResetPassword from 'Container/ResetPassword';
import Activateaccount from 'Container/Activateaccount';
import Successful from 'Components/ResetPassword/Successful';

const openUrls = [ '/forgotPassword','/resetpassword', 'activateaccount' ]

function fetchProfileInitial(props, pathname ) {  
  if(openUrls.indexOf(pathname) === -1 && localStorage.getItem('user')) {
      props.fetchProfile();
  }
}

function App(props) {
 
  return (
      <div className="App">
          <Router history={ history }>
              { fetchProfileInitial(props, history.location.pathname) }
              <Switch>
                  <Route path="/forgotPassword" component={ ForgotPassword } />
                  <Route path="/resetpassword" component={ ResetPassword } />
                  <Route path="/activateaccount" component={ Activateaccount } />
                  <Route path="/login" component={ Login } />
                  <Route path="/resetpassword-success" component={ Successful } />

                  <SwitchRoutes />
              </Switch>
          </Router>
      </div>
  );
}

const mapDispatchToProps = {
   fetchProfile: profileActions.fetchProfile
};

export default connect(null, mapDispatchToProps)(App);
