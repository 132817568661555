import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'Actions';
import ChangePassword from 'Components/Profile/ChangePassword';

function mapStateToProps(state) {
    const { loading,errorElement } = state.changepassword;
    const { imageUrl } = state.profile;
    return { loading, imageUrl, errorElement };
}

const mapDispatchToProps = {
    changepassword: userActions.changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);