import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from 'Actions';
import Logo from 'assets/images/logo.png';
import svggenerator from 'Helpers/svggenerator';
import Image from 'Components/Common/Image';

class Header extends React.Component {
  togglemenu = () => {
    var menunav = document.getElementById('sidemenu');
    var menucontainer = document.getElementById('maincontainer');
    menunav.classList.toggle('close');
    menucontainer.classList.toggle('collaps');
  };

  componentDidUpdate( ) {
    if( this.props.error === true ) { 
      document.getElementById('logout').classList.remove('disabled')
    }
  }

  logoutdispatch = (event) => {
     document.getElementById('logout').classList.add('disabled')
     this.props.logout()
  }

  render() {
    const { profile } = this.props;
    const { image , username } =  profile ;
    return (
      <React.Fragment>
        <header>
          <div className='menu-icon'>
            <span className='menu-ic' onClick={ this.togglemenu }>
              {svggenerator({
                width: '21',
                height: '14',
                viewBox: '0 0 21 14',
                className: 'ic-menu',
                d:
                  'M52,62V59.665H64.665V62Zm0-5.83V53.835H73V56.17Zm0-5.835V48H68.556v2.335Z',
                transform: 'translate(-52 -48)'
              })}
            </span>
            <h1>
              {' '}
              <Link to='/'>
                {' '}
                <img src={ Logo } />{' '}
              </Link>{' '}
            </h1>
          </div>
          <div className='main-user-details'>
            <ul>          
              <li className='user-profile'>
                <div className='profile'>
                  <a>
                    <Image imageToken = { image } />
                  </a>
                    <span>{ username }</span>
                  <i>
                    {svggenerator({
                      width: '8.4',
                      height: '4.725',
                      viewBox: '0 0 8.4 4.725',
                      className: 'a',
                      d:
                        'M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z',
                      transform: 'translate(-54.818 -237.549)'
                    })}
                  </i>
                </div>
                <ul>
                  <li>
                    <Link to='/profile/myprofile'>My profile</Link>
                  </li>
                  <li><a id='logout' onClick={ this.logoutdispatch }> Logout</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
    const { loading, error } = state.userlogout;
    const { profile } = state;
    return { error, loading, profile };
}

const mapDispatchToProps = {
  logout: userActions.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);