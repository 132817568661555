import React, { Component } from 'react';
import { validator } from 'Helpers/validator';
import Loginbg from './images/login-wallpaper.svg';
import loginlogo from 'assets/images/logo.png';
import LoadingOverlay from 'react-loading-overlay';
import labeltop from 'Helpers/labelTop';
import EyeIcon from 'Components/Common/eyeIcon';
import { ReactComponent as Triangle } from 'assets/images/svg/triangle.svg';
import { ReactComponent as Square } from 'assets/images/svg/square.svg';
import { ReactComponent as Bubble } from 'assets/images/svg/bubble.svg';

export default class Activateaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newpassword: '',
      confirmpassword: '',
      shownNewPassword: false,
      showConfirmPassword: false,
      errorElement:''
    };
    this.validator = validator.getInstance();
  }

  /**
   * @method handleChange
   * @summary set the username and password state
   * @param {event} event - React onChange event
   * @return {none} undefined
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  };

  toggleEyeIcon = event => {
    event.preventDefault();
    const name = event.target.closest('i').getAttribute('name');
    this.setState({ [ name ]: !this.state[ name ] });
  };
  componentDidUpdate(prevProps){
    if (this.props.errorElement !== prevProps.errorElement) {
       this.setState({ errorElement:this.props.errorElement })
    }
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */
  
  validation = e => {
    const superparent = e.target.parentElement;
    !e.target.value && superparent.classList.remove('top');
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');

    let elementObject = {
      formId: 'activate-account',
      elementName: e.target.name,
      elementValue: e.target.value,
      message: e.target.dataset.error
    };
    if (e.target.name == 'confirmpassword') {
      const { newpassword, confirmpassword } = this.state;
      elementObject = {
        ...elementObject,
        updateMessage: 'Confirm and new password must be same.',
        validateInput: [ newpassword, confirmpassword ]
      };
    }

    this.validator.validate(elementObject, inputRules);
  };

  handleformSubmit = event => {
    event.preventDefault();
    const query = this.useQuery();
    const { newpassword, confirmpassword } = this.state;
    const formId = event.target.id
    this.setState({ errorElement: null },()=>{
    if (
      this.validator.checkBeforeSubmit(formId) ||
      newpassword !== confirmpassword
    ) {
      const element = document.getElementById('confirmpassword');
      const e = new Event('blur');
      element.setAttribute('data-check', true);
      element.dispatchEvent(e);
      element.removeAttribute('data-check');
      return;
    } else {
      this.props.activateaccount({
        confirmPassword: confirmpassword,
        newPassword: newpassword,
        token: query.get('token')
      });
    }
  })
  };
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  };

  render() {
    const { loading } = this.props;
    const {
      newpassword,
      confirmpassword,
      showConfirmPassword,
      shownNewPassword,
      errorElement
    } = this.state;
    return (
      <div className='main-logindiv'>
        <div className='icons'>
          <div className='right-side'>
            <Triangle className='triangle' />
            <Square className='square' />
          </div>
          <div className='left-side'>
            <Triangle className='triangle' />
            <Square className='square' />
          </div>
        </div>
        <div className='login'>
          <div className='left-section'>
            <img src={ Loginbg } name='bg-image' />
          </div>
          <div className='right-section'>
            <Bubble className='bubble' />
            <LoadingOverlay
              active={ loading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center'
                }
              } }
              text='Please wait...'
            >
              <section>
                <div className='login-logo'>
                  <img src={ loginlogo } name='logo' />
                </div>
                <div className='main-login'>
                  <form
                    id='activate-account'
                    className='login-form'
                    autoComplete='off'
                    onSubmit={ this.handleformSubmit }
                  >
                    <div className='form-group'>
                      <label>New password</label>
                      <input
                        id='test'
                        className='login-forminput'
                        onBlur={ this.validation }
                        data-validation={ [ 'passwordpattern' ] }
                        data-error='Enter a new password'
                        onChange={ this.handleChange }
                        onFocus={ labeltop }
                        value={ newpassword }
                        data-check={ true }
                        type={ shownNewPassword ? 'text' : 'password' }
                        name='newpassword'
                      />
                      <EyeIcon
                        name='shownNewPassword'
                        dispatchFunction={ this.toggleEyeIcon }
                        showPassword={ shownNewPassword }
                      />
                    </div>
                    <div className='form-group'>
                      <label>Confirm new password</label>
                      <input
                        className='login-forminput'
                        id='confirmpassword'
                        onBlur={ this.validation }
                        data-validation={ [ 'password' ] }
                        data-error='Enter a confirm password'
                        onChange={ this.handleChange }
                        onFocus={ labeltop }
                        data-check={ true }
                        value={ confirmpassword }
                        type={ showConfirmPassword ? 'text' : 'password' }
                        name='confirmpassword'
                      />
                      <EyeIcon
                        name='showConfirmPassword'
                        dispatchFunction={ this.toggleEyeIcon }
                        showPassword={ showConfirmPassword }
                      />
                         { errorElement !== '' && 
                    <span className='errorElement'>{ errorElement }</span> }
                    </div>
                    <button className='default-bbt' type='submit'>
                      <span>Activate your account</span>
                    </button>
                  </form>
                </div>
              </section>
            </LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}
