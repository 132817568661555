import React from 'react';
import { connect } from 'react-redux';
import { profileActions } from 'Actions';
import Profile from 'Components/Profile/MyProfile';

function mapStateToProps(state) {
    return { ...state.profile };
}

const mapDispatchToProps = {
    fetchProfile: profileActions.fetchProfile,
    updateProfile: profileActions.updateProfile,
    updateProfileImage: profileActions.updateProfileImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);