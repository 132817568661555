import { userConstants } from 'Constants/user.constants';
const initialState = { loading: false,errorElement:'' };

export function changepassword(state = initialState, action={}) {
  switch (action.type) {
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return { loading: true,errorElement:'' };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return { loading: false,errorElement:'' }
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return { loading: false,errorElement:action.error };
    default:
      return state;
  }
}
