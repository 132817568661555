import React, { Component, Fragment } from 'react';
import EditButton from 'Components/Table/EditButton';
import EditPopUp from 'Components/Table/EditPopUp';
import DeleteButton from 'Components/Table/DeleteButton';
import DataTable from 'Container/DataTable/DataTableContainer';
import formUrlParams from 'Helpers/urlform';
import AddManageAdminRoles from 'Container/ManageRoles/AddManageAdminRolesContainer';
import EditManageAdminRoles from 'Container/ManageRoles/EditManageAdminRolesContainer';
import { ReactComponent as Add } from 'assets/images/svg/add.svg';
import Pagination from 'Components/Pagination';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

/**
 * @file ManageAdminRoles Component
 * Admin can view, update and delete the admin roles
 * @module  7.ManageAdminRoles
 * @extends React.Component
 */

export default class ManageAdminRoles extends Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Role Name',
          chooseOption: {
            name: 'roleSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.debounceEvent(this.handleSearch,500)
          },
          cell: rowDetail => {
            const { index, id, rowIdx, ...rest } = rowDetail;
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <EditPopUp
                  id={ id }
                  { ...rest }
                  title='View Roles'
                  getActiveEditData={ this.getActiveEditData }
                  editClass='viewroles'
                  formId='viewroles'
                />
              </td>
            );
          },
          accessor: 'roleName' /* API response */
        },
        {
          title: 'No. of Admin Users',
          accessor: 'noOfAdminUsers'
        },
        {
          title: 'Allowed Modules',
          accessor: 'allowedModules',
          cell: rowDetail => {
            const { index, id, rowIdx } = rowDetail;
            return (
              <td
                key={ index }
                data-row={ rowIdx }
                className='tooltipdiv textlimit'
              >
                <div class='limit'>
                  <span>{rowDetail.allowedModules}</span>
                  
                  { rowDetail.allowedModules.length > 29 &&
                  <span data-tip='' data-for={ id } className='more'>
                    More..
                  </span> }
                  <ReactTooltip
                    id={ id.toString() }
                    place='right'
                    className='more-tooltip'
                  >
                    <ul>{this.moreOptions(rowDetail.allowedModules)}</ul>
                  </ReactTooltip>
                </div>
              </td>
            );
          }
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: rowDetail => {
            const { index, id, rowIdx, profile, ...rest } = rowDetail;
            const permission = profile && profile[ 'Manage admin roles' ];
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <EditButton
                  id={ id }
                  { ...rest }
                  getActiveEditData={ this.getActiveEditData }
                  formId='editroles'
                  editClass='editroles'
                  title='Edit Roles'
                  checkPermission={ permission && permission.edit }
                />
                <DeleteButton
                  remainningRecords={ this.props.adminRoles.length }
                  deleteActionListener={ this.props.deleteAdminRoles }
                  activeQuery={ this.state.filtered }
                  id={ id }
                  checkPermission={ permission && permission.delete }
                  currentPage = { this.props.currentPage }
                />
              </td>
            );
          }
        }
      ],
      filtered: [],
      responseArray: [],
      activeEditData: '',
      pageLimit: 10,
      addAdminRolesFormStatus: false,
      editAdminRolesFormStatus: false,
      popup:false
    };
    this.state = model;
  }

  moreOptions = allowedModules => {
    return allowedModules
      .split(',')
      .map(individualmodule => <li>{individualmodule}</li>);
  };

  showMore = event => {
    const tooltip = event.target.nextElementSibling;
    Object.assign(tooltip.style, {
      opacity: 1,
      visibility: 'visible'
    });
  };

  /**
   * @event componentDidMount
   * @summary API call to fetch admin roles
   * @return {Object} return manage admin role object
   */
  componentDidMount() {
    this.props.fetchAdminRoles();
    this.props.fetchAllowedModules();
  }
  componentDidUpdate(prevProps) {
    if (this.props.popup !== prevProps.popup) {
      this.setState({ popup: this.props.popup });
    }
  }

  getActiveEditData = data => {
    this.setState(
      {
        activeEditData: data,
        editAdminRolesFormStatus: true
      },
      () => {
        document.getElementById(data.editClass).classList.add('open');
      }
    );
  };

  /**
   * @method createAdminRoles
   * @summary Render access level options dynamically based on admin roles
   * @return {none} undefined
   */

  createAdminRoles = () => {
    const { adminRoles } = this.props;
    if (adminRoles.length <= 0) return null;
    return adminRoles.map((admin, index) => {
      return (
        <option key={ index } value={ admin.id }>
          {admin.roleName}
        </option>
      );
    });
  };

  /**
   * @method formUrlParams
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @param {rest} rest - Rest object except name and value
   * @summary Construct the query string based on user selection
   * @return {function} call fetch admin roles  api after change local state
   */

  formUrlParams = (name, value, rest) => {
    const { filtered } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse, ...rest }, () => {
      this.props.fetchAdminRoles(this.state.filtered);
    });
  };

  /**
   * @method debounceEvent 
   * @summary used to delay the event for some limit
   * @param {event} event - React onChange event
   * @return { function } debouncedEvent
   */
  debounceEvent(...args) {
    this.debouncedEvent = debounce(...args);
    return e => {
      e.persist();
      return this.debouncedEvent(e);
    };
  }

  /**
   * @method handleSearch
   * @summary set the username and password state
   * @param {event} event - React onChange event
   * @return { function } formUrlParams
   */

  handleSearch = event => {
    const { name, value } = event.target;
      this.formUrlParams(name, value);
  };

  /**
   * @method hanldeAdd
   * @summary open admin role popup based click event
   * @return {none} undefined
   */
  hanldeAdd = () => {
    this.setState({ addAdminRolesFormStatus: true }, () => {
      document.getElementById('addroles').classList.add('open');
    });
  };

  /**
   * @method closeAddRoles
   * @summary close admin role popup
   * @return { object } updated admin role state
   */

  closeAddRoles = () => {
    this.setState({ addAdminRolesFormStatus: false, filtered: [] });
  };
  /**
   * @method setPageLimit
   * @summary set page limit for view data per page
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @return { function } formUrlParams
   */

  setPageLimit = (name, value) => {
    this.formUrlParams(name, value, { pageLimit: value });
  };

  /**
   * @method closeEditRoles
   * @summary close admin edit role popup
   * @return { object } updated admin role state
   */

  closeEditRoles = () => {
    this.setState({ editAdminRolesFormStatus: false });
  };

  changeEditView = () => {
    const { activeEditData } = this.state;
    this.setState({
      activeEditData: {
        ...activeEditData,
        formId: 'editroles',
        editClass: 'editroles',
        title: 'Edit Roles'
      }
    });
  };

  alterAdminRoleResponse = () => {
    const { adminRoles } = this.props;

    return adminRoles.map(val => {
      const parsedValue =
        val.allowedModules == '' ? '' : JSON.parse(val.allowedModules);
      var updatedObject = Object.keys(parsedValue)
        .map(ele => {
          const check =
            parsedValue[ ele ] &&
            Object.values(parsedValue[ ele ]).some(element => element);
          if (check) {
            return ele;
          }
        })
        .filter(ele => ele);
      var resObj = Object.values(updatedObject).join(',');
      const resData = { ...val, allowedModules: resObj };
      return resData;
    });
  };
  handleErrorPopup = (event) => {
    this.setState({ popup:false })
  }
  render() {
    const { totalRecords, currentPage, loading, profile, errorElement } = this.props;
    const { addAdminRolesFormStatus, editAdminRolesFormStatus,popup } = this.state;
    const resData = this.alterAdminRoleResponse();
    const Permission = profile && profile[ 'Manage admin roles' ];
    return (
      <Fragment>
        <div className='grid manage-admin'>
          <div className='head'>
            <h2>Manage Roles</h2>
            { popup &&
                    <div className='seccess-popup'>
                    <div className='content-wraper'>
                      <p>
                        { errorElement }
                      </p>
                      <div className='action'>
                        <Link onClick={ this.handleErrorPopup }>ok</Link>
                      </div>
                    </div>
                  </div>
                  }
            {addAdminRolesFormStatus && (
              <AddManageAdminRoles
                optionRender={ this.createAdminRoles }
                closeAddRoles={ this.closeAddRoles }
                filterVal = { this.state.filtered }
              />
            )}
            {editAdminRolesFormStatus && (
              <EditManageAdminRoles
                { ...this.state.activeEditData }
                changeEditView={ this.changeEditView }
                closeEditRoles={ this.closeEditRoles }
              />
            )}
            {Permission && Permission.create && (
              <button className='blue-bbt' onClick={ this.hanldeAdd }>
                <Add />
                Add Roles
              </button>
            )}
          </div>
          <div className='responsive-table'>
            <DataTable
              className='data-table'
              headers={ this.state.headers }
              data={ resData }
              noData='No records!'
              loading={ loading }
            />
             <Pagination
            handleClick={ this.formUrlParams }
            totalRecords={ totalRecords }
            initialPage={ currentPage }
            setPageLimit={ this.setPageLimit }
            pageLimit={ this.state.pageLimit }
          />
          </div>
        </div>
      </Fragment>
    );
  }
}
