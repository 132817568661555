import React, { Component, Fragment } from 'react';
import { ReactComponent as PrevNext } from 'assets/images/svg/prevnext.svg';

class PrevNextButton extends Component{
    constructor(props){
        super(props);
        this.state={
            pager:{}
        }
    }
    componentDidMount() {
        const { initialPage, pageLimit, totalRecords } = this.props;
        if(totalRecords > pageLimit ) this.pageSetup(initialPage);
      }
    componentDidUpdate(prevProps) {
        const { pageLimit, totalRecords, initialPage } = this.props;
        if (totalRecords !== prevProps.totalRecords || 
             pageLimit !== prevProps.pageLimit || initialPage !== prevProps.initialPage) { 
             this.pageSetup(initialPage);
        }
    }
   
    getPager(totalItems =1 , currentPage = 1, pageLimit =10) {
        const totalPages = Math.ceil(totalItems / pageLimit);
        return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageLimit: pageLimit,
          totalPages: totalPages,
        };
      }
      
    pageSetup(page) {
      const { totalRecords, pageLimit } = this.props;
      let pager = this.state.pager;
      if (page < 1 || page > pager.totalPages) {
        return;
      } 
      pager = this.getPager(totalRecords, page, pageLimit); 
      this.setState({ pager: pager });
    }
    setPage(page) {    
       const { pager } = this.state;
        this.setState({
          pager:{
            ...pager,
            currentPage:page
          }      
        },()=> {
          this.props.handleClick('page', this.state.pager.currentPage);
        })   
    }
    render(){
        const { pager } =this.state;
        return(
            <Fragment>
                <div className='paginationnumbers'>
                <div className='pagination'>
                    <ul>
                    <li className={ pager.currentPage === 1 ? 'disabled' : '' } onClick={ () => this.setPage(pager.currentPage - 1) } >
                        <i className='prev'>
                        <PrevNext />
                        </i>
                        Previous
                    </li>
                    <li className={ pager.currentPage === pager.totalPages ? 'disabled' : '' } onClick={ () => this.setPage(pager.currentPage + 1) } >
                        Next
                        <i className='next'>
                        <PrevNext />
                        </i>
                    </li>
                    </ul>
                </div>
                </div>
            </Fragment>
        )
    }
}
export default PrevNextButton;