import { connect } from 'react-redux';
import AddTeam from 'Components/Broadcast/Team/Add/index';
import { TeamAction } from 'Actions'

function mapStatetoProps(state){
    const { usersList,usersTotalRecords,totalRecords, formLoading,errorElement } = state.team
    return{
        totalRecords,
        usersList:usersList,
        usersTotalRecords:usersTotalRecords,
        formLoading,
        errorElement
    } 
}
    const mapDispatchtoProps = {
        fetchUsers:TeamAction.fetchUsers,
        addTeamImage:TeamAction.addTeamImage,
        addTeam:TeamAction.addTeam
    }

export default connect(mapStatetoProps,mapDispatchtoProps)(AddTeam)