import { userConstants } from 'Constants';
import Request from 'Services/request.js';
import { history } from 'Helpers/history';
import toastr from 'toastr';
import { profileActions } from 'Actions';
export const userActions = {
  login,
  logout,
  forgotPassword,
  changePassword,
  resetPassword,
  activateaccount
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({}));

    Request.create('/login', {
      username,
      password
    })
      .then((response) => {
        const { data } = response;
        localStorage.setItem('user', data.token);
        localStorage.setItem('userdetail', JSON.stringify({ username, password }));
        dispatch(success(data));
        dispatch(profileActions.fetchProfile());
        history.push('/');
      })
      .catch((error) => {
         Request.handleCatchedErrors(error, dispatch, failure )
      });
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function activateaccount(activatePasswordObject) {
  const Url = '/activateaccount'.concat('?',Request.objectToQueryString(activatePasswordObject))
  return (dispatch) => {
    dispatch(request({}));
    Request.get(Url,{})
      .then((response) => {
        const { data, message } = response;
        localStorage.removeItem('userdetail')
        dispatch(success(data));
        toastr.success(message);
        history.push('/login');
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(response) { return { type: userConstants.ACTIVATE_PASSWORD_REQUEST, response }; }
  function success(response) { return { type: userConstants.ACTIVATE_PASSWORD_SUCCESS, response }; }
  function failure(error) { return { type: userConstants.ACTIVATE_PASSWORD_FAILURE, error }; }
}

function forgotPassword(emailId) {
  return (dispatch) => {
    dispatch(request({}));

    Request.create('/forgotpassword', {
      emailId
    })
      .then((response) => {
        const { data } = response;
        localStorage.removeItem('user');
        dispatch(success(data));
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(response) { return { type: userConstants.FORGET_PASSWORD_REQUEST, response }; }
  function success(response) { return { type: userConstants.FORGET_PASSWORD_SUCCESS, response }; }
  function failure(error) { return { type: userConstants.FORGET_PASSWORD_FAILURE, error }; }
}

function changePassword(changePasswordObject) {
  const { newPassword, currentPassword, confirmPassword } = changePasswordObject;
  const { username } = JSON.parse(localStorage.getItem('userdetail'));
  return (dispatch) => {
    dispatch(request({}));
    Request.create('/admin/changepassword', {
      newPassword,
      currentPassword,
      confirmPassword
    }, { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { data, message } = response;
        dispatch(success(data));    
        localStorage.setItem('userdetail', JSON.stringify({ username, newPassword }));
        dispatch(login(username, newPassword))    
        return message
      }).then(message => {
        toastr.remove();
        toastr.success(message)
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(response) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, response }; }
  function success(response) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, response }; }
  function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error }; }
}

function resetPassword(resetPasswordObject) {
  const Url = '/resetpassword'.concat('?',Request.objectToQueryString(resetPasswordObject))
  return (dispatch) => {
    dispatch(request({}));
    Request.get(Url,{})
      .then((response) => {
        const { data } = response;
        dispatch(success(data));
        localStorage.removeItem('userdetail')
        history.push('resetpassword-success');
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(response) { return { type: userConstants.RESET_PASSWORD_REQUEST, response }; }
  function success(response) { return { type: userConstants.RESET_PASSWORD_SUCCESS, response }; }
  function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error }; }
}

function logoutPromise(params) {
   return Promise.resolve(true)
}

function logout() {
  return (dispatch) => {
    logoutPromise()
      .then(() => {
        dispatch(success());
        localStorage.removeItem('user');
        history.push('/');
      })
  };
  function success(response) { return { type: userConstants.LOGOUT_SUCCESS, response }; }
}