import React, { Component, Fragment } from 'react';
import { ReactComponent as Plus } from 'assets/images/svg/plus.svg';
import uploadImage from 'Helpers/uploadImage';
import Upload from 'Components/Common/Uploader';
import toastr from 'toastr';
import fileTokenToBase64 from 'Helpers';
import Cropme from 'croppie';
import 'croppie/croppie.css';
import { ReactComponent as Loader } from 'assets/images/svg/loader.svg';

let dropArea;

var cropme;

const popUpOptions = {
    showZoomer: true,
    enableOrientation: true,
    viewport: { width: 150, height: 150, type: 'square' },
    boundary: { width: 300, height: 300 },
    initialZoom:-1
};

export default class DragUpload extends Component {

    state = { fileName:'', loading: false, popupState: false }
    componentDidMount() {
        dropArea = document.getElementById('dragable');
        [ 'dragenter', 'dragover', 'dragleave', 'drop' ].forEach(eventName => {
            dropArea.addEventListener(eventName, this.preventDefaults, false);
        });
        [ 'dragenter', 'dragover' ].forEach(eventName => {
            dropArea.addEventListener(eventName, this.highlight, false);
        });
        [ 'dragleave', 'drop' ].forEach(eventName => {
            dropArea.addEventListener(eventName, this.unhighlight, false);
        });

        dropArea.addEventListener('drop', this.handleDrop, false);
    }

    preventDefaults = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    highlight = e => {
        dropArea.classList.add('highlight');
    };

    unhighlight = e => {
        dropArea.classList.remove('highlight');
    };

    handleDrop = e => {
        e.preventDefault()
        const dt = e.dataTransfer;
        const fileElement = document.getElementById(`${ this.props.id }`)
        fileElement.files = dt.files;
        fileElement.dispatchEvent(new Event('change', { bubbles: true, }))
    };
   
    handleFileUpload = (fileType, file) => {
        this.uploadBeforeCropImage(file);
    }
    handleimageError = (error,messsage) => {
        this.props.imageError(error,messsage)
    }

    cropInitilize = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        }, () => {
            cropme.result({
                type: 'base64'
            })
            .then(base64 => {
                const fileInstance = this.dataURLtoFile(base64, this.state.fileName);
                this.uploadImageAfterCrop(fileInstance)
            });
        })
    }

    uploadImageAfterCrop = (fileInstance) => {
        uploadImage(`type=${ this.props.type }`, fileInstance)
            .then(({ data }) => {
                const { fileToken } = data;
                const imageUrl = fileTokenToBase64(fileToken);
                this.setState({ loading: false,popupState:false },
                    () => this.props.updateImage(fileToken, this.state.fileName, imageUrl))
            })
            .catch(error => {
                toastr.error(error.toString());
            });
    }

    dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[ 0 ].match(/:(.*?);/)[ 1 ];
        const u8arr = Uint8Array.from(atob(arr[ 1 ]), c => c.charCodeAt(0));
        return new File([ u8arr ], filename, { type: mime });
    };

    uploadBeforeCropImage = (file) => {
        this.setState({
            popupState: true,
            fileName: file.name
        }, () => {
            const element = document.getElementById('container');
            cropme = new Cropme(element, popUpOptions);
            var reader = new FileReader();
            reader.onload = function (e) {
                cropme.bind({
                    url: e.target.result
                }).then(()=> cropme.setZoom(0));
            };
            reader.readAsDataURL(file);
        })
    };

    removeImage = () => {
        this.props.removeImage()
    };
    closePopUp = event => {
        event.preventDefault();
        this.setState({ popupState: false });
      };

    render() {
        const { id, type = 'profile', requiredfield = false } = this.props;
        const { loading, popupState } = this.state;
        return (
            <Fragment>
                <div className='formgroup input-Wrapper upload'>
                    <div className='profilelist'>
                        <i className='uploadic' >
                            <label
                                htmlFor={ id }
                                className={ id }
                            >
                                <Plus />
                            </label>
                        </i>
                    </div>
                    <div id='dragable' className='dragable'>
                        <Upload
                            dataValidation={ [ requiredfield ] }
                            dataError='Please upload image'
                            id={ id }
                            type={ type }
                            updateProfileImage={ this.handleFileUpload }
                            imageError= { this.handleimageError }
                            />
                        <label htmlFor={ id }>
                            Drag files here or <span>browse</span>
                        </label>
                    </div>
                </div>
                {popupState && (
                    <div className='profile-container'>
                        <div className='profile-popup-visible'>
                            <div id='container'></div>
                            <div className='popup-controls'>
                                {loading && (
                                    <div className='loader' style={ { zIndex:999 } }>
                                        <Loader style={ { width: 50, height: 50 } } />
                                    </div>
                                )}
                                <div>
                                    <button onClick={ this.closePopUp }>Cancel</button>
                                    <button
                                        onClick={ this.cropInitilize }
                                        style={ { marginLeft: '10%' } }
                                    >
                                        Save
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}