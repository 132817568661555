import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'Actions';
import ResetPassword from 'Components/ResetPassword';

function mapStateToProps(state) {
    const { loading,errorElement } = state.resetPassword;
    return { loading,errorElement };
}

const mapDispatchToProps = {
    resetPassword: userActions.resetPassword
}; 

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
