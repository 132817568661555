import AddManageAdminRoles from 'Components/Admin/ManageAdminRoles/Add';
import { connect } from 'react-redux';
import { manageAdminRolesActions } from 'Actions';

const mapDispatchtoProps = {
    addAdminRoles:manageAdminRolesActions.addAdminRoles,
    fetchAllowedModules:manageAdminRolesActions.fetchAllowedModules
 }
 
 function mapStatetoProps(state) {   
   return{ ...state.adminRoles };
 }
 
 export default connect(mapStatetoProps,mapDispatchtoProps)(AddManageAdminRoles);