import React from 'react';

export default class SearchBar extends React.Component {
  handleInputChange = (event) => {
    this.props.dispatchFunction(event);
  };
  render() {
    return (
      <React.Fragment>
        <div>
          <input
            type='text'
            autoComplete='off'
            name={ this.props.name }
            placeholder='Search'
            onChange={ this.handleInputChange }
          />
        </div>
      </React.Fragment>
    );
  }
}
