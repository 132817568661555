import React, { Fragment } from 'react';
import { ReactComponent as Add } from 'assets/images/svg/add.svg';
import AddManageBroadcast from './Add';
import Pagination from 'Components/Pagination';
import EditManageBroadcast from './Edit';
import EditButton from 'Components/Table/EditButton';
import DeleteButton from 'Components/Table/DeleteButton';
import DataTable from 'Container/DataTable/DataTableContainer';
import formUrlParams from 'Helpers/urlform';
import Image from 'Components/Common/Image';

class ManageBroadcast extends React.Component {
  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Messages', // add class="user max" in div
          chooseOption: {
            name: 'messageSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'messages' /* API response */
        },
        {
          title: 'Messages Type',
          chooseOption: {
            name: 'messageTypeSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'messageType' /* API response */
        },
        {
          title: 'Team Name',
          chooseOption: {
            name: 'teamNameSearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'teamName' /* API response */
        },
        {
          title: 'created By',
          chooseOption: {
            name: 'createdBySearch' /* API param name */,
            Component: 'Search',
            dispatchFunction: this.handleSearch
          },
          accessor: 'createdBy',
          cell: rowDetail => {
            const { index, rowIdx, createdBy } = rowDetail;
            return (
              <td key={ index } data-row={ rowIdx }>
                <div className='user'>
                  <Image imageToken={ createdBy.image } />
                  <label>{createdBy.name}</label>
                </div>
              </td>
            );
          }
        },
        {
          title: 'Created Date & Time	',
          accessor: 'createdDate', /* API response */
          chooseOption: {
            name: 'createdDateSearch',
            Component: 'DateTimeRangeComponent',
            dispatchFunction: this.handleDateSearch
          },
          cell: function(rowDetails) {
            const { index, content } = rowDetails;
            return (
              <td key={ index } id='created-date-time'>
               { content && content.replace('T',' ') }
              </td>
            );
          }
        },
        {
          title: 'Broadcasted Date & Time',
          chooseOption: {
            name: 'BroadcastDateSearch',
            Component: 'DateTimeRangeComponent',
            dispatchFunction: this.handleDateSearch
          },
          accessor: 'broadcastedDate', /* API response */
          cell: function(rowDetails) {
            const { index, content } = rowDetails;
            return (
              <td key={ index } id='broadcast-date-time'>
               { content && content.replace('T',' ') }
              </td>
            );
          }
        },
        {
          title: 'Delivered Status',
          chooseOption: {
            name: 'deliveredStatusSearch',
            Component: 'DropDown',
            DropdownText:'All',
            dispatchFunction: this.handleSearch,
            optionRender: this.createDeliveredStatus
          },
          accessor: 'deliveredStatus',
          cell: function(rowDetail) {
            const { content, index } = rowDetail;
            const status =
              content === 1 ? (
                <Fragment>
                  <span className='sent'>Sent</span>
                </Fragment>
              ) : (
                <Fragment>
                  <span className='failed'>Failed</span>
                </Fragment>
              );
            return <td key={ index }>{status}</td>;
          }
        },
        {
          title: 'Status',
          chooseOption: {
            name: 'isActiveSearch',
            Component: 'DropDown',
            DropdownText:'All',
            dispatchFunction: this.handleSearch,
            optionRender: this.handleBroadcastStatus
          },
          accessor: 'isActive',
          cell: function(rowDetail) {
            const { content, index } = rowDetail;
            const broadcastStatus =
              content === 1 ? (
                <Fragment>
                  <span className='active' ></span>Enable
                </Fragment>
              ) : (
                <Fragment>
                  <span className='inactive' ></span>Disable
                </Fragment>
              );
            return <td key={ index }>{broadcastStatus}</td>;
          }
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: rowDetail => {
            const { index, id, rowIdx,profile, ...rest } = rowDetail;
            const permission = profile && profile.Broadcast
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <EditButton
                  id={ id }
                  { ...rest }
                  getActiveEditData={ this.getActiveEditData }
                  editClass='editbroadcast'
                  checkPermission = { permission && permission.edit }
                />
                <DeleteButton
                  remainningRecords={ this.props.responseArray.length }
                  deleteActionListener={ this.props.deleteBroadCast }
                  activeQuery={ this.state.filtered }
                  id={ id }
                  checkPermission = { permission && permission.delete }
                />
              </td>
            );
          }
        }
      ],
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      activeEditData: '',
      addBroadCastMenuStatus: false,
      editBroadCastMenuStatus: false
    };
    this.state = model;
  }

  /**
   * @event componentDidMount
   * @summary API call to fetch broadcast  details
   * @return {Object} return manage broadcast object
   */
  componentDidMount() {
    this.props.fetchBroadCast();
  }

  getActiveEditData = data => {
    this.setState(
      { activeEditData: data, editBroadCastMenuStatus: true },
      () => {
        document.getElementById(data.editClass).classList.add('open');
      }
    );
  };
  handleDateSearch = data => {
    for (const [ key, value ] of Object.entries(data)) {
      this.formUrlParams(key,value)
    }
  };

  createDeliveredStatus = () => {
    const statusArray = [ 'Sent', 'Failed', 'Pending' ];
    return statusArray.map((broadcast, index) => {
      return (
        <option key={ index } value={ index }>
          {broadcast}
        </option>
      );
    });
  };

  handleBroadcastStatus = () => {
    const statusArray = [ 'Disable', 'Enable' ];
    return statusArray.map((broadcast, index) => {
      return (
        <option key={ index } value={ index }>
          {broadcast}
        </option>
      );
    });
  };

  /**
   * @method formUrlParams
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @param {rest} rest - Rest object except name and value
   * @summary Construct the query string based on user selection
   * @return {function} call fetch broadcast user api after change local state
   */

  formUrlParams = (name, value, rest) => {
    const { filtered } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse, ...rest }, () => {
      this.props.fetchBroadCast(this.state.filtered);
    });
  };
  /**
   * @method hanldeAddAdmin
   * @summary open broadcast popup based click event
   * @return {none} undefined
   */

  hanldeAddAdmin = () => {
    this.setState({ addBroadCastMenuStatus: true }, () => {
      document.getElementById('addbroadcast').classList.add('open');
    });
  };

  /**
   * @method setPageLimit
   * @summary set page limit for view data per page
   * @param {name} name - Query string name
   * @param {value} value - Query string value
   * @return { function } formUrlParams
   */

  setPageLimit = (name, value) => {
    this.formUrlParams(name, value, { pageLimit: value });
  };

  /**
   * @method handleSearch
   * @summary set the username and password state
   * @param {event} event - React onChange event
   * @return { function } formUrlParams
   */
  handleSearch = event => {
    const { name, value } = event.target;
    this.formUrlParams(name, value);
  };

  /**
   * @method closeBroadCastMenu
   * @summary close add broadcast popup
   * @return { object } updated manage broadcast state
   */

  closeBroadCastMenu = () => {
    this.setState({ addBroadCastMenuStatus: false, filtered: [] });
  };

  /**
   * @method closeEditBroadCastMenu
   * @summary close edit broadcast popup
   * @return { object } updated manage broadcast state
   */

  closeEditBroadCastMenu = () => {
    this.setState({ editBroadCastMenuStatus: false });
  };

  render() {
    const { addBroadCastMenuStatus, editBroadCastMenuStatus } = this.state;
    const { responseArray, profile, totalRecords, currentPage } = this.props;
    const Permission = profile && profile.Broadcast
    return (
      <React.Fragment>
        <div className='grid'>
          <div className='head'>
            <h2> Manage Broadcast</h2>
            {addBroadCastMenuStatus && (
              <AddManageBroadcast
                closeBroadCastMenu={ this.closeBroadCastMenu }
              />
            )}
            {editBroadCastMenuStatus && (
              <EditManageBroadcast
                { ...this.state.activeEditData }
                closeEditBroadCastMenu={ this.closeEditBroadCastMenu }
              />
            )}
            { Permission && Permission.create && (
              <button className='blue-bbt' onClick={ this.hanldeAddAdmin }>
              <Add /> New Broadcast
            </button>
            )  }
          </div>
          <div className='responsive-table'>
            <DataTable
              className='data-table'
              headers={ this.state.headers }
              data={ responseArray }
              noData='No records!'
            />
          </div>
          <Pagination
            handleClick={ this.formUrlParams }
            totalRecords={ totalRecords }
            initialPage={ currentPage }
            setPageLimit={ this.setPageLimit }
            pageLimit={ this.state.pageLimit }
          />
        </div>
      </React.Fragment>
    );
  }
}
export default ManageBroadcast;
