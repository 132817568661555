import React from 'react';
import { connect } from 'react-redux';
import { settingActions } from 'Actions';
import Settings from 'Components/Settings';

function mapStateToProps(state) {
    const { profile } = state
    return { ...state.settings,
        profile:profile.permission && JSON.parse(profile.permission),
    };
}

const mapDispatchToProps = {
    fetchsettings: settingActions.fetchSettings,
    updatesettings: settingActions.updateSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);