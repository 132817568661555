import React, { Component, Fragment } from 'react';
export default class Popup extends Component{
    render(){
        return(
            <Fragment>
                <div className='profile-container delete-image'>
                <div className='profile-popup-visible'>
                  <div className='popup-controls'>
                    <div className='popup-body'>
                      {this.props.popupText } <br />
                      <div className='buttons'>
                        <button onClick={ this.props.handleYes }>Yes</button>
                        <button onClick={ this.props.handleCancel }>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
        )
    }
}