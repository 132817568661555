import { broadCastConstant } from 'Constants/adminBroadCast.constants';

const initialState = {
  responseArray: [],
  loading: false,
  totalRecords: 0,
  error:false,
  currentPage:1
}

export function broadCast(state = initialState, action={}) {
  const { broadCast } = action;
 
  switch (action.type) {
    case broadCastConstant.BROADCAST_REQUEST:
      return { ...state, loading: true, error:null };
    case broadCastConstant.BROADCAST_GET_SUCCESS:
      return {
        ...state,
        responseArray: (broadCast ) ? [ ...broadCast ] : [],
        totalRecords: action.totalRecords,
        currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
        loading: false
      };
    case broadCastConstant.ADD_BROADCAST_SUCCESS:
      return {
        ...state,                                                       
        responseArray: [ ...state.responseArray, broadCast ],
        loading: false,
        error:false
      };
    case broadCastConstant.UPDATE_BROADCAST_SUCCESS:
      return {
          ...state,
          responseArray: state.responseArray.map(( broadcast ) => {
            if(broadcast.id == broadCast.id) return broadCast;
            return broadcast;
          }),
          loading: false,
          error:false
      }
    case broadCastConstant.DELETE_BROADCAST_SUCCESS:
      return {
          ...state,
          responseArray: state.responseArray.filter(( broadcast ) => {
            return action.updateObject.ids.indexOf(broadcast.id)
          }),
          totalRecords: state.totalRecords - action.updateObject.updateRecords,
          loading: false,
          error:false
      }
    case broadCastConstant.BROADCAST_FAILURE:
      return {
          ...state,
          loading: false,   
          error:true
      } 
      
    default:
      return state;
  }
}
