import React from 'react'
import { connect } from 'react-redux';
import { usersListActions,userConatctsActions } from 'Actions';
import UserDetails from 'Components/Users/UserDetails';

function mapStatetoProps(state){
    const { usersList } = state.usersList
    const { userContactsList } = state.userContactsList
    return{
        usersList:usersList,
        userContactsList:userContactsList
      }
}
const mapDispatchtoProps = {
    fetchUsersList:usersListActions.fetchUsersList,
    fetchUserContacts:userConatctsActions.fetchUserContacts
}

export default connect(mapStatetoProps,mapDispatchtoProps)(UserDetails)