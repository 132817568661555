import { connect } from 'react-redux';
import ReplyFeedback from 'Components/Feedback/ReplyFeedback';
import { FeedbackAction } from 'Actions'

function mapStatetoProps(state){
    const { formLoading, errorElement } = state.feedback
    return{
        formLoading,
        errorElement
    } 
}
const mapDispatchtoProps = {
    updateFeedback: FeedbackAction.updateFeedback,
}

export default connect(mapStatetoProps,mapDispatchtoProps)(ReplyFeedback)