import { feedbackConstants } from 'Constants/feedback.constants';
import Request from 'Services/request';
import { alterQueryRequest, findCurrentPageRequest, queryParser } from 'Helpers/urlform'
import toastr from 'toastr';

const requestFeedback = feedback => ({
    type:  feedbackConstants.FEEDBACK_REQUEST,
    feedback
})

const successFeedback = feedbackObj => ({
    type:  feedbackConstants.FEEDBACK_GET_SUCCESS,
    feedback: feedbackObj.data,
    totalRecords: feedbackObj.count,
    currentPage: feedbackObj.currentPage
})

const successUpdateFeedback = feedbackObj => ({
    type: feedbackConstants.UPDATE_FEEDBACK_SUCCESS,
    feedback: feedbackObj
})

const failureFeedback = error => ({
    type:  feedbackConstants.FEEDBACK_FAILURE,
    error
})

const failureSaveFeedback = error => ({
    type: feedbackConstants.FEEDBACK_SAVE_FORM_FAILURE,
    error
})

function fetchFeedback(SearchArray) {
    let url = '/feedback';
    if (SearchArray && SearchArray.length > 0) {
        url = queryParser(url, SearchArray);
    }
    const currentPage = findCurrentPageRequest(SearchArray);
    return async (dispatch) => {
        dispatch(requestFeedback({}));
        await Request.get(url, {}, { 'Authorization': `${ localStorage.getItem('user') }` })
            .then((response) => {
                const { data, count } = response;
                dispatch(successFeedback({ data, count, currentPage }));
            })
            .catch((error) => {
                toastr.remove();
                toastr.error(error.toString());
                dispatch(failureFeedback(error.toString()));
            });
    };
}

function updateFeedback(feedbackObj, SearchArray) {
    const { id, ...rest } = feedbackObj
    return (dispatch) => {
        const message  = 'Success';
        //dispatch(successUpdateFeedback(feedbackObj));
        //dispatch(fetchFeedback(SearchArray))
        toastr.remove();
        toastr.success(message);
        /* Request.update(`/feedback/${ id }`,
            { ...rest },
            { 'Authorization': `${ localStorage.getItem('user') }` })
            .then((response) => {
                const { message } = response;
                dispatch(successUpdateFeedback(feedbackObj));
                dispatch(fetchFeedback(SearchArray))
                toastr.remove();
                toastr.success(message);
            })
            .catch((error) => {
                dispatch(failureSaveFeedback(error.toString()));
            }); */
    };
}

function deleteFeedback(id, SearchArray, remainningRecords, currentPage) {
    const updateSearchArray = alterQueryRequest(SearchArray, remainningRecords, currentPage);
    return (dispatch) => {
        dispatch(requestFeedback({}));
        Request.remove(`/feedback?id=${ id }`,
            {},
            { 'Authorization': `${ localStorage.getItem('user') }` })
            .then((response) => {
                const { message } = response;
                dispatch(fetchFeedback(updateSearchArray));
                toastr.remove();
                toastr.success(message);
            })
            .catch((error) => {
                toastr.remove();
                toastr.error(error.toString());
                dispatch(failureFeedback(error.toString()));
            });
    }
}

const requestFeedbackExportData = exportData => ({
    type: feedbackConstants.EXPORT_FEEDBACK_REQUEST,
    exportData
})

const successFeedbackExportData = exportDataObj => ({
    type: feedbackConstants.EXPORT_FEEDBACK_SUCCESS,
    exportData: exportDataObj.data,
    totalRecords: exportDataObj.count
})

const failureFeedbackExportData = error => ({
    type: feedbackConstants.EXPORT_FEEDBACK_FAILURE,
    error
})

function exportFeedback() {
    let url = '/feedback';
    return async (dispatch) => {
        dispatch(requestFeedbackExportData({}));
        await Request.get(url, {}, { 'Authorization': `${ localStorage.getItem('user') }` })
            .then(response => {
                const { data, count = 10 } = response;
                dispatch(successFeedbackExportData({ data, count }));
            })
            .catch((error) => {
                toastr.remove();
                toastr.error(error.toString());
                dispatch(failureFeedbackExportData(error.toString()));
            });
    };
}

export const FeedbackAction = {
    fetchFeedback,
    updateFeedback,
    deleteFeedback,
    exportFeedback,
}