import { manageAdminConstant } from 'Constants/';

const initialState = {
  responseArray: [],
  loading: false,
  totalRecords: 0,
  error:false,
  currentPage:1,
  formLoading:false,
  errorElement:'',
  editErrorElement:''
}

export function manageAdmin(state = initialState, action={}) {
  const { manageAdmin } = action;
 
  switch (action.type) {
    case manageAdminConstant.ADMIN_USER_REQUEST:
      return { ...state, loading: true, error:null };  
    case manageAdminConstant.ADMIN_USER_FORM_REQUEST:
     return { ...state, loading: true, formLoading: true, error:true,errorElement:'',editErrorElement:'' };
    case manageAdminConstant.ADMIN_USER_GET_SUCCESS:
      return {
        ...state,
        responseArray: (manageAdmin ) ? [ ...manageAdmin ] : [],
        totalRecords: action.totalRecords,
        currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
        loading: false,
        formLoading:false,
        errorElement:''
      };
    case manageAdminConstant.ADD_ADMIN_USER_SUCCESS:
      return {
        ...state,                                                       
        responseArray: [ ...state.responseArray, manageAdmin ],
        loading: false,
        error:false,
        formLoading:false,
        errorElement:''
      };
    case manageAdminConstant.UPDATE_ADMIN_USER_SUCCESS:
      return {
          ...state,
          responseArray: state.responseArray.map(( admin ) => {
            if(admin.id == manageAdmin.id) return manageAdmin;
            return admin;
          }),
          loading: false,
          error:false,
          formLoading:false,
          editErrorElement:''
      }
    case manageAdminConstant.DELETE_ADMIN_USER_SUCCESS:
      return {
          ...state,
          responseArray: state.responseArray.filter(( admin ) => {
            return action.updateObject.ids.indexOf(admin.id)
          }),
          totalRecords: state.totalRecords - action.updateObject.updateRecords,
          loading: false,
          error:false
      }
    case manageAdminConstant.ADMIN_USER_FAILURE:
      return {
          ...state,
          loading: false,   
          error:true,
      }
    
    case manageAdminConstant.ADMIN_USER_FORM_FAILURE:
      return {
          ...state,
          loading: false,   
          error:true,
          formLoading:false,
          errorElement:action.error

      } 
    case manageAdminConstant.ADMIN_USER_EDIT_FORM_FAILURE:
      return {
            ...state,
            loading: false,   
            error:true,
            formLoading:false,
            editErrorElement:action.error
  
        }
      
    default:
      return state;
  }
}
