import React from 'react';

export default function svggenerator(svgimageDetails) {
    const { width, height, viewBox, className, d, transform } = svgimageDetails
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={ width }
            height={ height }
            viewBox={ viewBox } >
            <path className={ className } d={ d } transform={ transform } />
        </svg>
    )
}