import { usersListConstants } from 'Constants/usersList.constants'
import Request from 'Services/request.js';
import toastr from 'toastr';
import { findCurrentPageRequest,queryParser } from 'Helpers/urlform'

const requestUserList = usersList =>({
    type:usersListConstants.USERS_LIST_REQUEST,
    usersList
})
const sucessUsersList = usersListObject => ({
    type:usersListConstants.USERS_LIST_SUCCESS,
    usersListObject:usersListObject.data,
    totalRecords:usersListObject.count,
    currentPage:usersListObject.currentPage
})
const failureUserList = error => ({
    type:usersListConstants.USERS_LIST_FAILURE,
    error
})

function fetchUsersList(SearchArray) {
  let url = '/users' ;
  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  }  
  const currentPage = findCurrentPageRequest(SearchArray);
  return async (dispatch) => {
    dispatch(requestUserList({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data,count } = response;
        dispatch(sucessUsersList({ data, count, currentPage }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureUserList(error.toString()));
      });
  };
}

export const usersListActions={
    fetchUsersList
}