import { validator } from 'Helpers/validator';
const validatorInstance = validator.getInstance();

export default function validation(e, formId) {
    const superparent = e.target.parentElement;
    !e.target.value && superparent.classList.remove('top');
    if (!e.target.dataset.validation || !e.target.dataset.check) {
       return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    const inputDetails = {
      formId,
      elementName: e.target.name,
      elementValue: e.target.value,
      message: e.target.dataset.error
    }

    validatorInstance.validate(inputDetails, inputRules);
}