import { userContacts } from 'Constants'
import Request from 'Services/request.js';
import toastr from 'toastr';
import { findCurrentPageRequest,queryParser } from 'Helpers/urlform'

const requestUserContacts = userContact =>({
  type:userContacts.USER_CONTACTS_REQUEST,
  userContact
})
const sucessUserContacts = userContactObject => ({
  type:userContacts.USER_CONTACTS_SUCCESS,
  userContactObject:userContactObject.data,
  totalRecords:userContactObject.count,
  currentPage:userContactObject.currentPage
})
const failureUserContacts = error => ({
  type:userContacts.USERS_CONTACTS_FAILURE,
  error
})

function fetchUserContacts(SearchArray) {
  let url = '/users/contacts' ;
  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  }  
  const currentPage = findCurrentPageRequest(SearchArray);
  return async (dispatch) => {
    dispatch(requestUserContacts({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data,count } = response;
        dispatch(sucessUserContacts({ data, count, currentPage }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureUserContacts(error.toString()));
      });
  };
}

export const userConatctsActions={
    fetchUserContacts
}