import React, { Component, Fragment } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

export default class DateTimeRangeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      startDate: '',
      endDate: '',
      error:false
    };
  }

  dateChange = ({ startDate, endDate }) => {
    const fromDate = startDate ? startDate.format('YYYY-MM-DD'+' '+'00:00:00') : '';
    const toDate = endDate ? endDate.format('YYYY-MM-DD'+' '+'23:59:59') : '';
    this.setState({ startDate, endDate },()=>{ 
      if((startDate && endDate) || (!startDate && !endDate)){
      this.props.dispatchFunction({ fromDate,toDate })
      }
     });
  };

  focusChange = focusedInput => {
    this.setState({ focusedInput },()=>{
    if(this.state.focusedInput == null){
      this.setState({ error:true })
    }else{
      this.setState({ error:false });
    }
  });
  };

  render() {
    const { startDate,endDate,error } = this.state
    return (
      <Fragment>
      <DateRangePicker
        startDate={ this.state.startDate }
        startDateId="user_start_date_id" 
        endDateId="user_end_date_id" 
        endDate={ this.state.endDate }
        onDatesChange={ this.dateChange }
        focusedInput={ this.state.focusedInput }
        onFocusChange={ this.focusChange }
        isOutsideRange={ () => false }
        showClearDates='true'
        displayFormat={ () => 'MMM DD, YYYY' }
        minimumNights={ 0 }
        numberOfMonths={ 1 }
      />
      { ( (error &&!startDate && endDate) || (error && startDate && !endDate)) && <span className='error'>Please select valid dates</span> }
      </Fragment>
    )
  }
}