import validatorsRules from './validatorsRules'

class validator {

    validate = (elementObject, validateArray) => {
        return validateArray.reduce((errorObjectArray, rule) => {
            const errorObject = this.regexValidation(elementObject, rule);     
            return (errorObject) ? [ ...errorObjectArray, errorObject ] : errorObjectArray;
        }, []).map(errorObject => {
           
            this.createErrorElement(errorObject, elementObject);
            return errorObject;
        });
    }

    removeErrorElement = (errorId) => {
        const elem = document.getElementById(errorId);
        if (elem) elem.parentNode.removeChild(elem);
    }

    createErrorElement = (errorObject, elementObject) => {
        const { errorId, message } = errorObject;
        const span = document.createElement('span');
        span.id = errorId;
        span.classList.add('error');
        span.innerHTML = message;
        const { formId, elementName } = elementObject;
        const errorShow = document.getElementById(formId).querySelector(`[name="${ elementName }"]`)
        this.insertAfter(span, errorShow);
    }

    regexValidation = (elementObject, rule) => { 
        const { elementName, elementValue,updateMessage, validateInput } = elementObject;
        const errorId = `${ elementName }-${ rule }`;
        const addtionalParam = (updateMessage) ? {
            validateInput, updateMessage,
        } : null

        this.removeErrorElement(errorId);
        if ((!validatorsRules[ rule ]) || (validatorsRules[ rule ] &&
            validatorsRules[ rule ].test.test(elementValue, addtionalParam, elementName))) {       
            return null;
        }
       const message =  ( validatorsRules[ rule ].update ) ? validatorsRules[ rule ].messageupdate : elementObject.message
        return {
            errorId,
            message: (message) ? message : validatorsRules[ rule ].message
        }
    }

    checkBeforeSubmit = (formId) => {
        const formelement = document.getElementById(formId);
        [ ...formelement.elements ].forEach(element => {
            const elementObject = {
                formId,
                elementName: element.name,
                elementValue: element.value,
                message:element.dataset.error
            }  
             
            if (element.dataset && element.dataset.validation) {
                const inputRules = element.dataset.validation.split(',');
                this.validate(elementObject, inputRules);
            }
          
        })
        return formelement.getElementsByClassName('error')[ 0 ];
    }

    insertAfter = (el, referenceNode) => {
        if(referenceNode) {
            const checkParentWrapperExist = referenceNode.parentNode.closest('.input-Wrapper')
            if(checkParentWrapperExist){
                checkParentWrapperExist.appendChild(el)
            } else {
                referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
            }
        }      
    }

}

var validatorInstance = (function () {
    function validatorInstance() {
    }
    validatorInstance.getInstance = function () {
        if (!this.instance) {
            this.instance = new validator();
            return this.instance;
        }
        return this.instance
    };
    return validatorInstance;
}());

export { validatorInstance as validator };