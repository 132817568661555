import React, { Component, Fragment } from 'react';
import { ReactComponent as Closephoto } from 'assets/images/svg/closephoto.svg';
import { ReactComponent as Search } from 'assets/images/svg/search-icon.svg';
import Image from 'Components/Common/Image';
import validation from 'Helpers/Validation';
import { validator } from 'Helpers/validator';
import DragUpload from 'Components/Common/Dragupload';
import SearchBar from 'Components/Common/SearchBar';
import formUrlParams from 'Helpers/urlform';
import DataTable from 'Components/Table/dataTable';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

export default class ReplyFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      description: '',
      loading: false,
      filtered: [],
      showError: false,
      errorElement: '',
    };
    this.validator = validator.getInstance();
  }

  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */

  validation = (e) => {
    validation(e, 'add-team-form');
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [ name ]: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errorElement !== this.props.errorElement) {
      this.setState({ errorElement: this.props.errorElement });
    }
  }

  closeForm = () => {
    this.props.closeForm();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formId = event.target.id;
    const { id, description } = this.state;
    this.setState({ showError: false }, () => {
      if (formId && !this.validator.checkBeforeSubmit(formId) && this.state.showError !== true) {
        if(this.props.id !== 0) {
          return this.props.updateFeedback( { description, id }, this.props.filterVal);
        }
      }
    });
  };  

  render() {
    const { description, errorElement, id } = this.state;
    const { formLoading } = this.props;
    return (
      <Fragment>
        <div className='addbroadcast saveFeedback open'>
          <div className='overlay' onClick={ this.closeForm }></div>
          <div className='addmgnt'>
            <LoadingOverlay
              active={ formLoading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center',
                },
              } }
              text='Please wait...'
            ></LoadingOverlay>
            <div className='head'>
              <h2> Reply Feedback </h2>
            </div>
            <div className='form'>
              <form
                id='reply-feedback-form'
                autoComplete='off'
                onSubmit={ this.handleSubmit }
              >
                <div className='formgroup new'>
                  <label>
                    Description<sup>*</sup>
                  </label>
                  <textarea
                    name='description'
                    value={ description }
                    data-validation={ [ 'requiredfield' ] }
                    placeholder='Enter description here!'
                    data-error='Enter description'
                    onBlur={ this.validation }
                    onChange={ this.handleChange }
                  >
                  </textarea>
                </div>
                {errorElement && (
                  <span className='errorElement'>{errorElement}</span>
                )}
                <div className='buttons'>
                  <div>
                    <button
                      type='reset'
                      onClick={ this.closeForm }
                      className='plain-bbt'
                    >
                      Cancel
                    </button>
                    <button className='blue-bbt'>Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
