import React, { Component, Fragment } from 'react';
import DropDown from 'Components/Common/DropDown';
import { validator } from 'Helpers/validator';
import validation from 'Helpers/Validation';
import LoadingOverlay from 'react-loading-overlay';

class EditManageAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name: '',
      userName: '',
      emailId: '',
      roleName: '',
      isActive: 0,
      editErrorElement:''
    };
    this.validator = validator.getInstance();
  }

   /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */

  validation = e => {
    validation(e, 'edit-admin-form');
  };
  handlenameChange = event => {
    const { name, value } = event.target;
    this.setState({ [ name ]: value.toLowerCase() });
  }
  
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  };
 
  handleAccessLevelChange = e => {
    const value = e.target.options[ e.target.selectedIndex ].dataset.rolename;
    const { name } = e.target;
    this.setState({ [ name ]: value });
  };

  handleCheckBoxChange = () => {
    this.setState({ isActive: !this.state.isActive & 1 });
  };

  handleSubmit = event => {
    event.preventDefault();
    const {
      id,
      emailId,
      isActive,
      name,
      roleName,
      userName
    } = this.state;
    const elemnet = this.props.adminRoles.filter((ele=> {
      return ele.roleName === roleName
      })).shift(0)

    if (event.target.id && !this.validator.checkBeforeSubmit(event.target.id)) {
      this.props.updateAdminUser({
        id,
        emailId,
        roleId: elemnet.id,
        isActive,
        name,
        roleName,
        userName
      });
    }
  };
 
  createAdminRoles = () => {
    const { adminRoles } = this.props;
    if (!adminRoles || adminRoles.length <= 0) return null;
    return adminRoles.map((admin, index) => {
      return (
        <option key={ index } data-rolename={ admin.roleName } value={ admin.id }>
          {admin.roleName}
        </option>
      );
    });
  };

  closeEditAdmin = () => {
    this.props.closeEditAdmin();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      const { id, emailId, isActive, name, roleName, userName } = props;
      return {
        id,
        name,
        emailId,
        isActive,
        roleName,
        userName
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error) {
      if (this.props.error !== true && this.props.editErrorElement === ''){
        this.closeEditAdmin();
      }
    }
    if (prevProps.editErrorElement !== this.props.editErrorElement) {
      this.setState({ editErrorElement:this.props.editErrorElement })
    }
  }

  render() {
    const { name, userName, emailId, isActive,editErrorElement } = this.state;
    const { roleId,formLoading } = this.props;
    return (
      <Fragment>
        <div className='addadmin' id='editadmin'>
          <div className='overlay' onClick={ this.closeEditAdmin }></div>
          <div className='addmgnt'>
          <LoadingOverlay
            active={ formLoading }
            spinner
            styles={ {
              wrapper: {
                width: '100%',
                textAlign: '-webkit-center'
              }
            } }
            text='Please wait...'
          ></LoadingOverlay>
            <div className='head'>
              <h2>Edit Admin</h2>
              <div className='tooglebbt'>
                <div className='toogle' name='status' id='admin-active-toggle'>
                  <span> (Inactive) </span>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      onChange={ this.handleCheckBoxChange }
                      checked={ !!+isActive }
                    />
                    <span className='slider round'></span>
                  </label>
                  <span>(active)</span>
                </div>
              </div>
            </div>
            <form id='edit-admin-form' autoComplete='off' onSubmit={ this.handleSubmit }>
              <div className='formgroup'>
                <label>
                  Name<sup>*</sup>
                </label>
                <input
                  type='text'
                  name='name'
                  value={ name }
                  maxLength='25'
                  data-validation={ [ 'user' ] }
                  data-error='Enter your name'
                  placeholder='Enter name here!'
                  onBlur={ this.validation }
                  onChange={ this.handleChange }
                />
              </div>
              <div className='formgroup'>
                <label>Username</label>
                <input
                  type='text'
                  placeholder='Enter user name here!'
                  onBlur={ this.validation }
                  data-validation={ [ 'username' ] }
                  name='userName'
                  value={ userName }
                  onChange={ this.handlenameChange }
                  maxLength='16'
                />
              </div>
              <div className='formgroup'>
                <label>
                  Email<sup>*</sup>
                </label>
                <input
                  type='text'
                  placeholder='Enter email address here!'
                  name='emailId'
                  value={ emailId }
                  onChange={ this.handleChange }
                  data-validation={ [ 'email' ] }
                  onBlur={ this.validation }
                />
              </div>
              <div className='formgroup'>
                <label>
                  Access Level<sup>*</sup>
                </label>
                <DropDown
                  name='roleName'
                  DropdownText='Select'
                  selectedOption={ roleId }
                  requiredfield='true'
                  dispatchFunction={ this.handleAccessLevelChange }
                  optionRender={ this.createAdminRoles }
                />
              </div>
              { editErrorElement !== '' && 
                    <span className='errorElement'>{ editErrorElement }</span> }
              <div className='buttons'>
                <div>
                  <button
                    type='reset'
                    onClick={ this.closeEditAdmin }
                    className='plain-bbt'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='blue-bbt'>
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditManageAdmin;
