import { profileConstants } from 'Constants';
import Request from 'Services/request';
import toastr from 'toastr';
import { medaiHost } from 'Helpers/apiurl';
import { history } from 'Helpers/history';

export const profileActions = {
  updateProfile,
  fetchProfile,
  updateProfileImage
};

function updateProfileImage( fileToken) {
  return (dispatch) => {
    dispatch(request({}));
    Request.update(`/profile`,
        { image:fileToken },
        { 'Authorization': `${ localStorage.getItem('user') }` })
      .then(({ data }) => {
        const imageUrl = `${ medaiHost }/${ fileToken }?mf=${ localStorage.getItem('user') }`
        dispatch(success({ image:fileToken,imageUrl }));
        toastr.remove();
        toastr.success('Profile image updated successfully.');
      })
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request(profile) { return { type: profileConstants.PROFILE_REQUEST, profile }; }
  function success(profile) { return { type: profileConstants.PROFILE_UPDATE_SUCCESS, profile }; }
  function failure(error) { return { type: profileConstants.PROFILE_FAILURE, error }; }
}

function fetchProfile() {
  return (dispatch) => {
    dispatch(request({}));
    Request.get('/profile', {},
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { data } = response;
        const profileDetails = data.shift();
        const imageUrl = `${ medaiHost }/${ profileDetails.image }?mf=${ localStorage.getItem('user') }`
        const updateProfileObject = {
          ...profileDetails,
          imageUrl
        }
        dispatch(success(updateProfileObject));
      })
      .catch((error) => {
        if(error == 'Profile Not Found'){
          localStorage.removeItem('user');
          history.push('/');
        }else{
        toastr.remove();
        localStorage.getItem('user') && toastr.error(error.toString());
        dispatch(failure(error.toString()));
        }
      });
  };

  function request(profile) { return { type: profileConstants.PROFILE_REQUEST, profile }; }
  function success(profile) { return { type: profileConstants.PROFILE_GET_SUCCESS, profile }; }
  function failure(error) { return { type: profileConstants.PROFILE_FAILURE, error }; }
}

function updateProfileRequest(profileObject) {
  return Request.update(`/profile`,
    { ...profileObject },
    { 'Authorization': `${ localStorage.getItem('user') }` })
}

function updateProfile(profileObject) {
  return (dispatch) => {
    dispatch(request({}));

    updateProfileRequest(profileObject)
      .then((response) => {
        const { message } = response;
        dispatch(fetchProfile());
        toastr.remove();
        toastr.success(message);
      })
      .catch((error) => {
        toastr.remove();
        toastr.error(error.toString());
        dispatch(failure(error.toString()));
      });
  };

  function request(profile) { return { type: profileConstants.PROFILE_REQUEST, profile }; }
  function failure(error) { return { type: profileConstants.PROFILE_FAILURE, error }; }
}
