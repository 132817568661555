import React from 'react';
import { validator } from 'Helpers/validator';
import LoadingOverlay from 'react-loading-overlay';
import labeltop from 'Helpers/labelTop';

/**
 * @file Settings Component
 * User can view and update their settings
 * @module 5.Settings
 * @extends React.Component
 */
export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      mailDomain: '',
      adminUser: '',
      audioLimit: '',
      videoLimit: '',
      audioType: '',
      videoType: '',
      mailLogin: 'no'
    };
    this.validator = validator.getInstance();
  }

  /**
   * @event componentDidMount
   * @summary API call to fetch settings
   * @return {Object} return setting object
   */
  componentDidMount() {
    this.props.fetchsettings();
  }

  /**
   * @event getDerivedStateFromProps
   * @summary update the local setting state based on props
   * @return {Object|null } return setting state or null
   */

  static getDerivedStateFromProps(props, state) {
    const {
      id,
      mailDomain,
      adminUser,
      audioLimit,
      videoLimit,
      audioType,
      videoType,
      mailLogin
    } = props;
    if (props.id !== state.id) {
      return {
        id,
        mailDomain,
        adminUser,
        audioLimit,
        videoLimit,
        mailLogin,
        audioType,
        videoType
      };
    }
    return null;
  }
  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {event} event - React onBlur event
   * @return {none} undefined
   */
  // validation = e => {
  //   validation(e, 'settingform');
  // };
  validation = e => {
    const superparent = e.target.parentElement;
    !e.target.value && superparent.classList.remove('top');
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    let elementObject = {
      formId: 'settingform',
      elementName: e.target.name,
      message: e.target.dataset.error,
      elementValue: e.target.value,
    };

    if (e.target.name == 'audioType') {
      const { audioLimit, audioType } = this.state;
      elementObject = {
        ...elementObject,
        updateMessage: 'Enter valid audio limit',
        validateInput: [ audioLimit, audioType ]
      };
    }
    if (e.target.name == 'videoType') {
      const { videoLimit, videoType } = this.state;
      elementObject = {
        ...elementObject,
        updateMessage: 'Enter valid video limit',
        validateInput: [ videoLimit, videoType ]
      };
    }
    this.validator.validate(elementObject, inputRules);
  };

  /**
   * @method handleChange
   * @summary set the state based on input type change action
   * @param {event} event - React onChange event
   * @return {Object} return setting object
   */

  handleOnchange = event => {
    event.persist();
    this.setState(
      {
        [ event.target.name ]: event.target.value
      },
      () => (!event.target.value ? this.labeltop(event) : null)
    );
  };
  handleTypechange = event => {
    this.setState(
      {
        [ event.target.name ]: event.target.value
      }
    );
  };
  handleLimitOnchange = event => {
    event.persist();
    const val = event.target.value.split(''); 
    if(val.length > 6){
      const slicedVal= val.slice(0, 6)
      const filteredVal = slicedVal.join('')
      this.setState(
        {
          [ event.target.name ]: filteredVal
        },
        () => (!filteredVal ? this.labeltop(event) : null)
      );
    }else{
    this.setState(
      {
        [ event.target.name ]: parseInt(event.target.value)
      },
      () => (!event.target.value ? this.labeltop(event) : null)
    )
    }
  };
  /**
   * @method handleValidSubmit
   * @summary submit the setting form after the validation
   * @param {event} event - React onSubmit event
   * @return {Object} return setting object
   */
  handleValidSubmit = event => {
    event.preventDefault();
    if( (this.validator.checkBeforeSubmit(event.target.id) ||
      this.state.audioType === '') &&  (this.validator.checkBeforeSubmit(event.target.id) || this.state.videoType === '')){
        const e = new Event('blur');
        const element1 = document.getElementById('audioType');
        element1.setAttribute('data-check', true);
        element1.dispatchEvent(e);
        element1.removeAttribute('data-check');
        const element2 = document.getElementById('videoType');
        element2.setAttribute('data-check', true);
        element2.dispatchEvent(e);
        element2.removeAttribute('data-check');
        return;
      }else if (
        this.validator.checkBeforeSubmit(event.target.id) ||
        this.state.audioType === ''
      ) {
        const e = new Event('blur');
        const element = document.getElementById('audioType');
        element.setAttribute('data-check', true);
        element.dispatchEvent(e);
        element.removeAttribute('data-check');
        return;
      }
      else if (
        this.validator.checkBeforeSubmit(event.target.id) ||
        this.state.videoType === ''
      ) {
        const ele = new Event('blur');
        const elementVideo = document.getElementById('videoType');
        elementVideo.setAttribute('data-check', true);
        elementVideo.dispatchEvent(ele);
        elementVideo.removeAttribute('data-check');
        return;
      } 
    if (event.target.id && !this.validator.checkBeforeSubmit(event.target.id)) {
      const {
        id,
        mailDomain,
        adminUser,
        audioLimit,
        videoLimit,
        mailLogin,
        audioType,
        videoType
      } = this.state;
      this.props.updatesettings({
        id,
        mailDomain,
        adminUser,
        audioLimit,
        videoLimit,
        mailLogin,
        audioType,
        videoType
      });
  }
  };
  labeltop(e) {
    const superparent = e.target.parentElement;
    superparent.classList.add('top');
  }
  handleType = event => {
    event.preventDefault();
    this.setState({ [ event.target.name ]: event.target.value });
  };
  isNumber = (evt) =>  {
    var iKeyCode = (evt.which) ? evt.which : evt.keyCode
    if (iKeyCode >= 48 && iKeyCode <= 57){
      return true;
    } else {
      evt.preventDefault();
        return false;
    }

} 
  render() {
    const {
      mailDomain,
      adminUser,
      audioLimit,
      videoLimit,
      audioType,
      videoType
    } = this.state;
    const { loading,profile } = this.props;
    const Permission = profile && profile[ 'Settings' ] && profile[ 'Settings' ].edit;
    return (
      <React.Fragment>
        <div className='settings'>
          <h2>Settings</h2>
          <div className={ Permission ? 'main-block loading-parent' :'main-block loading-parent settings-container' }>
            <LoadingOverlay
              active={ loading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center'
                }
              } }
              text='Please wait...'
            ></LoadingOverlay>
            <h3>General Settings</h3>
            <form
              id='settingform'
              onSubmit={ this.handleValidSubmit }
              noValidate
              autoComplete='off'
            >
              <div className='group'>
                <div
                  className={
                    mailDomain !== '' ? 'form-group top' : 'form-group'
                  }
                >
                  <label>
                    Mail Domain<sup>*</sup>
                  </label>
                  <input
                    name='mailDomain'
                    onBlur={ this.validation }
                    onChange={ this.handleOnchange }
                    data-validation={ [ 'domain' ] }
                    type='text'
                    onFocus={ labeltop }
                    value={ mailDomain }
                  />
                </div>
                <div
                  className={ adminUser !== '' ? 'form-group top' : 'form-group' }
                >
                  <label>
                    Admin User<sup>*</sup>
                  </label>
                  <input
                    name='adminUser'
                    onBlur={ this.validation }
                    onChange={ this.handleOnchange }
                    data-validation={ [ 'adminuser' ] }
                    type='text'
                    maxLength='25'
                    onFocus={ labeltop }
                    value={ adminUser }
                  />
                </div>
                <div className="media-field">
                <div
                  className={
                    audioLimit !== '' ? 'form-group top' : 'form-group'
                  }
                >
                  <label>
                    Audio Limit<sup>*</sup>
                  </label>

                  <input
                    name='audioLimit'
                    onBlur={ this.validation }
                    data-validation={ [ 'greaterthanZero' ] }
                    onChange={ this.handleLimitOnchange }
                    type='number'
                    maxLength={ 1 }
                    onFocus={ labeltop }
                    value={ audioLimit }
                    onKeyPress={ (e) => { this.isNumber(e) } }
                  />
                    </div>
                  <select
                    className='time-format'
                    name='audioType'
                    value={ audioType }
                    data-check={ true }
                    onBlur={ this.validation }
                    onChange={ this.handleTypechange }
                    data-validation={ [ 'requiredformat' ] }
                    id='audioType'
                  >
                    <option value=''>Select</option>
                    <option value='sec'>Seconds</option>
                    <option value='min'>Minutes</option>
                    <option value='hrs'>Hour</option>
                  </select>
                
                </div>
                <div className="media-field">
                <div
                  className={
                    videoLimit !== '' ? 'form-group top' : 'form-group'
                  }
                >
                  <label>
                    Video Limit<sup>*</sup>
                  </label>
                  <input
                    name='videoLimit'
                    onBlur={ this.validation }
                    data-validation={ [ 'greaterthanZero' ] }
                    onChange={ this.handleLimitOnchange }
                    type='number'
                    onFocus={ labeltop }
                    value={ videoLimit }
                    onKeyPress={ (e) => { this.isNumber(e) } }
                  />
                  </div>
                  <select
                    className='time-format'
                    name='videoType'
                    value={ videoType }
                    data-check={ true }
                    onBlur={ this.validation }
                    onChange={ this.handleTypechange }
                    data-validation={ [ 'requiredvideoformat' ] }
                    id='videoType'
                  >
                    <option value=''>Select</option>
                    <option value='sec'>Seconds</option>
                    <option value='min'>Minutes</option>
                    <option value='hrs'>Hour</option>
                  </select>
                 
                </div>
                {/* <div className='form-group radio'>
                  <label>Mail Login</label>
                  <div className='check-box'>
                    <div className='checkbox'>
                      <input
                        name='mailLogin'
                        onChange={ this.handleOnchange }
                        type='radio'
                        id='checkbox1'
                        checked={ mailLogin === 'yes' }
                        value='yes'
                      />
                      <label name='mailLogin' htmlFor='checkbox1'>
                        <span>Yes</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        name='mailLogin'
                        onChange={ this.handleOnchange }
                        type='radio'
                        id='checkbox'
                        checked={ mailLogin === 'no' }
                        value='no'
                      />
                      <label name='mailLogin' htmlFor='checkbox'>
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
              <button type='submit' className='blue-bbt'>
                Update
              </button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
