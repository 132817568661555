import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class DatePickerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
          date: new Date()
        }
    }

    handleChange = date => {
        this.setState({ date })
    };

    render() {
        const { date } = this.state;
        const { minDate, name } = this.props
      return (
          <DatePicker
            name = { name || null }
            minDate = { minDate || null }
            selected={ date }
            onChange={ this.handleChange }
        />
      )
    }
  }