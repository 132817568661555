import React, { Component } from 'react';
import { validator } from 'Helpers/validator';
import labeltop from 'Helpers/labelTop';
import EyeIcon from 'Components/Common/eyeIcon';
import Logoimg from 'assets/images/logo.png';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

/**
 * @file
 * User can reset their password with existing password
 * @module 2.ChangePassword
 * @extends React.Component
 */

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      errorElement:''
    };

    this.validator = validator.getInstance();
  }
  componentDidUpdate(prevProps){
    if (this.props.errorElement !== prevProps.errorElement) {
       this.setState({ errorElement:this.props.errorElement })
    }
  }
  /**
   * @method validation
   * @summary validate the inputs based on validation rules
   * @param {e} event - React onBlur event
   * @return {none} undefined
   */

  validation = e => {
    const superparent = e.target.parentElement;
    !e.target.value && superparent.classList.remove('top');
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    let elementObject = {
      formId: 'changepassword',
      elementName: e.target.name,
      elementValue: e.target.value,
      message: e.target.dataset.error
    };

    if (e.target.name == 'confirmPassword') {
      const { newPassword, confirmPassword } = this.state;
      elementObject = {
        ...elementObject,
        updateMessage: 'Confirm and new password must be same',
        validateInput: [ newPassword, confirmPassword ]
      };
    }
    this.validator.validate(elementObject, inputRules);
  };

  /**
   * @method handlePasswordChange
   * @summary set the currentPassword, newPassword and confirmPassword state
   * @param {event} event - React onChange event
   * @return {object} update local component state
   */

  handlePasswordChange = event => {
    this.setState({ [ event.target.name ]: event.target.value });
  };

  toggleIcon = event => {
    event.preventDefault();
    const name = event.target.closest('i').getAttribute('name');
    this.setState({ [ name ]: !this.state[ name ] });
  };

  /**
   * @method handleFormSubmit
   * @summary submit the change password form after the validation
   * @param {event} event - React onSubmit event
   * @return {function} call the redux action
   */

  handleFormSubmit = event => {
    event.preventDefault();
    const formId = event.target.id
    this.setState({ errorElement: null },()=>{
    if (
      this.validator.checkBeforeSubmit(formId) ||
      this.state.newPassword !== this.state.confirmPassword
    ) {
      const e = new Event('blur');
      const element = document.getElementById('confirmPassword');
      element.setAttribute('data-check', true);
      element.dispatchEvent(e);
      element.removeAttribute('data-check');
      return;
    }
    this.props.changepassword(this.state);
  });  
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      showConfirmPassword,
      showNewPassword,
      showCurrentPassword,
      errorElement
    } = this.state;
    const { loading } = this.props;

    return (
      <React.Fragment>
        <div className='myprofile'>
          <h2>Change Password</h2>
          <div className='main-block loading-parent'>
            <LoadingOverlay
              active={ loading }
              spinner
              styles={ {
                wrapper: {
                  width: '100%',
                  textAlign: '-webkit-center'
                }
              } }
              text='Please wait...'
            />
            <div className='logo'>
              <img src={ Logoimg } />
            </div>
            <form id='changepassword' onSubmit={ this.handleFormSubmit }>
              {/* <div>
                <div className='profileimg'>
                  <div className='image'>
                    <img src={ imageUrl ? imageUrl : Profileimg } />
                  </div>
                  <h4>Change Password</h4>
                </div>
              </div> */}

              <div className='group'>
                <div className='form-group'>
                  <label>Current password</label>
                  <input
                    id='currentPassword'
                    name='currentPassword'
                    onBlur={ this.validation }
                    data-validation={ [ 'password' ] }
                    data-error='Enter current password'
                    onChange={ this.handlePasswordChange }
                    type={ showCurrentPassword ? 'text' : 'password' }
                    onFocus={ labeltop }
                    value={ currentPassword }
                  />
                  <EyeIcon
                    name='showCurrentPassword'
                    dispatchFunction={ this.toggleIcon }
                    showPassword={ showCurrentPassword }
                  />
                </div>
                <div className='form-group'>
                  <label>New password</label>
                  <input
                    id='newPassword'
                    name='newPassword'
                    onBlur={ this.validation }
                    data-validation={ [ 'passwordpattern' ] }
                    data-error='Enter new password'
                    onChange={ this.handlePasswordChange }
                    data-check={ true }
                    type={ showNewPassword ? 'text' : 'password' }
                    onFocus={ labeltop }
                    value={ newPassword }
                  />
                  <EyeIcon
                    name='showNewPassword'
                    dispatchFunction={ this.toggleIcon }
                    showPassword={ showNewPassword }
                  />
                </div>
                <div className='form-group'>
                  <label>Confirm password</label>
                  <input
                    id='confirmPassword'
                    name='confirmPassword'
                    onBlur={ this.validation }
                    data-validation={ [ 'password' ] }
                    data-error='Enter confirm password'
                    onChange={ this.handlePasswordChange }
                    data-check={ true }
                    type={ showConfirmPassword ? 'text' : 'password' }
                    onFocus={ labeltop }
                    value={ confirmPassword }
                  />
                  <EyeIcon
                    name='showConfirmPassword'
                    dispatchFunction={ this.toggleIcon }
                    showPassword={ showConfirmPassword }
                  />
                </div>
                { errorElement !== '' && 
                    <span className='errorElement'>{ errorElement }</span> }
                <div className='change-password-controls'>
                  <Link to='/profile/myprofile'>
                    Cancel
                  </Link>
                  <button type='submit'>
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
