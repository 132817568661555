import { settingsConstants } from 'Constants';
import Request from 'Services/request';
import toastr from 'toastr';

export const settingActions = {
  updateSettings,
  fetchSettings
};

function fetchSettings() {

  return (dispatch) => {
    dispatch(request({}));
    Request.get('/settings', {},
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { data } = response;
        dispatch(success(data.shift()));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failure(error.toString()));
      });
  };

  function request(settings) { return { type: settingsConstants.SETTINGS_REQUEST, settings }; }
  function success(settings) { return { type: settingsConstants.SETTINGS_GET_SUCCESS, settings }; }
  function failure(error) { return { type: settingsConstants.SETTINGS_FAILURE, error }; }
}

function updateSettings(settingObject) {
  return (dispatch) => {
    dispatch(request({}));
    Request.update(`/settings`,
      { ...settingObject },
      { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { message } = response;    
        dispatch(fetchSettings());
        toastr.remove();
        toastr.success(message);
      })
      .catch((error) => {
        toastr.remove();
        toastr.error(error.toString());
        dispatch(failure(error.toString()));
      });
  };

  function request(settings) { return { type: settingsConstants.SETTINGS_REQUEST, settings }; }
  function failure(error) { return { type: settingsConstants.SETTINGS_FAILURE, error }; }
}
