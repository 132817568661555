import React from 'react'
import { connect } from 'react-redux';
import { CallManagementAction } from 'Actions/callManagementAction';
import CallManagement from 'Components/CallManagement';

function mapStatetoProps(state){
    const { responseArray, totalRecords, currentPage, loading } = state.callManagement;
    const { exportCallManagementData } = state.exportCallManagementData;
    const { profile } = state;
    return{
        callList: responseArray, totalRecords, currentPage, loading,
        exportCallManagementData,
        profile: profile.permission && JSON.parse(profile.permission),
    } 
}

const mapDispatchtoProps = {
    fetchCallManagement: CallManagementAction.fetchCallManagement,
    exportCallManagement: CallManagementAction.exportCallManagement,
}

export default connect(mapStatetoProps,mapDispatchtoProps)(CallManagement)