import { callManagementConstants } from 'Constants/callManagement.constants';

const initialState = {
    responseArray: [],
    loading: false,
    totalRecords: 0,
    error: false,
    currentPage: 1,
    formLoading: false,
    errorElement: '',
    editErrorElement: '',
    exportCallManagementData: [],
    exportTotalRecords: 0,
    exportLoding: false,
}

export function callManagement(state = initialState, action = {}) {
    const { callManagement } = action;

    switch (action.type) {
        case callManagementConstants.CALL_MANAGEMENT_REQUEST:
            return { ...state, loading: true, error: null };
        case callManagementConstants.CALL_MANAGEMENT_GET_SUCCESS:
            return {
                ...state,
                responseArray: (callManagement) ? [ ...callManagement ] : [],
                totalRecords: action.totalRecords,
                currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
                loading: false,
                formLoading: false
            };
        case callManagementConstants.DELETE_CALL_MANAGEMENT_SUCCESS:
            return {
                ...state,
                responseArray: state.responseArray.filter((callManagementObj) => {
                    return action.updateObject.ids.indexOf(callManagementObj.id)
                }),
                totalRecords: state.totalRecords - action.updateObject.updateRecords,
                loading: false,
                error: false
            }
        case callManagementConstants.CALL_MANAGEMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state;
    }
}

export function exportCallManagementData(state = initialState, action = {}) {
  const { exportData, totalRecords, error } = action;   
  switch (action.type) {
    case callManagementConstants.EXPORT_CALL_MANAGEMENT_REQUEST:
      return { ...state, exportLoding: true, error: null }
    case callManagementConstants.EXPORT_CALL_MANAGEMENT_SUCCESS:
      return { ...state, exportLoding: false, exportCallManagementData: exportData, exportTotalRecords: totalRecords }
    case callManagementConstants.EXPORT_CALL_MANAGEMENT_FAILURE:
      return { ...state, exportLoding: false, error: error }
    default:
      return state;
  }
}
