import React,{ Component, Fragment } from 'react';

export default class EditPopUp extends Component{
   
    handleEdit = () => {
        const { getActiveEditData, ...rest } = this.props;
        this.props.getActiveEditData(rest);
    };

    render(){
        const { content } = this.props
        return(
            <Fragment>
                  <a onClick={ this.handleEdit }> { content } </a>
            </Fragment>
        )
    }
}