import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'Store';
import Admin from './App';
import './assets/scss/common.scss';
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

toastr.options = {
  ...toastr.options,
  positionClass: 'toast-top-center',
  timeOut: 2000,
  progressBar: true,
  closeButton: true,
  extendedTimeOut: 1000,
  showDuration: 300,
  hideDuration: 1000,
  showEasing: 'swing',
  hideEasing: 'linear'
};

ReactDOM.render(
  <Provider store={ store }>
    <Admin />
  </Provider>,
  document.getElementById('root')
);
