import React, { Fragment } from 'react';
import User from 'assets/images/user.svg';
import { ReactComponent as Delete } from 'assets/images/svg/delete.svg';
import DataTable from 'Container/DataTable/DataTableContainer';
import Pagination from '../../Components/Pagination';
import formUrlParams from 'Helpers/urlform';
import { debounce } from 'lodash';
import EditButton from 'Components/Table/EditButton';
import DeleteButton from 'Components/Table/DeleteButton';
import ReplyFeedback from 'Container/Feedback/ReplyFeedbackContainer';
import { ReactComponent as Android } from 'assets/images/svg/android.svg';
import { ReactComponent as Ios } from 'assets/images/svg/apple.svg';
//import { CSVLink } from 'react-csv';

class Feedback extends React.Component {

  constructor(props) {
    super(props);
    const model = {
      headers: [
        {
          title: 'Name',
          accessor: 'name',
          chooseOption: {
            name: 'nameSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch,
          },
          sortOption: false,
        },
        {
          title: 'Email',
          accessor: 'email',
          chooseOption: {
            name: 'emailSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch,
          },
          sortOption: false,
        },
        {
          title: 'Mobile',
          accessor: 'username',
          chooseOption: {
            name: 'usernameSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch,
          },
          sortOption: false,
        },
        {
          title: 'Device Platform',
          accessor: 'devicePlatform',
          chooseOption: {
            name: 'devicePlatformSearch',
            Component: 'DropDown',
            dispatchFunction: this.handleSearch,
            optionRender: this.createDevicePlatform,
            DropdownText: 'All'
          },
          cell: function (rowDetails) {
            const { content, index } = rowDetails;
            const deviceType =
              content !== '' ? (
                content === 'android' ? (
                  <Fragment>
                    <i className='android'>
                      <Android />
                    </i>
                    {content}
                  </Fragment>
                ) : (content === 'ios' || content === 'iOS') ? (
                  <Fragment>
                    <i className='android'>
                      <Ios />
                    </i>
                    {content}
                  </Fragment>
                ) : (
                      ''
                    )
              ) : (
                  ''
                );
            return <td key={ index }>{ deviceType }</td>;
          }
        },
        {
          title: 'Device Model',
          accessor: 'deviceModel',
          chooseOption: {
            name: 'deviceModelSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch,
          },
          sortOption: false,
        },
        {
          title: 'App Version',
          accessor: 'appVersion',
          chooseOption: {
            name: 'appVersionSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch,
          },
          sortOption: false,
        },
        {
          title: 'Description',
          accessor: 'description',
          chooseOption: {
            name: 'descriptionSearch',
            Component: 'Search',
            dispatchFunction: this.handleSearch,
          },
          sortOption: false,
        },
        {
          title: 'Image',
          accessor: 'image',
          combineapidata: [ '', 'image' ],
          cell: {
            type: 'image-with-name',
          },
        },
        {
          title: 'Status',
          chooseOption: {
            name: 'statusSearch',
            Component: 'DropDown',
            DropdownText:'All',
            dispatchFunction: this.debounceEvent(this.handleSearch,500),
            optionRender: this.createStatus
          },
          accessor: 'status',
          cell: function(rowDetail) {
            const { content, index } = rowDetail;
            const status =
              content === 1 ? (
                <Fragment>
                  <span className='active'></span>Enable
                </Fragment>
              ) : (
                <Fragment>
                  <span className='inactive'></span>Disable
                </Fragment>
              );
            return <td key={ index }>{status}</td>;
          }
        },
        {
          title: 'Action',
          accessor: 'id',
          cell: (rowDetail) => {
            const { index, id, rowIdx, ...rest } = rowDetail;
            const permission = true; //profile && profile.Team;
            return (
              <td key={ index } data-row={ rowIdx } className='action'>
                <EditButton
                  id={ id }
                  { ...rest }
                  getActiveEditData={ this.getActiveEditData }
                  editClass='editDomain'
                  checkPermission={ permission && true }
                />
                <DeleteButton
                  remainningRecords={ this.props.feedback.length }
                  deleteActionListener={ this.props.deleteFeedback }
                  activeQuery={ this.state.filtered }
                  id={ id }
                  currentPage={ this.props.currentPage }
                  checkPermission={ true, true } //{ permission && permission.delete }
                />
              </td>
            );
          },
        },
      ],
      filtered: [],
      responseArray: [],
      pageLimit: 10,
      permission: true,
      activeSort: {},
      saveFeedbackFormStatus: false,
    }
    this.state = model;
  }

  componentDidMount() {
    this.props.fetchFeedback();
    this.props.exportFeedback();
  }
  createStatus = () => {
    const statusArray = [ 'Disable', 'Enable' ];
    return statusArray.map((admin, index) => {
      return (
        <option key={ index } value={ index }>
          {admin}
        </option>
      );
    });
  };

  getActiveEditData = (data) => {
    this.setState({ activeEditData: data, saveFeedbackFormStatus: true });
  };

  setPageLimit = (name, value) => {
    this.formUrlParams(name, value, { pageLimit: value })
  }

  formUrlParams = (name, value, rest) => {
    const { filtered, activeSort } = this.state;
    const filterResponse = formUrlParams(filtered, name, value);
    this.setState({ filtered: filterResponse, ...rest }, () => {
      let updatedFilter = this.state.filtered;
      if (activeSort && activeSort.id) {
        updatedFilter = [
          ...this.state.filtered,
          activeSort
        ]
      }
      this.props.fetchFeedback(updatedFilter);
    });
  }

  /**
   * @method closeForm
   * @summary close save domain popup
   * @return { object } updated domain state
   */

  closeForm = () => {
    this.setState({ saveFeedbackFormStatus: false, filtered: [] });
  };

  /**
   * @method debounceEvent
   * @summary used to delay the event for some limit
   * @param {event} event - React onChange event
   * @return { function } debouncedEvent
   */
  debounceEvent(...args) {
    this.debouncedEvent = debounce(...args);
    return (e) => {
      e.persist();
      return this.debouncedEvent(e);
    };
  }

  sortTable = (sortAttribite) => {
    const { activeSort } = this.state
    const sortType = (activeSort.id === sortAttribite) ?
      ((activeSort.value && activeSort.value == 'desc') ? 'asc' : 'desc') : 'asc';
    this.setState({
      activeSort: { id: sortAttribite, value: sortType },
    }, () => {
      this.formUrlSort();
    })
    /* const { activeSort } = this.state
    const check = activeSort[ sortAttribite ] || 'desc';
    let sorto = { [ sortAttribite ]: check }
    const updateSort = multiSort(tableData, sorto);
    this.setState({
        tableData: updateSort,
        activeSort: {
            ...activeSort,
            [ sortAttribite ]: (check == 'desc') ? 'asc' : 'desc'
        }
    }) */
  }

  formUrlSort = () => {
    const updatedFilter = [
      ...this.state.filtered,
      this.state.activeSort
    ]
    this.props.fetchFeedback(updatedFilter);
  }

  handleSearch = event => {
    const { name, value } = event.target;
    this.formUrlParams(name, value);
  }

  createDevicePlatform = () => {
    const deviceArray = [ 'Android', 'Ios' ]
    return deviceArray.map((type, index) => {
      return (
        <option key={ index } value={ type.toLowerCase() }>{ type }</option>

      )
    })
  }

  updateActiveQuery = (updatedpagination) => {
    this.setState({
      filtered: [ ...this.state.filtered, ...updatedpagination ],
    });
  };

  render() {
    const { feedback, loading, totalRecords, currentPage, exportFeedbackData } = this.props;
    const { saveFeedbackFormStatus } = this.state;
    return (
      <React.Fragment>
        <div className='grid'>
          <div className='head'>
            <h2>Manage Feedback</h2>
            {/* { exportFeedbackData && exportFeedbackData.length !== 0 && <CSVLink
                  data = { exportFeedbackData }
                  filename = { 'Feedback Details.csv' }
                  className = 'btn green-bbt btn-export'
                  onClick={ () => {
                    console.log('Export Data'); // Your click handling logic
                  } }
                >
                  Export To CSV
                </CSVLink>
            } */}
          </div>
          {saveFeedbackFormStatus && (
            <ReplyFeedback
              closeForm={ this.closeForm }
              filterVal={ this.state.filtered }
              { ...this.state.activeEditData }
            />
          )}
          <div className='responsive-table'>
            <DataTable
              headers={ this.state.headers }
              data={ feedback }
              noData='No records!'
              loading={ loading }
              sortTable={ this.sortTable }
            />
            <Pagination
              handleClick={ this.formUrlParams }
              totalRecords={ totalRecords }
              initialPage={ currentPage }
              setPageLimit={ this.setPageLimit }
              pageLimit={ this.state.pageLimit }
              updateActiveQuery={ this.updateActiveQuery }
            />
          </div>
          <Pagination />
        </div>
      </React.Fragment>
    );
  }
}
export default Feedback;
