import { groupsContants } from 'Constants/groups.constants';
import toastr from 'toastr';
import Request from 'Services/request.js';
import { findCurrentPageRequest, queryParser } from 'Helpers/urlform';
import {  formatDateTime } from 'Components/Common/TimeStamp';
import { uuid } from 'uuidv4';

const requestGroups = groups =>({
    type:groupsContants.GROUPS_REQUEST,
    groups
})

const successGetGroups = groupsObject =>({
    type:groupsContants.GROUPS_GET_SUCCESS,
    Groups:groupsObject.data,
    totalRecord:groupsObject.count,
    currentPage:groupsObject.currentPage
})

const successGetGroupsInfo = groupsObject =>({
    id: uuid(),
    type:groupsContants.GROUP_INFO_GET_SUCCESS,
    GroupsInfoList : groupsObject.data,
    totalRecord:groupsObject.count,
    currentPage:groupsObject.currentPage
})

const failureGroups = error =>({
    type:groupsContants.GROUPS_FAILURE,
    error
})

function fetchGroups(SearchArray){
    let url = '/groups';
    if(SearchArray && SearchArray.length > 0) {
      url = queryParser(url, SearchArray)
    }
    const currentPage = findCurrentPageRequest(SearchArray);
    return async (dispatch) => {
      dispatch(requestGroups({}));
      await Request.get(url, {}, { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
          const { data, count } = response;      
          dispatch(successGetGroups({ data, count, currentPage }))
      })
      .catch((error) => {
          toastr.remove();
          toastr.error(error.toString())
          dispatch(failureGroups(error.toString()))
      })
    }
}

function fetchGroupDetails(groupId, SearchArray){
    let url = '/groups/groupId';
    if(SearchArray && SearchArray.length > 0) {
        url = queryParser(url, SearchArray)
    }
    const idQuery = SearchArray && SearchArray.length > 0 ? `&groupId=${ groupId }` : `?groupId=${ groupId }`;
    url = `${ url }${ idQuery }`;
    const currentPage = findCurrentPageRequest(SearchArray);
    return async (dispatch) => {
        dispatch(requestGroups({}));
        await Request.get(url, {}, { 'Authorization': `${ localStorage.getItem('user') }` })
        .then((response) => {
            const { data, count } = response;      
            dispatch(successGetGroupsInfo({ data, count, currentPage }))
        })
        .catch((error) => {
            toastr.remove();
            toastr.error(error.toString())
            dispatch(failureGroups(error.toString()))
        })
    }
}

const requestGroupExportData = (exportData, CON) => ({
  type: CON, 
  exportData
})

const successGroupExportData = (exportDataObj, CON) => ({
  type: CON,
  exportData: exportDataObj.data,
  totalRecords: exportDataObj.count
})

const failureGroupExportData = (error, CON) => ({
  type: CON,
  error
})

function exportGroup(SearchArray) {
  let url = '/groups';
  if (SearchArray && SearchArray.length > 0) {
    url = queryParser(url, SearchArray);
  }
  const exportUrl = SearchArray && SearchArray.length > 0 ? `&exportType=true` : `?exportType=true`;
  
  url = `${ url }${ exportUrl }`;
  return async (dispatch) => {
    dispatch(requestGroupExportData({}, groupsContants.EXPORT_GROUP_REQUEST));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data = [], count } = response;
        data.forEach(function(res){ 
          const createdAt = formatDateTime(res.createdAt);
          res[ 'Group Name' ] = res.groupName;
          res[ 'Created By' ] = res.createdBy;
          res[ 'Created Date' ] = createdAt && createdAt;
          res[ 'Admin Users' ] = res.adminUsers;
          res[ 'Total Members' ] = res.totalMembers;
          delete res.groupId; delete res.groupName; delete res.createdBy; delete res.createdAt; 
          delete res.adminUsers; delete res.totalMembers; delete res.groupImage;
        });
        dispatch(successGroupExportData({ data, count }, groupsContants.EXPORT_GROUP_SUCCESS));
      })
      .catch((error) => {
        toastr.remove();
        toastr.error(error.toString());
        dispatch(failureGroupExportData(error.toString(), groupsContants.EXPORT_GROUP_FAILURE));
      });
  };    
}

function exportGroupInfo(groupId, SearchArray) {
  let url = '/groups/groupId';
  if (SearchArray && SearchArray.length > 0) {
    url = queryParser(url, SearchArray);
  }
  const exportUrl = SearchArray && SearchArray.length > 0 ? `&exportType=true` : `?exportType=true`;
  
  url = `${ url }${ exportUrl }&groupId=${ groupId }`;
  return async (dispatch) => {
    dispatch(requestGroupExportData({}, groupsContants.EXPORT_GROUP_INFO_REQUEST));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })
      .then(( response ) => {
        const { data = [], count } = response;
        data.forEach(function(res){ 
          const createdAt = formatDateTime(res.createdAt);
          res[ 'First Name' ] = res.firstName;
          res[ 'Last Name' ] = res.lastName;
          res[ 'Work Email' ] = res.emailId;
          res[ 'Organisation' ] = res.organisation;
          res[ 'Job Title' ] = res.jobTitle;
          res[ 'Department' ] = res.department;
          res[ 'Status' ] = res.status;
          delete res.groupName; delete res.firstName; delete res.lastName; delete res.emailId; 
          delete res.organisation; delete res.jobTitle; delete res.department;
          delete res.status; delete res.groupImage;
        });
        dispatch(successGroupExportData({ data, count }, groupsContants.EXPORT_GROUP_INFO_SUCCESS));
      })
      .catch((error) => {
        toastr.remove();
        toastr.error(error.toString());
        dispatch(failureGroupExportData(error.toString(), groupsContants.EXPORT_GROUP_INFO_FAILURE));
      });
  };    
}

export const groupsAction = {
    fetchGroups,
    fetchGroupDetails,
    exportGroup,
    exportGroupInfo,
}