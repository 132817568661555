import React from 'react';
import { connect } from 'react-redux';
import EditManageBroadcast from 'Components/Broadcast/Team/Edit/index';
import { TeamAction } from 'Actions'

function mapStatetoProps(state){
    console.log('state.team', state.team)
    const { 
        usersList, usersTotalRecords, error, formLoading, editErrorElement,
        editFormObj: { 
            aboutTeam, checkPermission, content, createdAt, editClass,
            id, image, isActive, teamMembers, teamMembersCount, teamName
        } = {} ,  
    } = state.team
    return{
        error,
        usersList:usersList,
        usersTotalRecords:usersTotalRecords,
        formLoading,
        editErrorElement,
        aboutTeam, checkPermission, content, createdAt, editClass,
        id, image, isActive, teamMembers, teamMembersCount, teamName,
    } 
}
const mapDispatchtoProps = {
    fetchUsers:TeamAction.fetchUsers,
    addTeamImage:TeamAction.addTeamImage,
    updateTeam:TeamAction.updateTeam
}

export default connect(mapStatetoProps,mapDispatchtoProps)(EditManageBroadcast)