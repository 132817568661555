import React from 'react';
import { connect } from 'react-redux';
import Groupbg from 'assets/images/groupbg.jpg';
import { ReactComponent as Plus } from 'assets/images/svg/plus.svg';
import { ReactComponent as Closephoto } from 'assets/images/svg/closephoto.svg';
import { ReactComponent as Add } from 'assets/images/svg/add.svg';
import { broadCastActions } from 'Actions';
import toastr from 'toastr';
import Upload from 'Components/Common/Uploader';
import { validator } from 'Helpers/validator';
import DropDown from 'Components/Common/DropDown';
import uploadImage from 'Helpers/uploadImage';
import Image from 'Components/Common/Image';
import DatePickerComponent from 'Components/Common/DateTimePicker';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class EditManageBroadcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      messages: '',
      messagesType: '',
      teamName: '',
      createdBy: {
        name: '',
        image: ''
      },
      createdDateTime: 0,
      broadcastDateTime: '',
      deliveryStatus: '',
      status: 1,
      fileName: ''
    };
    this.validator = validator.getInstance();
  }

  validation = e => {
    if (!e.target.dataset.validation || !e.target.dataset.check) {
      return;
    }
    const inputRules = e.target.dataset.validation.split(',');
    const elementObject = {
      formId: 'edit-broadcast-form',
      elementValue: e.target.value,
      elementName: e.target.name,
      message: e.target.dataset.error
    };
    this.validator.validate(elementObject, inputRules);
  };

  handleSelectTeam = e => {
    const value = e.target.options[ e.target.selectedIndex ].dataset.rolename;
    const { name } = e.target;
    this.setState({ [ name ]: value });
  };
  
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [ name ]: value });
  };

  handleCheckBoxChange = () => {
    this.setState({ isActive: !this.state.isActive & 1 });
  };

  teamNameArray = () => {
    return [
      'teamName1',
      'teamName2',
      'teamName3',
      'teamName4',
      'teamName5',
      'teamName6',
      'teamName7',
      'teamName8'
    ];
  };
  getIndexByTeamName = teamName => {
    const teamNameArray = this.teamNameArray();
    return teamNameArray.indexOf(teamName) !== -1
      ? teamNameArray.indexOf(teamName)
      : 0;
  };

  renderTeamName = () => {
    const teamNameArray = this.teamNameArray();
    return teamNameArray.map((broadcast, index) => {
      return (
        <option key={ index } value={ index }>
          {broadcast}
        </option>
      );
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      const { id, image, messagesType, messages, teamName, status } = props;
      return {
        id,
        image,
        messagesType,
        messages,
        teamName,
        status,
        fileName: image
      };
    }
    return null;
  }
  handleSubmit = event => {
    event.preventDefault();
    const {
      id,
      messages,
      messagesType,
      teamName,
      createdBy,
      createdDateTime,
      broadcastDateTime,
      deliveryStatus,
      status,
      fileName
    } = this.state;

    if (event.target.id && !this.validator.checkBeforeSubmit(event.target.id)) {
      this.props.updateBroadCast({
        id,
        messages,
        messagesType,
        teamName,
        createdBy,
        createdDateTime,
        broadcastDateTime,
        deliveryStatus,
        status,
        fileName
      });
    }
  };

  updateProfileImage = (fileType, file) => {
    uploadImage(fileType, file)
      .then(
        ({ data }) => {
          const { fileToken } = data;
          this.setState({
            image: fileToken,
            fileName: file.name
          });
        },
        () => {
          document.querySelector('.profilelabel').style.visibility = 'visible';
          const e = new Event('blur');
          const element = document.getElementById('profileimage');
          element.dispatchEvent(e);
        }
      )
      .catch(error => {
        toastr.error(error.toString());
      });
  };

  removeProfileimage = () => {
    this.setState(
      {
        image: '',
        fileName: ''
      },
      () => {
        document.querySelector('.profilelabel').style.visibility = 'hidden';
      }
    );
  };

  closeEditBroadCast = () => {
    this.props.closeEditBroadCastMenu();
  };

  render() {
    const {
      fileName,
      messagesType,
      messages,
      teamName,
      status,
      image
    } = this.state;
    return (
      <React.Fragment>
        <div className='addbroadcast' id='editbroadcast'>
          <div className='overlay' onClick={ this.closeEditBroadCast }></div>
          <div className='addmgnt'>
            <Tabs>
              <TabList>
                <Tab>Publish Now</Tab>
                <Tab>Schedule Later</Tab>
              </TabList>

              <TabPanel>
                <div className='head'>
                  <h2>New Broadcast</h2>
                  <div className='tooglebbt'>
                    <div className='toogle'>
                      <span>(Inactive)</span>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          checked={ !!+status }
                          onChange={ this.handleCheckBoxChange }
                        />
                        <span className='slider round'></span>
                      </label>
                      <span>(active)</span>
                    </div>
                  </div>
                </div>
                <form id='edit-broadcast-form' onSubmit={ this.handleSubmit }>
                  <div className='formgroup'>
                    <label>
                      Message Type<sup>*</sup>
                    </label>
                    <input
                      type='text'
                      name='messagesType'
                      value={ messagesType }
                      data-validation={ [ 'user' ] }
                      data-error='Please enter message type'
                      placeholder='Enter message type here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>Message</label>
                    <input
                      type='text'
                      name='messages'
                      value={ messages }
                      data-validation={ [ 'user' ] }
                      data-error='Please enter message'
                      placeholder='Enter message here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup date'>
                    <label>
                      Message Date<sup>*</sup>
                    </label>
                    <div className='datetime'>
                      <DatePickerComponent minDate={ new Date() } />
                    </div>
                    <span>
                      (Message will be sent at 09.30 AM on the selected date)
                    </span>
                  </div>
                  <div className='formgroup new'>
                    <label>
                      Select Team<sup>*</sup>
                      <Link className='button' to='/UserManagement'>
                        <i>
                          <Add />
                        </i>
                        New Team
                      </Link>
                    </label>
                    <DropDown
                      name='teamName'
                      DropdownText='Select'
                      selectedOption={ this.getIndexByTeamName(teamName) }
                      requiredfield='true'
                      dispatchFunction={ this.handleSelectTeam }
                      optionRender={ this.renderTeamName }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>
                      Profile image<sup>*</sup>
                    </label>
                    <div className='loading-parent'>
                      <div className='profileimg'>
                        <Image placeHolder={ Groupbg } imageToken={ image } />
                        <span className='profilelabel'>
                          <input
                            type='text'
                            name='image'
                            id='profileimage'
                            disabled
                            value={ fileName }
                            onChange={ this.handleChange }
                            onBlur={ this.validation }
                            data-validation={ [ 'requiredfield' ] }
                            data-error='Profile image must not be empty'
                          />
                          {fileName && (
                            <i>
                              <Closephoto onClick={ this.removeProfileimage } />
                            </i>
                          )}
                        </span>
                      </div>

                      <div className='formgroup upload'>
                        <div className='profilelist'>
                          <i className='uploadic'>
                            <Upload
                              id='addbroadcastupload'
                              type='profile'
                              updateProfileImage={ this.updateProfileImage }
                            />
                            <label
                              htmlFor='addbroadcastupload'
                              className='addadminupload'
                            >
                              <Plus />
                            </label>
                          </i>
                        </div>
                        <div className='dragable'>
                          <Upload
                            id='addbroadcastupload'
                            type='profile'
                            updateProfileImage={ this.updateProfileImage }
                          />
                          <label htmlFor='addbroadcastupload'>
                            Drag files here or <span>browse</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='buttons'>
                    <div>
                      <button
                        type='reset'
                        onClick={ this.closeEditBroadCast }
                        className='plain-bbt'
                      >
                        Cancel
                      </button>
                      <button className='blue-bbt'>Save</button>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel>
                <div className='head'>
                  <h2>New Broadcast</h2>
                  <div className='tooglebbt'>
                    <div className='toogle'>
                      <span>(Inactive)</span>
                      <label className='switch'>
                        <input
                          type='checkbox'
                          checked={ !!+status }
                          onChange={ this.handleCheckBoxChange }
                        />
                        <span className='slider round'></span>
                      </label>
                      <span>(active)</span>
                    </div>
                  </div>
                </div>
                <form id='edit-broadcast-form' onSubmit={ this.handleSubmit }>
                  <div className='formgroup'>
                    <label>
                      Message Type<sup>*</sup>
                    </label>
                    <input
                      type='text'
                      name='messagesType'
                      value={ messagesType }
                      data-validation={ [ 'user' ] }
                      data-error='Please enter message type'
                      placeholder='Enter message type here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>Message</label>
                    <input
                      type='text'
                      name='messages'
                      value={ messages }
                      data-validation={ [ 'user' ] }
                      data-error='Please enter message'
                      placeholder='Enter message here!'
                      onBlur={ this.validation }
                      onChange={ this.handleChange }
                    />
                  </div>
                  <div className='formgroup date'>
                    <label>
                      Message Date<sup>*</sup>
                    </label>
                    <div className='datetime'>
                      <DatePickerComponent minDate={ new Date() } />
                    </div>
                    <span>
                      (Message will be sent at 09.30 AM on the selected date)
                    </span>
                  </div>
                  <div className='formgroup new'>
                    <label>
                      Select Team<sup>*</sup>
                      <Link className='button' to='/UserManagement'>
                        <i>
                          <Add />
                        </i>
                        New Team
                      </Link>
                    </label>
                    <DropDown
                      name='teamName'
                      selectedOption={ this.getIndexByTeamName(teamName) }
                      requiredfield='true'
                      dispatchFunction={ this.handleSelectTeam }
                      optionRender={ this.renderTeamName }
                    />
                  </div>
                  <div className='formgroup'>
                    <label>
                      Profile image<sup>*</sup>
                    </label>
                    <div className='loading-parent'>
                      <div className='profileimg'>
                        <Image placeHolder={ Groupbg } imageToken={ image } />
                        <span className='profilelabel'>
                          <input
                            type='text'
                            name='image'
                            id='profileimage'
                            disabled
                            value={ fileName }
                            onChange={ this.handleChange }
                            onBlur={ this.validation }
                            data-validation={ [ 'requiredfield' ] }
                            data-error='Profile image must not be empty'
                          />
                          {fileName && (
                            <i>
                              <Closephoto onClick={ this.removeProfileimage } />
                            </i>
                          )}
                        </span>
                      </div>

                      <div className='formgroup upload'>
                        <div className='profilelist'>
                          <i className='uploadic'>
                            <Upload
                              id='addbroadcastupload'
                              type='profile'
                              updateProfileImage={ this.updateProfileImage }
                            />
                            <label
                              htmlFor='addbroadcastupload'
                              className='addadminupload'
                            >
                              <Plus />
                            </label>
                          </i>
                        </div>
                        <div className='dragable'>
                          <Upload
                            id='addbroadcastupload'
                            type='profile'
                            updateProfileImage={ this.updateProfileImage }
                          />
                          <label htmlFor='addbroadcastupload'>
                            Drag files here or <span>browse</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='buttons'>
                    <div>
                      <button
                        type='reset'
                        onClick={ this.closeEditBroadCast }
                        className='plain-bbt'
                      >
                        Cancel
                      </button>
                      <button className='blue-bbt'>Save</button>
                    </div>
                  </div>
                </form>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStatetoProps(state) {
  const { error } = state.broadCast;
  return {
    error
  };
}

const mapDispatchtoProps = {
  updateBroadCast: broadCastActions.updateBroadCast
};

export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(EditManageBroadcast);
