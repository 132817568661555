import dateFormat from 'dateformat';
const TIME_FORMAT = 'HH:mm:ss';
const FINAL_FORMAT = 'd-MMM -yyyy';

/**
 * convertUTCTOLocalTimeStamp() method to perform convert UTC to local time formate.
 * 
 * @param {date} date 
 */
export const convertUTCTOLocalTimeStamp = (date) => {
  const replacedDate = new Date(date.replace(/-/g, '/'));
  var newDate = new Date(replacedDate);
  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  return newDate;
}

export const formatDateTime = (dateTime) => {
  const val = dateTime && dateTime.replace('T',' ')
  const time = dateTime && convertUTCTOLocalTimeStamp(val)
  return dateTime && dateFormat(time, 'mmm d, yyyy / hh:MM:ss TT ');
}

export const millisToMinutesAndSeconds = (micro) => {
  const millis = micro / 1000;
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}
