import { broadCastConstant } from 'Constants/adminBroadCast.constants';
import Request from 'Services/request';
import broadCastSample from '../Dummy/broadCastSample'
import { alterQueryRequest,  findCurrentPageRequest, queryParser } from 'Helpers/urlform'
import toastr from 'toastr';

const requestBroadCast = broadCast =>({ 
    type: broadCastConstant.BROADCAST_REQUEST, 
    broadCast 
})

const successBroadCast = broadcastDetails =>({
    type: broadCastConstant.BROADCAST_GET_SUCCESS,
    broadCast:broadcastDetails.data,
    totalRecords:broadcastDetails.count,
    currentPage:broadcastDetails.currentPage
})

const successUpdateBroadCast = broadcastDetails =>({
  type: broadCastConstant.UPDATE_BROADCAST_SUCCESS,
  broadCast:broadcastDetails
})

const failureBroadCast = error =>({
    type: broadCastConstant.BROADCAST_FAILURE,
    error
})

function dummyFetchBroadCast(params) {
   return new Promise((resolve,reject)=>{
       resolve(broadCastSample)
   })
}

function dummyUpdateBroadCast(){
  return new Promise((resolve,reject)=>{
     resolve(broadCastSample)
 })
}

function fetchBroadCast(SearchArray) {
  let url = '/broadcast';
  if(SearchArray && SearchArray.length > 0 ) {
    url = queryParser(url,SearchArray);
  }  
  const currentPage = findCurrentPageRequest(SearchArray);
  return async (dispatch) => {
    dispatch(requestBroadCast({}));
    await Request.get(url, {},{ 'Authorization': `${ localStorage.getItem('user') }` })      .then(( response ) => {
        const { data,count } = response;
        dispatch(successBroadCast({ data, count, currentPage }));
      })
      .catch((error) => {
        toastr.error(error.toString());
        dispatch(failureBroadCast(error.toString()));
      });
  };
}

function addBroadCast(addBroadCastObj) {
 
    return (dispatch) => {
      dispatch(requestBroadCast({}));
      Request.create('/adminuser',{ ...addBroadCastObj },
        { 'Authorization': `${ localStorage.getItem('user') }` })
      .then((response) => {
        const { message } = response;   
        dispatch(fetchBroadCast([]));
        toastr.success(message);
      })
      .catch((error) => {
         toastr.error(error.toString());
         dispatch(failureBroadCast(error.toString()));
      });
    };
  }

  function updateBroadCast(broadCast) {
    const { id, ...rest } = broadCast
    return (dispatch) => {
      dispatch(requestBroadCast({}));
      Request.update(`/adminuser/${ id }`,
        { ...rest },
        { 'Authorization': `${ localStorage.getItem('user') }` })
        dummyUpdateBroadCast()
        .then((response) => {
          const { message } = response;        
          dispatch(successUpdateBroadCast(broadCast));
          toastr.success(message);
        })
        .catch((error) => {
          toastr.error(error.toString());
          dispatch(failureBroadCast(error.toString()));
        });
    };
  }

  function deleteBroadCast(id, SearchArray, remainningRecords){
    const updateSearchArray = alterQueryRequest(SearchArray, remainningRecords);
      return (dispatch) => {
          dispatch(requestBroadCast({}));
          Request.remove('/adminuser',
          { ids:[ id ] },
          { 'Authorization': `${ localStorage.getItem('user') }` })
          .then((response) => {
            dispatch(fetchBroadCast(updateSearchArray));
            toastr.success('Deleted broadcast successfully');
          })
          .catch((error) => {
            toastr.error(error.toString());
            dispatch(failureBroadCast(error.toString()));
          });
      }
  }
  
  export const broadCastActions = {
    fetchBroadCast,
    addBroadCast,
    updateBroadCast,
    deleteBroadCast
  };