import GroupInfo from 'Container/Groups/GroupsInfoContainer';
import BroadcastMgMt from 'Container/BroadCast/ManageBroadCast/BroadCastContainer';
import Broadcast from 'Components/Broadcast';
import BroadcastTeam from 'Container/BroadCast/Team/TeamContainer';
import ManageAdmin from 'Container/ManageAdmin/ManageAdminContainer';
import EditManageAdmin from 'Components/Admin/ManageAdmin/Edit';
import ManageAdminRoles from 'Container/ManageRoles/ManageAdminRoles';
import EditManageAdminRoles from 'Components/Admin/ManageAdminRoles/Edit';
import Settings from 'Container/Settings/SettingContainer';
import MyProfile from 'Container/Profile/MyProfileContainer';
import ChangePassword from 'Container/ChangePasswordContainer';
import Coming from 'Components/ComingSoon';
import UserDetails from 'Container/Users/userDetailsContainer';
import UserList from 'Container/Users/usersListContainer';
import Group from 'Container/Groups/GroupsContainer';
import CallManagement from 'Container/CallManagement';
import AddTeam from 'Container/BroadCast/Team/AddTeamContainer';
import EditTeam from 'Container/BroadCast/Team/EditTeamContainer';
import Feedback from 'Container/Feedback';

const dashboardRoutes = [
  {
    path: '/',
    name: '',
    icon: null,
    component: Coming,
    exact: true,
    route: 'private'
  },
  {
    path: '/UserManagement',
    name: 'Users',
    component: UserList,
    icon: null,
    exact: true,
    route: 'private'
  },
  {
    path: '/comingsoon',
    name: 'comingsoon',
    icon: null,
    exact: true,
    component: Coming,
    route: 'public'
  },
  {
    path: '/groups',
    name: 'Groups',
    icon: null,
    exact: true,
    route: 'private',
    component: Group,
  },
  {
    path: '/groups/:id',
    name: 'Groups',
    icon: null,
    component: GroupInfo,
    exact: true,
    route: 'private'
  },
  {
    path: '/groupinfo/:id',
    name: 'Groups',
    icon: null,
    component: GroupInfo,
    exact: true,
    route: 'private'
  },
  {
    path: '/broadcast',
    name: 'Broadcast',
    icon: null,
    component: Broadcast,
    exact: true,
    route: 'private'
  },
  {
    path: '/broadcast/manage',
    name: 'Broadcast',
    icon: null,
    component: BroadcastMgMt,
    exact: true,
    route: 'private'
  },
  {
    path: '/broadcast/team',
    name: 'Team',
    icon: null,
    component: BroadcastTeam,
    exact: true,
    route: 'private'
  },
  {
    path: '/broadcast/team/addteam',
    name: 'Team',
    icon: null,
    component: AddTeam,
    exact: true,
    route: 'private'
  },
  {
    path: '/broadcast/team/editteam',
    name: 'Team',
    icon: null,
    component: EditTeam,
    exact: true,
    route: 'private'
  },
  {
    path: '/admin/manageadmin',
    name: 'Manage admin',
    icon: null,
    component: ManageAdmin,
    exact: true,
    route: 'public'
  },
  {
    path: '/admin/editmanageadmin',
    name: 'Manage admin',
    icon: null,
    component: EditManageAdmin,
    exact: true,
    route: 'private'
  },
  {
    path: '/admin/manageadminroles',
    name: 'Manage admin roles',
    icon: null,
    component: ManageAdminRoles,
    exact: true,
    route: 'public'
  },
  {
    path: '/admin/editmanageadminroles',
    name: 'Manage admin roles',
    icon: null,
    component: EditManageAdminRoles,
    exact: true,
    route: 'private'
  },
  {
    path: '/feedback',
    name: 'Feedback',
    icon: null,
    component: Feedback,
    exact: true,
    route: 'private'
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: null,
    component: Settings,
    exact: true,
    route: 'private'
  },
  {
    path: '/profile/myprofile',
    name: '',
    icon: null,
    component: MyProfile,
    exact: true,
    route: 'public'
  },
  {
    path: '/profile/changepassword',
    name: '',
    icon: null,
    component: ChangePassword,
    exact: true,
    route: 'public'
  },
  {
    path: '/callmanagement',
    name: 'Call management',
    icon: null,
    component: CallManagement,
    exact: true,
    route: 'public'
  },
  {
    path: '/user/:id',
    name: 'Users',
    icon: null,
    component: UserDetails,
    exact: true,
    route: 'private'
  }
];

export default dashboardRoutes;
