import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { forgotpassword } from './forgotpassword.reducer';
import { changepassword } from './changepassword.reducer';
import { userlogout } from './logout.reducer';
import { settings } from './settings.reducer';
import { profile } from './profile.reducer';
import { manageAdmin } from './manageAdmin.reducer';
import { adminRoles } from './manageadminroles.reducer';
import { broadCast } from './broadCast.reducer';
import { usersList } from './usersList.reducer';
import { groupsList, exportGroupData } from './groups.reducer';
import { team } from './team.reducer';
import { resetPassword } from './resetPassword.reducer';
import { userContactsList } from './contacts.reducer';
import { feedback, exportFeedbackData } from './feedback.reducer';
import { callManagement, exportCallManagementData } from './callManagement.reducer';

const rootReducer = combineReducers({
  authentication,
  forgotpassword,
  changepassword,
  userlogout,
  settings,
  profile,
  manageAdmin,
  adminRoles,
  broadCast,
  usersList,
  groupsList,
  team,
  resetPassword,
  userContactsList,
  callManagement,
  feedback,
  exportGroupData,
  exportCallManagementData,
  exportFeedbackData,
});

export default rootReducer;
