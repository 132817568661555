import React, { Component } from 'react';
import CheckboxItem from './CheckboxItem'

class CheckboxGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentCheckboxChecked: false,
        };
    }

    updateParentWithChildren = () => {
        const { checkboxes, onCheckboxGroupChange, checkboxGroupkey } = this.props;
        const { parentCheckboxChecked } = this.state;
        const updateCheckBox = { ...checkboxes }
        for (const property in updateCheckBox) {
            updateCheckBox[ property ] = !parentCheckboxChecked
        }
        this.setState({
            parentCheckboxChecked: !parentCheckboxChecked
        }, () => {
            onCheckboxGroupChange(checkboxGroupkey, updateCheckBox);
        })
    };

    handleChildCheckboxChange = (isChecked, checkboxname) => {
        const { checkboxes,
            checkboxGroupkey,
            onCheckboxGroupChange
        } = this.props;

        const updateCheckBox = { ...checkboxes }
        updateCheckBox[ checkboxname ] = isChecked;
        const updateParent = Object.keys(updateCheckBox).some(key => !updateCheckBox[ key ]);
        this.setState({
            parentCheckboxChecked: !updateParent
        }, () => {
            onCheckboxGroupChange(checkboxGroupkey, updateCheckBox);
        })
    };

    renderCheckboxes = () => {
        const { checkboxes, checkboxGroupkey } = this.props;
        if (!checkboxes) {
            return null;
        }
        var CheckboxItemArray = [];
        for (const property in checkboxes) {
            CheckboxItemArray.push(<CheckboxItem
                checkboxname={ property }
                parentClass={ this.props.parentClass }
                checked={ checkboxes[ property ] }
                parentkey={ checkboxGroupkey }
                checkboxChangeCallback={ (checkStatus, checkboxname) => this.handleChildCheckboxChange(checkStatus, checkboxname) }
                key={ property } />)
        }
        return CheckboxItemArray;
    };

    componentDidMount() {
        this.initialSetUp()
    }

    initialSetUp = () => {
        const { checkboxes } = this.props;
        const updateParent = Object.keys(checkboxes).some(key => !checkboxes[ key ]);
        this.setState({
            parentCheckboxChecked: !updateParent
        })
    }

    render() {
        const { checkboxGroupkey } = this.props;
        const { parentCheckboxChecked } = this.state;
        return (
            <React.Fragment>
                <div className='roles'>
                    <div className='parent'>
                        <div className='check-box'>
                            <div className='checkbox'>
                                <input type='checkbox'
                                    checked={ parentCheckboxChecked }
                                    id={ checkboxGroupkey }
                                    onChange={ this.updateParentWithChildren }
                                />
                                <label htmlFor={ checkboxGroupkey }>
                                    <span name={ checkboxGroupkey }>{checkboxGroupkey}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <ul>
                        {this.renderCheckboxes()}
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default CheckboxGroup;