import React from 'react'
import { connect } from 'react-redux';
import { groupsAction } from 'Actions/groupsAction';
import Groups from 'Components/Groups';

function mapStatetoProps(state){
    const { exportGroupData } = state.exportGroupData;
    return{
       ...state.groupsList,
       exportGroupData,
    } 
}

const mapDispatchtoProps = {
    fetchGroups:groupsAction.fetchGroups,
    exportGroup: groupsAction.exportGroup,
}

export default connect(mapStatetoProps,mapDispatchtoProps)(Groups)