export const feedbackConstants = {
    FEEDBACK_REQUEST: 'FEEDBACK_REQUEST',
    FEEDBACK_GET_SUCCESS: 'FEEDBACK_GET_SUCCESS',
    UPDATE_FEEDBACK_SUCCESS: 'UPDATE_FEEDBACK_SUCCESS',
    FEEDBACK_FAILURE: 'FEEDBACK_FAILURE', 
    DELETE_FEEDBACK_SUCCESS : 'DELETE_FEEDBACK_SUCCESS',
    FEEDBACK_SAVE_FORM_FAILURE: 'FEEDBACK_SAVE_FORM_FAILURE',
    EXPORT_FEEDBACK_REQUEST: 'EXPORT_FEEDBACK_REQUEST',
    EXPORT_FEEDBACK_SUCCESS: 'EXPORT_FEEDBACK_SUCCESS',
    EXPORT_FEEDBACK_FAILURE: 'EXPORT_FEEDBACK_FAILURE',
}