export const teamConstants = {

    TEAM_REQUEST: 'TEAM_REQUEST',
    TEAM_GET_SUCCESS: 'TEAM_GET_SUCCESS',
    TEAM_FAILURE: 'TEAM_FAILURE',  
    ADD_TEAM_REQUEST: 'ADD_TEAM_REQUEST',
    ADD_TEAM_SUCCESS: 'ADD_TEAM_SUCCESS',
    UPDATE_TEAM_SUCCESS : 'UPDATE_TEAM_SUCCESS',
    DELETE_TEAM_SUCCESS : 'DELETE_TEAM_SUCCESS',
    USERS_REQUEST:'USERS_REQUEST',
    USERS_SUCCESS:'USERS_SUCCESS',
    USERS_FAILURE:'USERS_FAILURE',
    TEAM_ADD_FORM_FAILURE:'TEAM_ADD_FORM_FAILURE',
    TEAM_EDIT_FORM_FAILURE:'TEAM_EDIT_FORM_FAILURE',
    EDIT_FORM_REQUEST: 'EDIT_FORM_REQUEST',
}