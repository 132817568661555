import React from 'react';
import { connect } from 'react-redux';
import { manageAdminActions } from 'Actions/manageAdminactions';
import EditManageAdmin from 'Components/Admin/ManageAdmin/Edit';

function mapStatetoProps(state) {
  const { allRoles,formLoading } = state.adminRoles;
  const { error,editErrorElement } = state.manageAdmin;
  return {
    adminRoles: allRoles,
    formLoading,
    error,
    editErrorElement
  }
}

const mapDispatchtoProps = {
  updateAdminUser: manageAdminActions.updateAdminUser
}

export default connect(mapStatetoProps, mapDispatchtoProps)(EditManageAdmin);