import { groupsContants } from 'Constants/groups.constants';

const initialState ={
    Groups:[],
    GroupsInfoList:[],
    loading:false,
    totalRecords:0,
    exportGroupData: [],
    exportGroupInfoData: [],
    exportGroupTotalRecords: 0,
    exportGroupInfoTotalRecords: 0,
}

export function groupsList(state=initialState,action={}){
    const { Groups,GroupsInfoList, id } = action
    switch(action.type){
        case groupsContants.GROUPS_REQUEST:
            return{
                ...state,
                loading:true
            }
        case groupsContants.GROUPS_GET_SUCCESS:
            return{
                ...state,
                Groups:Groups ? [ ...Groups ] : [],
                totalRecords:action.totalRecord,
                currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
                loading:false
            }
        case groupsContants.GROUP_INFO_GET_SUCCESS:
            return{
                ...state,
                id: id,
                GroupsInfoList : GroupsInfoList ? [ ...GroupsInfoList ] : [],
                totalRecords:action.totalRecord,
                currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
                loading:false
            }
        case groupsContants.GROUPS_FAILURE:
            return{
                ...state,
                loading:false
            }
        default:
            return state
    }
}

export function exportGroupData(state = initialState, action = {}) {
    const { exportData, totalRecords, error } = action;   
    switch (action.type) {
        case groupsContants.EXPORT_GROUP_REQUEST:
            return { ...state, exportLoding: true, error: null }
        case groupsContants.EXPORT_GROUP_SUCCESS:
            return { ...state, exportLoding: false, exportGroupData: exportData, exportGroupTotalRecords: totalRecords }
        case groupsContants.EXPORT_GROUP_FAILURE:
            return { ...state, exportLoding: false, error: error }
        case groupsContants.EXPORT_GROUP_INFO_REQUEST:
            return { ...state, exportLoding: true, error: null }
        case groupsContants.EXPORT_GROUP_INFO_SUCCESS:
            return { ...state, exportLoding: false, exportGroupInfoData: exportData, exportGroupInfoTotalRecords: totalRecords }
        case groupsContants.EXPORT_GROUP_INFO_FAILURE:
            return { ...state, exportLoding: false, error: error }
        default:
            return state;
    }
}