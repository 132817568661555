import { adminRolesConstants } from 'Constants/adminRole.costants';

const initialState = {
  adminRoles: [],
  allRoles:[],
  allowedModules:[],
  totalRecords: 0,
  error:false,
  currentPage:1,
  loading:false,
  formLoading:false,
  errorElement:'',
  editErrorElement:'',
  popup:false
}

export function adminRoles(state = initialState, action={}) {

  const { adminRoleObject,allowedModulesObject } = action; 
 
  switch (action.type) {
    case adminRolesConstants.ADMIN_ROLES_REQUEST:
      return { ...state, loading: true,  error:null,popup:false };
    case adminRolesConstants.ADMIN_ROLES_FORM_REQUEST:
      return { ...state, formLoading: true, error:true,errorElement:'',editErrorElement:'',popup:false };
    case adminRolesConstants.ALL_ADMIN_ROLES_GET_SUCCESS:
      return { 
              ...state, 
              loading: false,  
              allRoles:[ ...adminRoleObject ],
              error:null
            };
    case adminRolesConstants.ALLOWED_MODULES_GET_SUCCESS:
      return { 
              ...state, 
              loading: false,  
              allowedModules:{ ...allowedModulesObject },
              error:null,
            };
    case adminRolesConstants.ADMIN_ROLES_GET_SUCCESS:
      return {
        ...state,
        adminRoles: (adminRoleObject ) ? [ ...adminRoleObject ] : [],
        totalRecords: (action.totalRecords) ? action.totalRecords : 0,
        currentPage: (action.currentPage) ? action.currentPage : state.currentPage,
        loading: false,
        formLoading: false
      };
    case adminRolesConstants.ADMIN_ROLES_UPDATE_SUCCESS:
      return {
          ...state,
          adminRoles: state.adminRoles.map(( role ) => {
              if(role.id == adminRoleObject.id) {
                return {
                  id:adminRoleObject.id,
                  roleName:adminRoleObject.roleName,
                  noOfAdminUsers:role.noOfAdminUsers,
                  allowedModules:JSON.stringify(adminRoleObject.module)    
                }
              }
            return role;
          }),
          loading: false,
          error:false,
          formLoading: false,
          editErrorElement:''
      }
    case adminRolesConstants.ADMIN_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error:true,
      };
    case adminRolesConstants.ADMIN_DELETE_ROLES_FAILURE:
      return {
        ...state,
        errorElement:action.error,
        popup:true,
        loading:false
      };
    case adminRolesConstants.ADMIN_ROLES_FORM_FAILURE:
        return {
          ...state,
          loading: false,
          error:true,
          formLoading: false,
          errorElement:action.error,
        };
      
    case adminRolesConstants.ADMIN_ROLES_EDIT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error:true,
        formLoading: false,
        editErrorElement:action.error,
      };
    default:
      return state;
  }
}
