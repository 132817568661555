import { connect } from 'react-redux';
import { userActions } from 'Actions';
import LoginPage from 'Components/Login';

function mapStateToProps(state) {
    const { loading, message, errorElement } = state.authentication;
    return { loading, message, errorElement };
}

const mapDispatchToProps = {
    login: userActions.login,
    logout: userActions.logout
}; 

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
