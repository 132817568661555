import React from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

class SideMenu extends React.Component {
  toggle = () => {
    document.getElementById('sidemenu').classList.remove('close');
    document.getElementById('maincontainer').classList.remove('collaps');
  };
  getNavLinkClass = path => {
    return this.props.location.pathname.includes(path) ? 'active' : '';
  };

  getlevelmenuClass = path => {
      return (this.props.location.pathname === path) ? 'levelmenu' : '';
  }

  getlevelmenuClassIncludes = path => {
    return this.props.location.pathname.includes(path) ? 'levelmenu' : '';
  }

  render() {
    const { [ 'Manage admin' ]:Adminuser, Broadcast, Feedback,[ 'Call management' ]:Callmanagement, Groups,[ 'Manage admin roles' ]:Roles, Settings, Team, Users } =
    this.props.profile.permission && JSON.parse(this.props.profile.permission) || {};
    return (
      <React.Fragment>
        <nav className='sideMenu' id='sidemenu' onMouseOver={ this.toggle }>
          <ul>
            <li>
              <NavLink
                className='main-link'
                exact
                activeClassName='active'
                to='/'
              >
                <i className='MenuIcon'>
                  <svg
                    width='14.237'
                    height='21.355'
                    viewBox='0 0 14.237 21.355'
                  >
                    <path
                      d='M59.209,205.3a.759.759,0,0,0,.911-.276L68.1,193.617a.728.728,0,0,0,.056-.773.777.777,0,0,0-.669-.4l-4.946-.037,1.432-7.506a.756.756,0,0,0-1.339-.607l-8.46,10.542a.725.725,0,0,0-.093.791.765.765,0,0,0,.688.423l5.466.037-1.469,8.389A.722.722,0,0,0,59.209,205.3Z'
                      transform='translate(-54 -184)'
                    />
                  </svg>
                </i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            { Users && Users.view && (
            <li>
              <NavLink
                className='main-link'
                activeClassName='active'
                to='/UserManagement'
              >
                <i className='MenuIcon'>
                  <svg width='13' height='13' viewBox='0 0 13 13'>
                    <path d='M6.5,6.5A3.25,3.25,0,1,0,3.25,3.25,3.26,3.26,0,0,0,6.5,6.5Zm0,1.625c-2.194,0-6.5,1.056-6.5,3.25V13H13V11.375C13,9.181,8.694,8.125,6.5,8.125Z' />
                  </svg>
                </i>
                <span> Users</span>
              </NavLink>
            </li>
            )}
            { Groups && Groups.view && (
            <li>
              <NavLink
                className='main-link'
                activeClassName='active'
                to='/groups'
              >
                <i className='MenuIcon'>
                  <svg
                    width='18.917'
                    height='14.185'
                    viewBox='0 0 18.917 14.185'
                  >
                    <path
                      d='M9.458,42.9c1.658,0,2.948-1.72,2.948-3.5v-.558a2.949,2.949,0,1,0-5.9,0V39.4C6.51,41.183,7.8,42.9,9.458,42.9Zm-5.906.576a2.022,2.022,0,0,0,1.771-1.892v-.946a1.776,1.776,0,1,0-3.543,0v.946a2.043,2.043,0,0,0,1.771,1.892Zm10.614,2.365a.252.252,0,0,0,0-.027,2.279,2.279,0,0,0-.064-.327,0,0,0,0,0,0,0,2.023,2.023,0,0,0-.322-.66h0a3.8,3.8,0,0,0-1.269-1.068,1.4,1.4,0,0,0-.683-.272c-.326,0-.61.254-1.09.693h0l-.436.4c-.015.015-.486.679-.838.679s-.822-.664-.838-.679l-.436-.4h0c-.479-.44-.764-.693-1.09-.693a1.282,1.282,0,0,0-.683.272,4.443,4.443,0,0,0-1.27,1.064h0v0a2.023,2.023,0,0,0-.32.656l0,.006a2.342,2.342,0,0,0-.064.324.278.278,0,0,1,0,.03,3.278,3.278,0,0,0-.02.361v2.6c0,.84.317,1.182,1.182,1.182H13c.873,0,1.182-.323,1.182-1.182V46.2a3.325,3.325,0,0,0-.021-.358Zm1.208-2.365a2.022,2.022,0,0,0,1.771-1.892v-.946a1.776,1.776,0,1,0-3.543,0v.946A2.043,2.043,0,0,0,15.374,43.479ZM1.345,44.759A2.558,2.558,0,0,0,0,47.032c0,.01,0,1.173,0,1.182C0,49.072.288,49.4,1.182,49.4H3.547V45.85l-.591-.591C2.33,44.708,1.8,44.5,1.345,44.759Zm2.2,1.091C3.56,45.862,3.547,45.57,3.547,45.85Zm15.369,1.182a2.558,2.558,0,0,0-1.345-2.274c-.458-.26-.985-.052-1.611.5l-.591.591V49.4h2.366c.894,0,1.182-.324,1.182-1.182C18.917,48.206,18.916,47.043,18.916,47.032ZM15.369,45.85C15.369,45.57,15.356,45.862,15.369,45.85Z'
                      transform='translate(0 -35.8)'
                    />
                  </svg>
                </i>
                <span> Groups</span>
              </NavLink>
            </li>
          )}
          { ((Broadcast && Broadcast.view ) || (Team && Team.view)) &&  (
            <li className={ this.getNavLinkClass('/broadcast') }>
              <NavLink className='main-link' to={ Broadcast.view ? '/broadcast/manage' : (Team.view  ? '/broadcast/team' : '') }>
                <i className='MenuIcon'>
                  <svg
                    width='15.116'
                    height='18.109'
                    viewBox='0 0 15.116 18.109'
                  >
                    <g transform='translate(0 0)'>
                      <path
                        d='M7.555,23.894a1.511,1.511,0,1,0,1.511,1.511A1.511,1.511,0,0,0,7.555,23.894Zm4.534,1.511a4.533,4.533,0,1,0-9.067,0,4.6,4.6,0,0,0,2.269,3.929l.755-1.285a3,3,0,1,1,4.533-2.568,2.838,2.838,0,0,1-1.511,2.568l.755,1.285a4.6,4.6,0,0,0,2.264-3.93ZM7.555,17.85A7.578,7.578,0,0,0,0,25.4a7.433,7.433,0,0,0,3.778,6.5l.755-1.285A6.037,6.037,0,1,1,13.6,25.4a5.972,5.972,0,0,1-3.022,5.213l.755,1.284A7.524,7.524,0,0,0,7.555,17.85Z'
                        transform='translate(0 -17.85)'
                      />
                    </g>
                    <path
                      d='M2-8H3.672V2.45H2Z'
                      transform='translate(4.688 15.659)'
                    />
                  </svg>
                </i>
                <span> Broadcast</span>
                <i className='arrow'>
                  <svg width='8.4' height='4.725' viewBox='0 0 8.4 4.725'>
                    <path
                      d='M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z'
                      transform='translate(-54.818 -237.549)'
                    />
                  </svg>
                </i>
              </NavLink>
              <ul>
              { Broadcast && Broadcast.view && (
                <li className={ this.getlevelmenuClass('/broadcast/manage') }>
                  <Link to='/broadcast/manage'>
                    <i>
                      <svg width='8.4' height='4.725' viewBox='0 0 8.4 4.725'>
                        <path
                          d='M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z'
                          transform='translate(-54.818 -237.549)'
                        />
                      </svg>
                    </i>
                    <span> Manage Broadcast</span>
                  </Link>
                </li>
              )}
              { Team && Team.view && (
                <li className={ this.getlevelmenuClassIncludes('/broadcast/team') }>
                  <Link to='/broadcast/team'>
                    <i>
                      <svg width='8.4' height='4.725' viewBox='0 0 8.4 4.725'>
                        <path
                          d='M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z'
                          transform='translate(-54.818 -237.549)'
                        />
                      </svg>
                    </i>
                    <span>Team</span>
                  </Link>
                </li>
                  )}
              </ul>
            </li>
              )}
          { ((Adminuser && Adminuser.view ) || (Roles && Roles.view)) &&  (
            <li className={ this.getNavLinkClass('/admin') }>
              <NavLink
                className='main-link'
                activeClassName='active'
                to={ Adminuser.view ? '/admin/manageadmin' : ( Roles.view ? '/admin/manageadminroles' : '' ) }
              >
                <i className='MenuIcon'>
                  <svg width='17.28' height='18.359' viewBox='0 0 17.28 18.359'>
                    <path d='M7.426,18.359a.641.641,0,0,1-.586-.382L5.924,15.9,4.18,14.892l-2.256.246a.641.641,0,0,1-.624-.317l-1.214-2.1a.641.641,0,0,1,.038-.7l1.342-1.835V8.173L.124,6.338a.639.639,0,0,1-.038-.7L1.3,3.538a.638.638,0,0,1,.624-.316l2.256.245L5.924,2.46,6.84.382A.641.641,0,0,1,7.426,0H9.854a.641.641,0,0,1,.586.382l.916,2.078L13.1,3.467l2.256-.245a.64.64,0,0,1,.624.316l1.214,2.1a.639.639,0,0,1-.038.7L15.814,8.173v2.013l1.342,1.835a.641.641,0,0,1,.038.7l-1.214,2.1a.642.642,0,0,1-.624.317L13.1,14.892,11.356,15.9l-.916,2.078a.64.64,0,0,1-.586.382ZM4.884,13.386l1.969,1.138A.6.6,0,0,1,7.1,14.8l.777,1.765H9.366l.777-1.765a.6.6,0,0,1,.248-.277l1.969-1.138a.6.6,0,0,1,.364-.076l1.915.208.745-1.289-1.14-1.559a.6.6,0,0,1-.116-.354V8.043a.6.6,0,0,1,.116-.354l1.14-1.559-.745-1.289-1.915.208a.594.594,0,0,1-.364-.077L10.391,3.835a.6.6,0,0,1-.248-.277L9.366,1.793H7.878L7.1,3.558a.6.6,0,0,1-.248.277L4.884,4.972a.592.592,0,0,1-.364.077L2.6,4.841,1.86,6.13,3,7.689a.6.6,0,0,1,.116.354v2.274A.6.6,0,0,1,3,10.671L1.86,12.229,2.6,13.518,4.52,13.31a.6.6,0,0,1,.364.076Zm1.72-.871a.582.582,0,0,1-.583-.582V9.938A1.383,1.383,0,0,1,7.4,8.557H9.8a1.383,1.383,0,0,1,1.382,1.381v1.994a.582.582,0,0,1-.582.582Zm.233-5.781A1.787,1.787,0,1,1,8.624,8.521,1.787,1.787,0,0,1,6.837,6.734Z' />
                  </svg>
                </i>
                <span> Admin</span>
                <i className='arrow'>
                  <svg width='8.4' height='4.725' viewBox='0 0 8.4 4.725'>
                    <path
                      d='M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z'
                      transform='translate(-54.818 -237.549)'
                    />
                  </svg>
                </i>
              </NavLink>
              <ul>
              { Adminuser && Adminuser.view && (
                <li className={ this.getlevelmenuClass('/admin/manageadmin') }>
                  <Link to='/admin/manageadmin'>
                    <i>
                      <svg width='8.4' height='4.725' viewBox='0 0 8.4 4.725'>
                        <path
                          d='M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z'
                          transform='translate(-54.818 -237.549)'
                        />
                      </svg>
                    </i>
                    <span>Manage Admin</span>
                  </Link>
                </li>
              )}
              { Roles && Roles.view && (
                <li  className={ this.getlevelmenuClass('/admin/manageadminroles') }>
                  <Link to='/admin/manageadminroles'>
                    <i>
                      <svg width='8.4' height='4.725' viewBox='0 0 8.4 4.725'>
                        <path
                          d='M62.693,237.549h-7.35a.522.522,0,0,0-.369.894l3.675,3.675a.515.515,0,0,0,.738,0l3.675-3.675a.525.525,0,0,0-.369-.894Z'
                          transform='translate(-54.818 -237.549)'
                        />
                      </svg>
                    </i>
                    <span> Manage Admin Roles</span>
                  </Link>
                </li>
              )}
              </ul>
            </li>
          )}
          {Callmanagement && Callmanagement.view && ( 
            <li>
              <NavLink
                className='main-link'
                activeClassName='active'
                to='/callmanagement'
              >
                <i className='MenuIcon'>
                  <svg width='17.61' height='18.25' viewBox='0 0 17.61 18.25'>
                    <g
                      id='hand-graving-smartphone'
                      transform='translate(-1.23)'
                    >
                      <g
                        id='Group_14145'
                        data-name='Group 14145'
                        transform='translate(1.23)'
                      >
                        <path
                          id='Path_11381'
                          data-name='Path 11381'
                          d='M56.475,32.195H54.414a.974.974,0,0,0-.974.975v.106a.975.975,0,0,0,.974.976h2.061a.977.977,0,0,0,.976-.976v-.107A.974.974,0,0,0,56.475,32.195Z'
                          transform='translate(-39.842 -23.81)'
                        />
                        <path
                          id='Path_11382'
                          data-name='Path 11382'
                          d='M17.865,5.952h-.429V1.233A1.237,1.237,0,0,0,16.2,0H8.066A1.237,1.237,0,0,0,6.833,1.233V4.619c-1.641.928-3.334,1.9-3.338,1.9C2.576,6.9,1.23,8.116,1.23,11a6.954,6.954,0,0,0,.041.724c.008.067.016.132.025.2q.036.253.089.483c.013.055.023.112.036.164a4.752,4.752,0,0,0,.165.5c.031.078.065.15.1.223s.073.153.113.225.091.168.141.247c.012.018.025.035.037.053a4.363,4.363,0,0,0,3.376,1.8H6.833v1.4A1.236,1.236,0,0,0,8.066,18.25H16.2a1.237,1.237,0,0,0,1.233-1.232V15.309h.428a.974.974,0,0,0,.976-.976v-.106a.975.975,0,0,0-.976-.974H15.8a.973.973,0,0,0-.974.974v.108a.975.975,0,0,0,.974.975h.775v.66H7.69V7.116c.446-.283.86-.556,1.1-.738,1.194-.924,2.328-1.659,2.481-2.326.2-.866-.465-1.595-1.617-1-.363.189-1.108.6-1.959,1.08V1.954h8.887v4H15.8a.976.976,0,0,0-.976.976v.106a.974.974,0,0,0,.975.975h2.063a.974.974,0,0,0,.974-.975V6.926A.973.973,0,0,0,17.865,5.952ZM12.133,16.4a.617.617,0,1,1-.615.617A.616.616,0,0,1,12.133,16.4Zm1.3-15.215h-2.6a.149.149,0,1,1,0-.3h2.6a.149.149,0,0,1,0,.3Z'
                          transform='translate(-1.23)'
                        />
                        <path
                          id='Path_11383'
                          data-name='Path 11383'
                          d='M56.472,41.538H54.41a.976.976,0,0,0-.975.976v.107a.974.974,0,0,0,.975.975h2.061a.975.975,0,0,0,.976-.975v-.107A.975.975,0,0,0,56.472,41.538Z'
                          transform='translate(-39.838 -30.719)'
                        />
                      </g>
                    </g>
                  </svg>
                </i>
                <span> Call Management</span>
              </NavLink>
            </li>
          )}
          { Feedback && Feedback.view && (
            <li>
              <NavLink
                className='main-link'
                activeClassName='active'
                to='/feedback'
              >
                <i className='MenuIcon'>
                  <svg width='12.96' height='16.2' viewBox='0 0 12.96 16.2'>
                    <path d='M2.719,14.924l.01-.007A3.227,3.227,0,0,1,0,11.681a.925.925,0,0,1,.009-.1V.338A.331.331,0,0,1,.333,0h12.3a.331.331,0,0,1,.324.338V15.525a.662.662,0,0,1-.648.675Zm2.2-4.607,6.423,3.926V1.687H1.628v9.947c0,.03,0,.073,0,.1A1.492,1.492,0,0,0,3.134,13.26a1.546,1.546,0,0,0,1.511-1.579c0-.019,0-.037,0-.057v-1.15c0-.129.059-.2.144-.2A.239.239,0,0,1,4.918,10.317ZM3.138,7.5V6.486H9.812V7.5Zm0-2.425V4.061H9.812V5.071Z' />
                  </svg>
                </i>
                <span> Feedback</span>
              </NavLink>
            </li>
          )}
            { Settings && Settings.view && (
            <li >
              <NavLink className='main-link' activeClassName='active'  to='/settings'>
                <i className='MenuIcon'>
                  <svg
                    width='17.272'
                    height='17.279'
                    viewBox='0 0 17.272 17.279'
                  >
                    <path
                      d='M56.593,1044l-.433,2.18a7.041,7.041,0,0,0-1.974,1.115l-2.13-.722-2.05,3.493,1.7,1.456a6.306,6.306,0,0,0,0,2.234l-1.7,1.456,2.05,3.493,2.13-.722a7.047,7.047,0,0,0,1.974,1.115l.433,2.18h4.1l.433-2.18a7.047,7.047,0,0,0,1.974-1.115l2.13.722,2.05-3.493-1.7-1.456a6.319,6.319,0,0,0,0-2.234l1.7-1.456-2.05-3.493-2.13.722a7.041,7.041,0,0,0-1.974-1.115l-.433-2.18Zm2.05,4.3a4.337,4.337,0,1,1-4.327,4.337A4.332,4.332,0,0,1,58.642,1048.3Z'
                      transform='translate(-50.006 -1044)'
                    />
                  </svg>
                </i>
                <span>Settings</span>
              </NavLink>
            </li>
            )} 
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { profile } = state;
  return { profile };
}
export default connect(mapStateToProps, null)(withRouter(SideMenu));
