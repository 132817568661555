import React, { Component } from 'react';
import { Fragment } from 'react';
import Usermgnt from 'Components/Users/Usermgnt';
import Pagination from 'Components/Pagination';
import formUrlParams from 'Helpers/urlform';

export default class Contacts extends Component{
    constructor(props){
        super(props);
        this.state={
            filtered: [],
            responseArray: [],
            pageLimit: 10,
            initialPage: 1,
        }
    }
    
    componentDidMount(){
        this.props.fetchUsersList();
    }
    formUrlParams = ( name, value, rest ) => {
        const { filtered } = this.state;
        const filterResponse = formUrlParams(filtered, name, value);
        this.setState({ filtered: filterResponse, ...rest }, () => {
            this.props.fetchUsersList(this.state.filtered);
        });
    }
    
      createStatus = () => {
        const statusArray = [ 'Android', 'Ios' ]
        return statusArray.map((type, index) => {
            return (
                <option key={ index } value={ type.toLowerCase() }>{type}</option>
            )
        })
    }
    setPageLimit = ( name, value ) => {
      this.formUrlParams(name, value,{ pageLimit: value })
    }
    getActiveData = (username) => {    
        this.props.history.push(`/user/${ username }`)
    }
    render(){
      const { usersList, totalRecords, currentPage, loading } = this.props
    return(
            <Fragment>
                <Usermgnt
                usersList={ usersList }
                totalRecords= { totalRecords }
                currentPage={  currentPage }
                loading={ loading }
                formUrl= { this.formUrlParams }
                status = { this.createStatus }
                getActiveUserData = { this.getActiveData }
                />
            <Pagination
                handleClick={ this.formUrlParams }
                totalRecords={ totalRecords }
                currentPage={ this.state.initialPage }
                setPageLimit={ this.setPageLimit }
                initialPage={ currentPage }
                pageLimit={ this.state.pageLimit }
            />
            </Fragment>
        )
    }
}